import React from 'react'
import { Component } from 'reflux'
import { toast } from 'react-toastify'
import { UserPromptActions, UserPromptStore, STATUS } from 'Stores/userPromptStore'
import UserPromptForm from 'Modules/userPrompt/userPromptForm'

export default class UserPromptEdit extends Component {
  constructor(props) {
    super(props)
    this.stores = [UserPromptStore]
    this.storeKeys = ['status']
  }

  componentDidMount() {
    UserPromptActions.getUserPromptDetails(this.props.match.params.UserPromptID)
  }

  componentDidUpdate() {
    if (this.state.status === STATUS.UPDATE_SUCCESS) {
      toast.success('UserPrompt Updated', {
        autoClose: 3000,
        onClose: () => {
          UserPromptActions.clearFormToast()
        }
      })
      UserPromptActions.clearFormToast(STATUS.REDIRECT)
    }
    if (this.state.status === STATUS.UPDATE_ERROR) {
      toast.error('UserPrompt Update Failed', {
        autoClose: 3000,
        onClose: () => {
          UserPromptActions.clearFormToast()
        }
      })
      UserPromptActions.clearFormToast(STATUS.REDIRECT)
    }
  }

  render() {
    return (
      <React.Fragment>
        <UserPromptForm match={this.props.match} />
      </React.Fragment>
    )
  }
}
