/* eslint-disable eqeqeq */
import React from 'react'
import { Component } from 'reflux'
import { ProductStore, ProductActions, STATUS } from 'Stores/productStore'
import CollapsibleCard from 'Components/collapsibleCard'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Link } from 'react-router-dom'
import { Badge, Button, ButtonGroup, Col, Input, Row, UncontrolledTooltip as Tooltip } from 'reactstrap'
import styled from 'styled-components'
import { toast } from 'react-toastify'
import _ from 'lodash'
import ProductFilter from 'Modules/products/productFilter'
import PaginatedTable from '../../components/paginatedTable'
import ProductBulkUpdateCatalogModal from './productBulkUpdateCatalogModal'

const StyledAnchor = styled.a`
  cursor: pointer !important;
`
const StyledSpan = styled.span`
  white-space: nowrap !important;
`

const productBulkActions = [{ value: 'change_catalogs', label: 'Ændr kataloger' }]

export default class ProductList extends Component {
  constructor(props) {
    super(props)
    this.store = ProductStore
    this.storeKeys = [
      'products',
      'status',
      'appliedFilter',
      'filterText',
      'totalProducts',
      'paginationAmount',
      'paginationPoint',
      'sortData',
      'bulkAction',
      'catalogs'
    ]
  }
  componentDidMount() {
    if (_.get(this.props, 'location.search')) {
      let queryValues = this.props.location.search.split('=')
      ProductActions.getCatalogs(queryValues[1])
    }
    if (this.state && this.state.status !== STATUS.FETCHING_PRODUCTS && !_.get(this.props, 'location.search')) {
      ProductActions.getCatalogs()
      ProductActions.setFilter()
    }
  }
  componentDidUpdate() {
    if (!_.get(this.props, 'location.search') && this.state.appliedFilter) {
      ProductActions.setFilter()
    }
    if (this.state.status === STATUS.PRODUCTS_ERROR) {
      toast.error('Fetching Products Failed', {
        autoClose: 7500,
        onClose: () => {
          ProductActions.clearToast()
        }
      })
      ProductActions.clearToast()
    }
    if (this.state.status === STATUS.PRODUCT_DELETE) {
      toast.success('Product Deleted', {
        autoClose: 7500,
        onClose: () => {
          ProductActions.clearToast()
        }
      })
      ProductActions.clearToast()
    }
    if (this.state.status === STATUS.PRODUCT_DELETE_ERROR) {
      toast.error('Product Delete Failed', {
        autoClose: 7500,
        onClose: () => {
          ProductActions.clearToast()
        }
      })
      ProductActions.clearToast()
    }
    if (this.state.status === STATUS.COPY_PRODUCT_FAILED) {
      toast.error('Copy Product Failed', {
        autoClose: 7500,
        onClose: () => {
          ProductActions.clearToast()
        }
      })
      ProductActions.clearToast()
    }
    if (this.state.status === STATUS.COPY_PRODUCT_SUCCESS) {
      toast.success('Successfully Copied', {
        autoClose: 7500,
        onClose: () => {
          ProductActions.clearToast()
        }
      })
      ProductActions.clearToast()
    }
    if (this.state.status === STATUS.BULK_ACTION_SUCCES) {
      toast.success('Successfully updated products', {
        autoClose: 7500,
        onClose: () => {
          ProductActions.clearToast()
        }
      })
      ProductActions.clearToast()
    }
    if (this.state.status === STATUS.BULK_ACTION_ERROR) {
      toast.error('Failed to update products', {
        autoClose: 7500,
        onClose: () => {
          ProductActions.clearToast()
        }
      })
      ProductActions.clearToast()
    }
  }

  render() {
    let tablefieldStringFunctions = {
      productStatus: e => {
        return e.productStatus
      },
      category: e => {
        let eCat = e.categories ? e.categories : []
        let categories = eCat
          .map(c => {
            if (c.categoryTaxonomy == 'PRODUCT') {
              return c.categoryName
            }
          })
          .filter(c => {
            return c
          })
        return categories.toString()
      },
      variations: e => {
        let eVar = e.variations ? e.variations : []
        let variations = eVar.map(v => {
          return v.variationTitle
        })
        return variations.toString()
      },
      variationIDs: e => {
        let eVar = e.variations ? e.variations : []
        let variations = eVar.map(v => {
          return v.variationUID
        })
        return variations.toString()
      }
    }
    return (
      <React.Fragment>
        <ProductFilter location={this.props.location} />
        <CollapsibleCard
          startOpen
          header={
            <Row>
              <Col md='10'>
                <strong>Produkter</strong>
              </Col>
              <Col md='2'>
                <Input
                  type='select'
                  name='productCatalog'
                  id='productCatalog'
                  disabled={!this.state.products.some(elem => elem.checked)}
                  onChange={actionSelect => {
                    ProductActions.setBulkAction(actionSelect.target.value)
                    actionSelect.target.value = false // reset the dropdown
                  }}
                >
                  <option selected='true' disabled={true} value={false}>
                    Handling
                  </option>
                  {productBulkActions.map(a => {
                    return (
                      <option key={a.value} value={a.value}>
                        {a.label}
                      </option>
                    )
                  })}
                </Input>
              </Col>
            </Row>
          }
        >
          <PaginatedTable
            data={this.state.products}
            headerscss={{ productTags: { whiteSpace: 'nowrap' } }}
            headers={{
              check: '\u00A0',
              ProductID: '#',
              elementTitle: 'Title',
              productPrice: 'basePrice',
              productPriceAdjust: 'baseMarkup',
              productStatus: 'Status',
              supplier: 'Supplier',
              product: 'Category',
              manufacturer: 'Brand',
              productTags: 'Tags',
              variations: 'Variations',
              variationIDs: 'Varenumre',
              catalogs: 'Catalogs',
              functions: '\u00A0'
            }}
            options={{
              getdata: ProductActions.getProducts,
              totalamount: this.state.totalProducts,
              loading: this.state.status === STATUS.FETCHING_PRODUCTS,
              setFilterText: ProductActions.setFilterText,
              setSortData: ProductActions.setSortData,
              setPaginationAmount: ProductActions.setPaginationAmount,
              setPaginationPoint: ProductActions.setPaginationPoint,
              filterText: this.state.filterText,
              paginationAmount: this.state.paginationAmount,
              paginationPoint: this.state.paginationPoint,
              sortData: this.state.sortData,
              unsortable: ['supplier', 'product', 'productTags', 'variations', 'variationIDs', 'catalogs', 'check'],
              setAllChecked: ProductActions.setAllChecked,
              fieldFunctions: {
                check: e => {
                  return (
                    <Input
                      type='checkbox'
                      id={'checkbox' + e.ProductID}
                      name={'checkbox' + e.ProductID}
                      checked={e.checked || false}
                      style={{ margin: 0, marginTop: -4 }}
                      onChange={event => {
                        ProductActions.selectProductHandler(event, e.ProductID)
                      }}
                    />
                  )
                },
                productStatus: e => {
                  if (e.productStatus == 'AVAILABLE') {
                    return <Badge color='primary'>{e.productStatus}</Badge>
                  }
                  return <Badge>{e.productStatus}</Badge>
                },
                productTags: e => {
                  if (e.productTags && e.productTags.length) {
                    const reducedTags = e.productTags.reduce((acc, cur, idx) => {
                      acc.push(cur)
                      acc.push(<br key={idx} />)
                      return acc
                    }, [])
                    if (reducedTags.length > 0) {
                      reducedTags.pop()
                    }
                    return reducedTags
                  }
                },
                catalogs: e => {
                  if (e.catalogs && e.catalogs.length) {
                    const reducedCatalogs = e.catalogs.reduce((acc, cur, idx) => {
                      acc.push(cur.moduleName)
                      acc.push(<br key={idx} />)
                      return acc
                    }, [])
                    if (reducedCatalogs.length > 0) {
                      reducedCatalogs.pop()
                    }
                    return reducedCatalogs
                  }
                },
                variations: e => {
                  let reducedVariations = e.variations.reduce((acc, cur, idx) => {
                    let line = cur.variationTitle
                    if (cur.variationStockable != 0) {
                      line += ' (' + cur.variationStock + ') ' + (cur.externalStock ? 'E' : 'I')
                    }
                    acc.push(line)
                    acc.push(<br key={idx} />)
                    return acc
                  }, [])
                  if (e.variationReadMore && e.variations.length > 3) {
                    reducedVariations.push(
                      <StyledAnchor key={reducedVariations.length} onClick={() => ProductActions.showDetails(e)}>
                        Show Less
                      </StyledAnchor>
                    )
                  }
                  if (e.variationReadLess) {
                    reducedVariations = reducedVariations.splice(0, 4)
                    reducedVariations.push(
                      <StyledAnchor key={reducedVariations.length} onClick={() => ProductActions.showDetails(e)}>
                        Show More
                      </StyledAnchor>
                    )
                  }
                  return <StyledSpan>{reducedVariations}</StyledSpan>
                },
                variationIDs: e => {
                  let reducedVariations = e.variations.reduce((acc, cur, idx) => {
                    acc.push(cur.variationUID)
                    acc.push(<br key={idx} />)
                    return acc
                  }, [])
                  if (e.variationReadMore && e.variations.length > 3) {
                    reducedVariations.push(
                      <StyledAnchor key={reducedVariations.length} onClick={() => ProductActions.showDetails(e)}>
                        Show Less
                      </StyledAnchor>
                    )
                  }
                  if (e.variationReadLess) {
                    reducedVariations = reducedVariations.splice(0, 4)
                    reducedVariations.push(
                      <StyledAnchor key={reducedVariations.length} onClick={() => ProductActions.showDetails(e)}>
                        Show More
                      </StyledAnchor>
                    )
                  }
                  return <StyledSpan>{reducedVariations}</StyledSpan>
                },
                functions: e => {
                  return (
                    <ButtonGroup>
                      <Tooltip target={'edit' + e.ProductID}>Rediger</Tooltip>
                      <Button tag={Link} to={'/products/' + e.ProductID} id={'edit' + e.ProductID}>
                        <FontAwesomeIcon icon='edit' />
                      </Button>
                      <Tooltip target={'delete' + e.ProductID}>Slet</Tooltip>
                      <Button
                        id={'delete' + e.ProductID}
                        color='danger'
                        onClick={() => {
                          if (confirm('Vil du slette dette produkt?')) {
                            ProductActions.deleteProduct(e.ProductID)
                          }
                        }}
                      >
                        <FontAwesomeIcon icon='trash' />
                      </Button>
                      <Tooltip target={'copy' + e.ProductID}>Kopier</Tooltip>
                      <Button
                        id={'copy' + e.ProductID}
                        onClick={() => {
                          ProductActions.copyProduct(e)
                        }}
                      >
                        <FontAwesomeIcon icon='copy' />
                      </Button>
                    </ButtonGroup>
                  )
                }
              },
              fieldStringFunctions: tablefieldStringFunctions,
              fieldExportFunctions: {
                ...tablefieldStringFunctions,
                catalogs: e => {
                  let eVar = e.catalogs ? e.catalogs : []
                  let catalogs = eVar.map(v => {
                    return v.moduleName
                  })
                  return catalogs.toString()
                }
              }
            }}
          />
        </CollapsibleCard>

        <ProductBulkUpdateCatalogModal
          isOpen={this.state.bulkAction === 'change_catalogs'}
          onClose={() => ProductActions.setBulkAction('')}
          products={this.state.products.filter(elem => elem.checked)}
          catalogs={this.state.catalogs}
          onSuccess={ProductActions.onBulkActionSuccess}
          onError={ProductActions.onBulkActionError}
        />
      </React.Fragment>
    )
  }
}
