import React from 'react'
import { Component } from 'reflux'
import { Button, Input, Modal, ModalBody, ModalFooter, ModalHeader, Tooltip } from 'reactstrap'
import Proxies from 'Components/proxies'
const { POST } = Proxies

export default class ProductBulkUpdateCatalogModal extends Component {
  constructor(props) {
    super(props)
    this.state = {
      bulkCatalogIds: [false],
      bulkCatalogOverwrite: false
    }
  }

  bulkUpdateProductCatalog() {
    if (this.props.products.length > 0) {
      const productIds = this.props.products.map(p => p.ProductID)
      const catalogIds = this.state.bulkCatalogIds.filter(cid => !!cid)
      const overwriteExisting = !!this.state.bulkCatalogOverwrite
      POST('products/updateCatalogsProducts', { productIDs: productIds, toCatalogIds: catalogIds, overwriteExisting: overwriteExisting }).then(
        response => {
          if (response && !response.error) {
            this.props.onSuccess()
          } else {
            this.props.onError()
          }
        }
      )

      this.props.onClose()
      this.setState({ bulkCatalogIds: [false], bulkCatalogOverwrite: false })
    }
  }

  changeBulkCatalog(idx, catalogId) {
    this.setState({
      bulkCatalogIds: this.state.bulkCatalogIds.map((cid, i) => {
        if (idx === i) {
          return catalogId
        } else {
          return cid
        }
      })
    })
  }

  addBulkCatalog() {
    this.setState({ bulkCatalogIds: [...this.state.bulkCatalogIds, false] })
  }

  removeBulkCatalog(idx) {
    this.setState({ bulkCatalogIds: this.state.bulkCatalogIds.filter((_, i) => idx !== i) })
  }

  setBulkCatalogOverwrite(val) {
    this.setState({ bulkCatalogOverwrite: val })
  }

  render() {
    return (
      <Modal isOpen={this.props.isOpen} toggle={this.props.onClose} size='md'>
        <ModalHeader toggle={this.props.onClose}>Ændr kataloger</ModalHeader>
        <ModalBody>
          <Tooltip target='addBulkCatalog'>Tilføj katalog</Tooltip>
          <Button id='addBulkCatalog' onClick={() => this.addBulkCatalog()}>
            +
          </Button>
          {this.state.bulkCatalogIds.map((cid, idx) => (
            <div key={`${cid} + ${idx}`} style={{ display: 'flex', gap: 20, justifyContent: 'space-between', marginTop: 20 }}>
              <Input
                type='select'
                value={cid}
                onChange={catalogSelect => {
                  this.changeBulkCatalog(idx, catalogSelect.target.value)
                }}
              >
                <option selected='true' disabled={true} value={false}>
                  Katalog
                </option>
                {this.props.catalogs.map(c => {
                  return (
                    <option key={c.CatalogID} value={c.CatalogID}>
                      {c.moduleName}
                    </option>
                  )
                })}
              </Input>
              <Tooltip target='removeBulkCatalog'>Fjern katalog</Tooltip>
              <Button id='removeBulkCatalog' color='danger' onClick={() => this.removeBulkCatalog(idx)}>
                -
              </Button>
            </div>
          ))}

          <div style={{ display: 'flex', gap: 20, justifyContent: 'space-between', width: 260, marginTop: 20 }}>
            <p>Overskriv nuværende kategorier: </p>
            <input
              style={{ marginTop: -12 }}
              type='checkbox'
              value={this.state.bulkCatalogOverwrite}
              onChange={item => {
                this.setBulkCatalogOverwrite(item.target.checked)
              }}
            />
          </div>
        </ModalBody>
        <ModalFooter>
          <Button
            onClick={() => {
              if (confirm('Do you want to change the catalog of the selected products?')) {
                this.bulkUpdateProductCatalog()
              }
            }}
          >
            Bekræft
          </Button>
        </ModalFooter>
      </Modal>
    )
  }
}
