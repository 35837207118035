import { useState, useEffect } from 'react'
import Proxies from 'Components/proxies'
const { GET } = Proxies
/* eslint-disable eqeqeq */
import React from 'react'
import DynamicTable from 'Components/dynamicTable'
import CollapsibleCard from 'Components/collapsibleCard'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Link } from 'react-router-dom'
import moment from 'moment'
import 'moment/locale/da'
import { Button, ButtonGroup, UncontrolledTooltip as Tooltip } from 'reactstrap'
import { currencyFormatter } from 'Util/currencyFormatter'
import { Row, Col } from 'reactstrap'
import styled from 'styled-components'

const StyledRow = styled(Row)`
  padding: 2px 100px 1px 5px !important;
  @media only screen and (min-width: 2000px) {
    padding: 2px 180px 1px 5px !important;
  }
  @media only screen and (max-width: 1024px) {
    padding: 2px 65px 1px 5px !important;
  }
`
const StyledInvoiceAmount = styled.b`
  padding-right: 20px !important;
  @media only screen and (min-width: 2000px) {
    padding-right: 37px !important;
  }
`

const StyledSpan = styled.span`
  margin: 0px 15px !important;
`
const MerchInvoice = () => {
  const [brands, setBrands] = useState([])
  const [loading, setLoading] = useState(false)

  const fetchMerchBrandInvoices = async () => {
    setLoading(true)
    const response = await GET('orders/brands', { isMerchandise: true })
    setLoading(false)
    if (response.length) {
      setBrands(response)
    }
  }

  const totals = {
    openInvoiceAmount: brands.reduce((acc, cur) => acc + (cur.openInvoiceAmount || 0), 0),
    closedInvoiceThisMonth: brands.reduce((acc, cur) => acc + (cur.closedInvoiceThisMonth || 0), 0),
    closedInvoiceLastMonth: brands.reduce((acc, cur) => acc + (cur.closedInvoiceLastMonth || 0), 0),
    closedInvoiceTwoMonthsBack: brands.reduce((acc, cur) => acc + (cur.closedInvoiceTwoMonthsBack || 0), 0),
    openOrderAmount: brands.reduce((acc, cur) => acc + (cur.openOrdersAmount || 0), 0),
    todayShippedAmount: brands.reduce((acc, cur) => acc + (cur.todayShippedAmount || 0), 0)
  }

  useEffect(() => {
    fetchMerchBrandInvoices()
  }, [])

  return loading ? (
    <FontAwesomeIcon icon='spinner' spin />
  ) : (
    <CollapsibleCard
      startOpen
      header={
        <React.Fragment>
          <strong>Merch Fakturaer</strong>{' '}
        </React.Fragment>
      }
    >
      <DynamicTable
        headerscss={{
          openInvoiceAmount: { textAlign: 'right', whiteSpace: 'nowrap' },
          closedInvoiceThisMonth: { textAlign: 'right', whiteSpace: 'nowrap' },
          closedInvoiceLastMonth: { textAlign: 'right', whiteSpace: 'nowrap' },
          closedInvoiceTwoMonthsBack: { textAlign: 'right', whiteSpace: 'nowrap' },
          openOrdersAmount: { textAlign: 'right', whiteSpace: 'nowrap' }
        }}
        responsive
        size='sm'
        data={brands}
        headers={{
          brandName: 'Brand',
          openInvoiceAmount: 'Open Invoice Amount',
          closedInvoiceThisMonth: 'Closed Current Month',
          closedInvoiceLastMonth: 'Closed Last Month',
          closedInvoiceTwoMonthsBack: 'Closed 2 Months Back',
          openOrdersAmount: 'Open Orders Amount'
        }}
        options={{
          addField: e => {
            return (
              <td>
                <ButtonGroup>
                  <Tooltip target={'view' + e.BrandID}>Vis</Tooltip>
                  <Button tag={Link} to={'/merchandise/admininvoice/' + e.BrandID} id={'view' + e.BrandID}>
                    <FontAwesomeIcon icon='eye' />
                  </Button>
                </ButtonGroup>
              </td>
            )
          },
          fieldFunctions: {
            openInvoiceAmount: e => {
              return <StyledSpan>{e.openInvoiceAmount ? currencyFormatter.format(e.openInvoiceAmount) : '0,00 kr.'}</StyledSpan>
            },
            closedInvoiceThisMonth: e => {
              return <StyledSpan>{e.closedInvoiceThisMonth ? currencyFormatter.format(e.closedInvoiceThisMonth) : '0,00 kr.'}</StyledSpan>
            },
            closedInvoiceLastMonth: e => {
              return <StyledSpan>{e.closedInvoiceLastMonth ? currencyFormatter.format(e.closedInvoiceLastMonth) : '0,00 kr.'}</StyledSpan>
            },
            closedInvoiceTwoMonthsBack: e => {
              return <StyledSpan>{e.closedInvoiceTwoMonthsBack ? currencyFormatter.format(e.closedInvoiceTwoMonthsBack) : '0,00 kr.'}</StyledSpan>
            },
            openOrdersAmount: e => {
              return <StyledSpan>{e.openOrdersAmount ? currencyFormatter.format(e.openOrdersAmount) : '0,00 kr.'}</StyledSpan>
            }
          },
          fieldExportFunctions: {
            openInvoiceAmount: e => {
              return e.openInvoiceAmount ? currencyFormatter.format(e.openInvoiceAmount) : '0,00 kr.'
            },
            closedInvoiceThisMonth: e => {
              return e.closedInvoiceThisMonth ? currencyFormatter.format(e.closedInvoiceThisMonth) : '0,00 kr.'
            },
            closedInvoiceLastMonth: e => {
              return e.closedInvoiceLastMonth ? currencyFormatter.format(e.closedInvoiceLastMonth) : '0,00 kr.'
            },
            closedInvoiceTwoMonthsBack: e => {
              return e.closedInvoiceTwoMonthsBack ? currencyFormatter.format(e.closedInvoiceTwoMonthsBack) : '0,00 kr.'
            },
            openOrdersAmount: e => {
              return e.openOrdersAmount ? currencyFormatter.format(e.openOrdersAmount) : '0,00 kr.'
            }
          }
        }}
      />

      <StyledRow>
        <Col>
          <b>Totals</b>
        </Col>
        <Col md='2' style={{ textAlign: 'right' }}>
          <StyledInvoiceAmount>{currencyFormatter.format(totals.openInvoiceAmount)}</StyledInvoiceAmount>
        </Col>
        <Col md='2' style={{ textAlign: 'right' }}>
          <b>{currencyFormatter.format(totals.closedInvoiceThisMonth)}</b>
        </Col>
        <Col md='2' style={{ textAlign: 'right' }}>
          <b>{currencyFormatter.format(totals.closedInvoiceLastMonth)}</b>
        </Col>
        <Col md='2' style={{ textAlign: 'right' }}>
          <b>{currencyFormatter.format(totals.closedInvoiceTwoMonthsBack)}</b>
        </Col>
        <Col md='2' style={{ textAlign: 'right' }}>
          <b>{currencyFormatter.format(totals.openOrderAmount)}</b>
        </Col>
      </StyledRow>

      <StyledRow className='mt-2'>
        <Col>
          <b>Dagens omsætning</b>
        </Col>
      </StyledRow>
      <StyledRow className='mt-2'>
        <Col md='2'>
          <b>{moment().format('DD-MM-YYYY')}</b>
        </Col>
        <Col md='2' style={{ textAlign: 'right' }}>
          <StyledInvoiceAmount>{currencyFormatter.format(totals.todayShippedAmount)}</StyledInvoiceAmount>
        </Col>
      </StyledRow>
    </CollapsibleCard>
  )
}

export default MerchInvoice
