import Papa from 'papaparse'
import { download } from 'Components/download'
import XLSX from 'xlsx'

export default class DownloadUtil {
  static downloadData(data, headers, options) {
    let headerNames = []
    /* hvis der ikke er data, eller data ikke har længde, så stop */
    if (!data || data.length === 0) {
      return null
    }
    /* hvis der ikke er sendt headers med, så lad headers være feltnavnene */
    if (!headers) {
      headers = Object.keys(data[0])
    }
    /* find rækkefølge af felter - hvis headers ikke er sendt med, så lad rækkefølgen være den som data er leveret i */
    let fieldOrder = []
    if (!Array.isArray(headers)) {
      headers.actions && delete headers.actions
      fieldOrder = Object.keys(headers)
      headerNames = Object.values(headers)
    } else {
      fieldOrder = headers
    }
    /* sørg for at options er et objekt */
    if (!options) {
      options = {}
    }
    let csvdata = data.map(e => {
      let line = {}
      fieldOrder.forEach((a, index) => {
        if (!Array.isArray(headers)) {
          if (!headers[a]) {
            return null
          }
        }
        let rVal = e[a]
        if (options.fieldExportFunctions && options.fieldExportFunctions[a] && typeof options.fieldExportFunctions[a] === 'function') {
          rVal = options.fieldExportFunctions[a](e)
        } else if (options.fieldFunctions && options.fieldFunctions[a] && typeof options.fieldFunctions[a] === 'function') {
          rVal = options.fieldFunctions[a](e)
        } else if (options.fieldStringFunctions && options.fieldStringFunctions[a] && typeof options.fieldStringFunctions[a] === 'function') {
          rVal = options.fieldStringFunctions[a](e)
        }
        headerNames.length ? (line[headerNames[index]] = rVal) : (line[a] = rVal)
      })
      return line
    })
    let papasettings = {
      quotes: true,
      delimiter: ';',
      header: true
    }
    download(Papa.unparse(csvdata, papasettings), 'download.csv')
  }

  static downloadDataExcel(data, headers, options) {
    /* hvis der ikke er data, eller data ikke har længde, så stop */
    if (!data || data.length === 0) {
      return null
    }
    let headerNames = []
    /* hvis der ikke er sendt headers med, så lad headers være feltnavnene */
    if (!headers) {
      headers = Object.keys(data[0])
    }
    /* find rækkefølge af felter - hvis headers ikke er sendt med, så lad rækkefølgen være den som data er leveret i */
    let fieldOrder = []
    if (!Array.isArray(headers)) {
      headers.actions && delete headers.actions
      fieldOrder = Object.keys(headers)
      headerNames = Object.values(headers)
    } else {
      fieldOrder = headers
    }
    /* sørg for at options er et objekt */
    if (!options) {
      options = {}
    }
    let xlData = []
    headerNames.length ? xlData.push(headerNames) : xlData.push(fieldOrder)
    data.map(e => {
      let xlRow = []
      fieldOrder.forEach(a => {
        if (!Array.isArray(headers)) {
          if (!headers[a]) {
            return null
          }
        }
        let rVal = e[a]
        if (options.fieldExportFunctions && options.fieldExportFunctions[a] && typeof options.fieldExportFunctions[a] === 'function') {
          rVal = options.fieldExportFunctions[a](e)
        } else if (options.fieldFunctions && options.fieldFunctions[a] && typeof options.fieldFunctions[a] === 'function') {
          rVal = options.fieldFunctions[a](e)
        } else if (options.fieldStringFunctions && options.fieldStringFunctions[a] && typeof options.fieldStringFunctions[a] === 'function') {
          rVal = options.fieldStringFunctions[a](e)
        }
        xlRow.push(rVal)
      })
      xlData.push(xlRow)
    })
    let worksheet = XLSX.utils.aoa_to_sheet(xlData)
    let workbook = new XLSX.utils.book_new()
    XLSX.utils.book_append_sheet(workbook, worksheet, 'download')
    XLSX.writeFile(workbook, 'download.xlsx')
  }
}
