import Reflux from 'reflux'
import Proxies from 'Components/proxies'
const { GET, POST } = Proxies
import keyMirror from 'keymirror'
import moment from 'moment'
import 'moment/locale/da'

export const priorities = ['HIGHEST', 'HIGH', 'MEDIUM', 'LOW', 'LOWEST']

export const STATUS = keyMirror({
  IS_FETCHING: null,
  FETCHING_ERROR: null,
  UPDATE_SUCCESS: null,
  UPDATE_ERROR: null,
  DELETE_ERROR: null,
  DELETE_SUCCESS: null,
  TAKING_INPUT: null,
  IS_SAVING: null,
  REDIRECT: null
})

export const UserPromptActions = Reflux.createActions([
  'getUserPrompts',
  'deleteUserPrompt',
  'clearToast',
  'createEmptyUserPrompt',
  'clearFormToast',
  'updateUserPromptField',
  'saveUserPrompt',
  'getUserPromptDetails',
  'addGroup',
  'removeGroup',
  'getGroups',
  'updateBodyText'
])

export class UserPromptStore extends Reflux.Store {
  constructor(props) {
    super(props)
    this.listenables = UserPromptActions
    this.state = {
      status: null,
      userPrompts: [],
      userPromptName: null,
      userPromptDetails: {
        title: '',
        bodyText: '',
        promptType: 'CUSTOM',
        buttonText: '',
        buttonLink: '',
        poolOrder: 0,
        isHideable: 0,
        isActive: 0,
        timeStart: moment().format('YYYY-MM-DD'),
        timeEnd: '',
        groupIds: []
      },
      groups: []
    }
  }

  getUserPrompts() {
    if (this.state.status === STATUS.IS_FETCHING) {
      return
    }

    this.setState({ status: STATUS.IS_FETCHING })
    GET('userPrompts/pool').then(responseJSON => {
      let nextState = {
        userPrompts: []
      }
      if (responseJSON) {
        if (responseJSON.error) {
          nextState.status = STATUS.FETCHING_ERROR
        } else {
          nextState.userPrompts = responseJSON
          nextState.status = null
        }
      }
      this.setState(nextState)
    })
  }

  clearToast() {
    this.setState({ status: null })
  }

  deleteUserPrompt(id = null) {
    if (id) {
      POST('userPrompts/removeUserPrompt', { UserPromptID: id }).then(responseJSON => {
        if (responseJSON.error) {
          this.setState({ status: STATUS.DELETE_ERROR })
        } else {
          this.getUserPrompts()
        }
      })
    }
  }

  createEmptyUserPrompt() {
    this.setState({
      userPromptDetails: {
        title: '',
        bodyText: '',
        promptType: 'CUSTOM',
        buttonText: '',
        buttonLink: '',
        poolOrder: 0,
        isHideable: 0,
        isActive: 0,
        timeStart: moment().format('YYYY-MM-DD'),
        timeEnd: '',
        groupIds: []
      }
    })
  }

  addGroup(groupId) {
    let userPromptDetails = this.state.userPromptDetails
    this.setState({ userPromptDetails: { ...userPromptDetails, groupIds: [...userPromptDetails.groupIds, groupId] } })
  }

  removeGroup(index) {
    let userPromptDetails = this.state.userPromptDetails
    const newGroups = this.state.userPromptDetails.groupIds.filter((_, i) => i !== index)
    this.setState({ userPromptDetails: { ...userPromptDetails, groupIds: newGroups } })
  }

  clearFormToast(status) {
    status ? this.setState({ status }) : this.setState({ status: STATUS.TAKING_INPUT })
  }

  getUserPromptDetails(id = null) {
    if (!id) {
      return
    }

    this.setState({ status: STATUS.IS_FETCHING })
    GET('userPrompts/fetch', { UserPromptID: id }).then(responseJSON => {
      let nextState = {
        userPromptDetails: {}
      }
      if (responseJSON) {
        if (responseJSON.error) {
          nextState.status = STATUS.FETCHING_ERROR
        } else {
          nextState.userPromptDetails = responseJSON
          nextState.status = STATUS.TAKING_INPUT
        }
      }
      this.setState(nextState)
    })
  }

  updateUserPromptField(field) {
    let fieldName = field.target.name
    let fieldValue
    if (fieldName === 'isActive' || fieldName === 'isHideable') {
      fieldValue = field.target.checked ? 1 : 0
    } else if (fieldName === 'poolOrder') {
      fieldValue = Number(field.target.value)
    } else {
      fieldValue = field.target.value
    }
    let userPromptDetails = this.state.userPromptDetails
    userPromptDetails[fieldName] = fieldValue
    this.setState({ userPromptDetails })
  }

  updateBodyText(val) {
    this.setState({ userPromptDetails: { ...this.state.userPromptDetails, bodyText: val } })
  }

  saveUserPrompt() {
    let userPromptDetails = this.state.userPromptDetails
    if (!userPromptDetails.timeEnd) {
      userPromptDetails.timeEnd = null
    }
    this.setState({ status: STATUS.IS_SAVING })
    POST('userPrompts/replace', userPromptDetails).then(responseJSON => {
      if (responseJSON) {
        if (responseJSON.error) {
          this.setState({ status: STATUS.UPDATE_ERROR })
        } else {
          this.setState({ status: STATUS.UPDATE_SUCCESS })
        }
      }
    })
  }

  getGroups() {
    GET('groups').then(responseJSON => {
      let nextState = {
        groups: []
      }
      if (responseJSON) {
        responseJSON = responseJSON.filter(item => {
          return !isNaN(item.GroupID)
        })
        nextState.groups = responseJSON
      }
      this.setState(nextState)
    })
  }
}
