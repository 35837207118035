import React from 'react'
import { Component } from 'reflux'
import DynamicTable from 'Components/dynamicTable'
import CollapsibleCard from 'Components/collapsibleCard'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Link } from 'react-router-dom'
import { Button, ButtonGroup, UncontrolledTooltip as Tooltip } from 'reactstrap'
import moment from 'moment'
import 'moment/locale/da'
import { toast } from 'react-toastify'
import { UserPromptActions, UserPromptStore, STATUS } from 'Stores/userPromptStore'
import { priorities } from '../../stores/userPromptStore'

export default class UserPromptList extends Component {
  constructor(props) {
    super(props)
    this.stores = [UserPromptStore]
    this.storeKeys = ['userPrompts', 'status']
  }

  componentDidMount() {
    UserPromptActions.getUserPrompts()
  }

  componentDidUpdate() {
    if (this.state.status === STATUS.FETCHING_ERROR) {
      toast.error('Fetching UserPrompts Failed', {
        autoClose: 7500,
        onClose: () => {
          UserPromptActions.clearToast()
        }
      })
      UserPromptActions.clearToast()
    }
    if (this.state.status === STATUS.DELETE_ERROR) {
      toast.error('Delete UserPrompt Failed', {
        autoClose: 7500,
        onClose: () => {
          UserPromptActions.clearToast()
        }
      })
      UserPromptActions.clearToast()
    }
    if (this.state.status === STATUS.DELETE_SUCCESS) {
      toast.success('UserPrompt Deleted', {
        autoClose: 7500,
        onClose: () => {
          UserPromptActions.clearToast()
        }
      })
      UserPromptActions.clearToast()
    }
  }

  render() {
    if (this.state.status === STATUS.IS_FETCHING) {
      return <FontAwesomeIcon icon='spinner' spin />
    }
    return (
      <CollapsibleCard startOpen header={<strong>UserPrompts</strong>}>
        <DynamicTable
          responsive
          size='sm'
          options={{
            startSort: {
              dir: 'DESC',
              column: 'UserPromptID'
            },
            fieldFunctions: {
              bodyText: e => {
                if (e.bodyText) {
                  const maxStringLength = 100
                  let shownText = e.bodyText.substring(0, maxStringLength)
                  if (e.bodyText.length > maxStringLength) {
                    shownText += '...'
                  }
                  return shownText
                }
              },
              poolOrder: e => {
                return priorities[e.poolOrder / 10]
              },
              timeStart: e => {
                if (e.timeStart) {
                  return moment(e.timeStart).format('DD-MM-YYYY ')
                }
              },
              timeEnd: e => {
                if (e.timeEnd) {
                  return moment(e.timeEnd).format('DD-MM-YYYY')
                }
              }
            },
            fieldStringFunctions: {
              poolOrder: e => {
                return e.poolOrder
              },
              timeStart: e => {
                return moment(e.timeStart).format('x')
              },
              timeEnd: e => {
                return moment(e.timeEnd).format('x')
              }
            },
            addField: e => {
              return (
                <td>
                  <ButtonGroup>
                    <Tooltip target={'edit' + e.UserPromptID}>Rediger</Tooltip>
                    <Button tag={Link} to={'/user-prompts/' + e.UserPromptID} id={'edit' + e.UserPromptID}>
                      <FontAwesomeIcon icon='edit' />
                    </Button>
                    <Tooltip target={'delete' + e.UserPromptID}>Slet</Tooltip>
                    <Button
                      id={'delete' + e.UserPromptID}
                      color='danger'
                      onClick={() => {
                        if (confirm('Vil du slette denne instruktion?')) {
                          UserPromptActions.deleteUserPrompt(e.UserPromptID)
                        }
                      }}
                    >
                      <FontAwesomeIcon icon='trash' />
                    </Button>
                  </ButtonGroup>
                </td>
              )
            }
          }}
          headers={{
            UserPromptID: 'ID',
            title: 'Titel',
            bodyText: 'Brødtekst',
            promptType: 'Type',
            poolOrder: 'Prioritet',
            isActive: 'Aktiv',
            timeStart: 'Start',
            timeEnd: 'Slut'
          }}
          data={this.state.userPrompts}
        />
      </CollapsibleCard>
    )
  }
}
