/* eslint-disable eqeqeq */
import React from 'react'
import { Component } from 'reflux'
import Reflux from 'reflux'
import DynamicTable from 'Components/dynamicTable'
import CollapsibleCard from 'Components/collapsibleCard'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Link } from 'react-router-dom'
import moment from 'moment'
import 'moment/locale/da'
import { Button, ButtonGroup, UncontrolledTooltip as Tooltip, Form, Input } from 'reactstrap'
import FormField from 'Components/formField'
import DatePicker from 'react-datepicker'
import { InvoiceStore, InvoiceActions } from 'Stores/invoiceStore'
import { LoginStore } from 'Stores/loginStore'
import { currencyFormatter } from 'Util/currencyFormatter'
import MerchInvoiceDetails from './invoice/merchInvoiceDetails'

const Invoice = props => {
  if (props.match.params.InvoiceID) {
    return <InvoiceDetails {...props} />
  }
  return <InvoiceList {...props} />
}
export default Invoice

class InvoiceDetails extends Component {
  constructor(props) {
    super(props)
    this.stores = [InvoiceStore, LoginStore]
    this.storeKeys = ['settings', 'fetchingInvoiceData', 'fetchingInvoiceLine', 'invoiceLine', 'invoiceData']
    this.updateDateField = this.updateDateField.bind(this)
    this.invoiceHeaders = {}
  }
  componentDidMount() {
    let commonHeaders = {
      OrderlineID: '#',
      ConfirmationID: 'Confirmation ID',
      OrderID: 'Ordre ID',
      UserID: 'Bruger ID',
      userName: 'Bruger',
      userUID: 'Unikt ID',
      userCompany: 'Virksomhed',
      orderlineTitle: 'Vare',
      orderAmountPoints: 'Point',
      orderAmountDKK: 'DKK',
      timeInsert: 'Oprettet',
      timeShipped: 'Afsendt',
      ...(this.state.settings && this.state.settings.features && this.state.settings.features.enableVault && { vaultSlug: 'Vault Slug' })
    }
    if (this.state.settings.features && !!this.state.settings.features.hideDkkColumnForInvoice) {
      delete commonHeaders.orderAmountDKK
    }
    const headers = { orderPointRatio: 'Ratio', orderlineProductPrice: 'Basis', orderCurrency: 'Valuta' }
    Reflux.GlobalState.LoginStore.BrandID === 11 || Reflux.GlobalState.LoginStore.BrandID === 167
      ? (this.invoiceHeaders = { ...commonHeaders, ...headers })
      : (this.invoiceHeaders = commonHeaders)
  }
  componentWillMount() {
    super.componentWillMount()
    if (!(this.state && this.state.fetchingInvoiceData)) {
      InvoiceActions.getInvoice(this.props.match.params.InvoiceID)
    }
  }
  updateDateField(m, field) {
    let data = {
      target: {
        name: field,
        value: null
      }
    }
    if (field == 'timeEnd') {
      data.target.value = m.endOf('day').format('DD-MM-YYYY HH:mm:ss')
    } else if (field == 'timeStart') {
      data.target.value = m.startOf('day').format('DD-MM-YYYY HH:mm:ss')
    }
    InvoiceActions.updateInvoiceField(data)
  }
  render() {
    if (this.state.settings.isMerchandise) {
      return (
        <MerchInvoiceDetails
          {...this.props}
          isAdmin={false}
          enableVault={this.state.settings && this.state.settings.features && this.state.settings.features.enableVault}
          BrandID={Reflux.GlobalState.LoginStore.BrandID}
          hideDkkColumnForInvoice={this.state.settings.features && !!this.state.settings.features.hideDkkColumnForInvoice}
        />
      )
    }
    if (this.state.fetchingInvoiceData || this.state.fetchingInvoiceLine) {
      return <FontAwesomeIcon icon='spinner' spin />
    }
    return (
      <React.Fragment>
        <CollapsibleCard startOpen={this.props.create} header={<strong>Detaljer for faktura</strong>}>
          <Form
            onSubmit={e => {
              e.preventDefault()
            }}
          >
            <FormField
              data={this.state.invoiceLine}
              field={'invoiceTitle'}
              disabled
              name='Titel'
              onChange={InvoiceActions.updateInvoiceField}
              required
            />
            <FormField field={'invoiceStatus'} name='Status'>
              <Input
                type='select'
                name='invoiceStatus'
                disabled
                value={this.state.invoiceLine.invoiceStatus}
                onChange={InvoiceActions.updateInvoiceField}
              >
                <option>OPEN</option>
                <option>CLOSED</option>
              </Input>
            </FormField>
            <FormField field={'timeStart'} disabled name='Start dato'>
              <DatePicker
                disabled
                selected={moment(this.state.invoiceLine.timeStart)}
                onChange={e => {
                  this.updateDateField(e, 'timeStart')
                }}
              />
            </FormField>
            <FormField field={'timeEnd'} name='Slut dato' disabled>
              <DatePicker
                disabled
                selected={moment(this.state.invoiceLine.timeEnd)}
                onChange={e => {
                  this.updateDateField(e, 'timeEnd')
                }}
              />
            </FormField>
          </Form>
        </CollapsibleCard>
        {!this.props.create && (
          <CollapsibleCard header={<strong>Linier</strong>} startOpen>
            <DynamicTable
              responsive
              size='sm'
              data={this.state.invoiceData}
              headers={this.invoiceHeaders}
              options={{
                fieldFunctions: {
                  timeInsert: e => {
                    return e.timeInsert ? moment(e.timeInsert).format('DD-MM-YYYY HH:mm') : ''
                  },
                  timeShipped: e => {
                    return e.timeShipped ? moment(e.timeShipped).format('DD-MM-YYYY HH:mm') : ''
                  }
                },
                fieldStringFunctions: {
                  timeInsert: e => {
                    return moment(e.timeInsert).format('x')
                  },
                  timeShipped: e => {
                    return moment(e.timeShipped).format('x')
                  }
                }
              }}
            />
          </CollapsibleCard>
        )}
      </React.Fragment>
    )
  }
}

class InvoiceList extends Component {
  constructor(props) {
    super(props)
    this.store = InvoiceStore
  }
  componentDidMount() {
    if (this.state.invoices.length == 0 && !this.state.fetchingInvoices) {
      InvoiceActions.getInvoices(Reflux.GlobalState.LoginStore.BrandID)
    }
  }
  render() {
    if (this.state.fetchingInvoices) {
      return <FontAwesomeIcon icon='spinner' spin />
    }
    return (
      <CollapsibleCard startOpen header={<strong>Fakturaer</strong>}>
        <DynamicTable
          responsive
          size='sm'
          data={this.state.invoices}
          headerscss={{
            invoiceAmountTotal: { textAlign: 'right', whiteSpace: 'nowrap', paddingRight: '26px' }
          }}
          headers={{
            InvoiceID: '#',
            invoiceStatus: 'Status',
            invoiceTitle: 'Titel',
            timeStart: 'Kører fra',
            timeEnd: 'Kører til',
            invoiceAmountTotal: 'Fakturabeløb'
          }}
          options={{
            startSort: {
              column: 'InvoiceID',
              dir: 'DESC'
            },
            addField: e => {
              return (
                <td>
                  <ButtonGroup>
                    <Tooltip target={'edit' + e.InvoiceID}>Detaljer</Tooltip>
                    <Button tag={Link} to={this.props.location.pathname + '/' + e.InvoiceID} id={'edit' + e.InvoiceID}>
                      <FontAwesomeIcon icon='info-circle' />
                    </Button>
                  </ButtonGroup>
                </td>
              )
            },
            fieldFunctions: {
              timeStart: e => {
                return e.timeStart ? moment(e.timeStart).format('DD-MM-YYYY HH:mm') : ''
              },
              timeEnd: e => {
                return e.timeEnd ? moment(e.timeEnd).format('DD-MM-YYYY HH:mm') : ''
              },
              invoiceAmountTotal: e => {
                return e.invoiceAmountTotal > 0 ? currencyFormatter.format(e.invoiceAmountTotal) : '0,00 kr.'
              }
            },
            fieldStringFunctions: {
              timeStart: e => {
                return moment(e.timeStart).format('x')
              },
              timeEnd: e => {
                return moment(e.timeEnd).format('x')
              }
            }
          }}
        />
      </CollapsibleCard>
    )
  }
}
