/* eslint-disable eqeqeq */
import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import DefaultIndex from 'Modules/defaultIndex'
import NotAdmin from 'Modules/notAdmin'
import Blog from 'Modules/blog'
import Post from 'Modules/post'
import User from 'Modules/user'
import Brand from 'Modules/brand/brand'
import Shop from 'Modules/shop'
import CVR2 from 'Modules/cvr2'
import StatList from 'Modules/stat/statList'
import StatDetails from 'Modules/stat/statDetails'
import StatCreate from 'Modules/stat/statCreate'
import StatEdit from 'Modules/stat/statEdit'
import { Order } from 'Modules/order'
import CustomOrder from 'Modules/CustomOrder'
import Wallet from 'Modules/wallet'
import VIB from 'Modules/vib'
import Christmas from 'Modules/christmas'
import CustomCss from 'Modules/customcss'
import Customization from 'Modules/customization'
import AdminInvoice from 'Modules/adminInvoice'
import Invoice from 'Modules/invoice'
import CompanyWallet from 'Modules/companyWallet'
import { KamOverview as SantanderKamOverview, KamCvrDetails as SantanderKamCvrDetails } from 'Modules/kam/santanderKam'
import { SantanderCreateKamCampaign, SantanderKamCampaignView, SantanderKamEditCampaign } from 'Modules/kam/SantanderKamCampaign.js'
import Group from 'Modules/group'
import Envelope from 'Modules/envelope'
import { Logout } from 'Modules/login'
import MacroDetails from 'Modules/macros/macroDetails'
import MacroList from 'Modules/macros/macroList'
import DataImport from 'Modules/dataset/dataImport'
import DatasetList from 'Modules/dataset/datasetList'
import DatasetCreate from 'Modules/dataset/datasetCreate'
import DatasetEdit from 'Modules/dataset/datasetEdit'
import BatchList from 'Modules/batch/batchList'
import BatchPreview from 'Modules/batch/batchPreview'
import ProductList from 'Modules/products/productList'
import ProductCreate from 'Modules/products/productCreate'
import ProductEdit from 'Modules/products/productEdit'
import CampaignCreate from 'Modules/campaigns/campaignCreate'
import CampaignEdit from 'Modules/campaigns/campaignEdit'
import CampaignList from 'Modules/campaigns/campaignList'
import CampaignPreview from 'Modules/campaigns/campaignPreview'
import CatalogCreate from 'Modules/catalogs/catalogCreate'
import CatalogEdit from 'Modules/catalogs/catalogEdit'
import CatalogList from 'Modules/catalogs/catalogList'
import verifyRequirements from 'Components/authorization.js'
import KamDetails from 'Modules/kamDetails'
import ClientList from 'Modules/client/clientList'
import ClientCreate from 'Modules/client/clientCreate'
import ClientEdit from 'Modules/client/clientEdit'
import MenuList from 'Modules/menu/menuList'
import MenuCreate from 'Modules/menu/menuCreate'
import MenuEdit from 'Modules/menu/menuEdit'
import Maintenance from 'Modules/maintenance/'
import CompanyList from 'Modules/company/companyList'
import CompanyCreate from 'Modules/company/companyCreate'
import CompanyEdit from 'Modules/company/companyEdit'
import HOFList from 'Modules/hallOfFame/hofList'
import HOFCreate from 'Modules/hallOfFame/hofCreate'
import HOFEdit from 'Modules/hallOfFame/hofEdit'
import HOFElements from 'Modules/hallOfFame/hofElements'
import TranslationCreate from 'Modules/translations/translationCreate'
import TranslationEdit from 'Modules/translations/translationEdit'
import TranslationList from 'Modules/translations/translationList'
import Settings from 'Modules/settings'
import VaultCreate from 'Modules/vault/vaultCreate'
import VaultEdit from 'Modules/vault/vaultEdit'
import VaultList from 'Modules/vault/vaultList'
import CategoryList from 'Modules/category/categoryList'
import CategoryCreate from 'Modules/category/categoryCreate'
import CategoryEdit from 'Modules/category/categoryEdit'
import AdminUsersList from 'Modules/adminUsersList'
import PointsEnvelope from 'Modules/pointsEnvelope'
import WalletList from 'Modules/walletList'
import AdminBatchList from 'Modules/batch/adminBatchList'
import OrderPrint from 'Modules/orderPrint'
import AdminDatasetList from 'Modules/dataset/adminDatasetList'
import AdminDatasetElements from 'Modules/dataset/adminDatasetElements'
import CommunicationList from 'Modules/communication/communicationList'
import CommunicationDetails from 'Modules/communication/communicationDetails'
import ValidationCodeList from 'Modules/validationCodeList'
import FreightOverview from 'Modules/freight/freightOverview'
import FreightElements from 'Modules/freight/freightElements'
import FreightBrandOverview from 'Modules/freight/freightBrandOverview'
import AddressesList from 'Modules/addresses/addressesList'
import AddressCreate from 'Modules/addresses/addressCreate'
import AddressEdit from 'Modules/addresses/addressEdit'
import KAMOverview from 'Modules/kams/kamOverview'
import KAMCompanyOverview from 'Modules/kams/kamCompanyOverview'
import KAMCompanyDetails from 'Modules/kams/kamCompanyDetails'
import KamCompanyDashboard from 'Modules/kams/kamCompanyDashboard'
import Imports from 'Modules/import/imports'
import ImportUsers from 'Modules/import/importUsers'
import ImportPoints from 'Modules/import/importPoints'
import ProductsOverview from 'Modules/stock/productsOverview'
import ProductHistoryOverview from 'Modules/stock/productHistoryOverview'
import ImportsOverview from 'Modules/import/importsOverview'
import ImportsBatchPreview from 'Modules/import/importsBatchPreview'
import ImportOrders from 'Modules/import/importOrders'
import UserObfuscationSchedule from 'Modules/dataRetention/userObfuscationSchedule'
import OrderObfuscationSchedule from 'Modules/dataRetention/orderObfuscationSchedule'
import SupplierCreate from 'Modules/supplier/supplierCreate'
import SupplierEdit from 'Modules/supplier/supplierEdit'
import SupplierOverview from 'Modules/supplier/supplierOverview'
import AnnouncementOverview from 'Modules/announcement/announcementOverview'
import AnnouncementCreate from 'Modules/announcement/announcementCreate'
import AnnouncementEdit from 'Modules/announcement/announcementEdit'
import CustomerProductCreate from 'Modules/customerProduct/customerProductCreate'
import CustomerProductEdit from 'Modules/customerProduct/customerProductEdit'
import CustomerProductOverview from 'Modules/customerProduct/customerProductOverview'
import CronOverview from 'Modules/cron/cronOverview'
import ObfuscateBrand from '../modules/brand/obfuscateBrand'
import ClubCMS from 'Modules/cms/clubCMS'
import UserReports from 'Modules/reports/userReports'
import CustomerSalesRewardCreate from 'Modules/customerSalesReward/customerSalesRewardCreate'
import CustomerSalesRewardEdit from 'Modules/customerSalesReward/customerSalesRewardEdit'
import CustomerSalesRewardOverview from 'Modules/customerSalesReward/customerSalesRewardOverview'
import CustomerSalesClaimOverview from 'Modules/customerSalesClaim/customerSalesClaimOverview'
import ColorsOverview from 'Modules/colors/ColorsOverview'
import ColorsForm from 'Modules/colors/ColorsForm'
import DashboardFrame from '../modules/zohoDashboards/dashboardFrame'
import DepartmentOverview from '../modules/department/departmentOverview'
import DepartmentForm from '../modules/department/departmentForm'
import AllEarnedPoints from '../modules/reports/AllEarnedPoints'
import AllUsedPoints from '../modules/reports/AllUsedPoints'
import AllUnusedPoints from '../modules/reports/AllUnusedPoints'
import ActiveInactiveUsers from '../modules/reports/ActiveInactiveUsers'
import ActiveInactiveUsersPoints from '../modules/reports/ActiveInactiveUsersPoints'
import OrdersListWithinDates from '../modules/reports/OrdersListWithinDates'
import TaxReport from '../modules/reports/TaxReport'
import DealersListWithinDates from '../modules/reports/DealersListWithinDates'
import CompetitionOverview from '../modules/Competition/CompetitionOverview'
import CompetitionUsers from '../modules/Competition/CompetitionUsers'
import CompetitionForm from '../modules/Competition/CompetitionForm'
import UserGoalsOverview from '../modules/goals/UserGoalsOverview'
import UserGoalsForm from '../modules/goals/UserGoalsForm'
import CampaignOverview from '../modules/campaignNotification/campaignOverview'
import CampaignReport from '../modules/campaignNotification/campaignReport'
import CampaignForm from '../modules/campaignNotification/campaignForm'
import TemplateForm from '../modules/notificationtTemplate/TemplateForm'
import CampaignUsers from '../modules/campaignNotification/campaignUsers'
import ChristmasCalendarOverview from '../modules/ChristmasCalendar/ChristmasCalendarOverview'
import ChristmasCalendarForm from '../modules/ChristmasCalendar/ChristmasCalendarForm'
import TaxOverview from '../modules/reports/TaxOverview'
import ChristmasCalendarDaysForm from '../modules/ChristmasCalendar/ChristmasCalendarDaysForm'
import LanguagesList from '../modules/translations/LanguagesList'
import LanguageCreate from '../modules/translations/LanguageCreate'
import PackageOverview from '../modules/Package/PackageOverview'
import PackageForm from '../modules/Package/PackageForm'
import WalletActivationList from 'Modules/walletActivationList'
import EmailConfiguration from '../modules/translations/EmailConfiguration'
import HighlightedProduct from '../modules/cms/highlightedProduct/highlightedProduct.js'
import HighlightedProductOverview from '../modules/cms/highlightedProduct/highlightedProductOverview'
import Shops from '../modules/cms/shop/shop'
import ShopOverview from '../modules/cms/shop/shopOverview'
import CPROverview from '../modules/CPR/CPROverview'
import PointExpireCommunication from '../modules/PointExpire/PointExpireCommunication'
import PointExpireOverview from '../modules/PointExpire/PointExpireOverview'
import ConfigureLabels from '../modules/cms/ConfigureLabels'
import EarningsTaxReport from '../modules/reports/EarningsTaxReport.js'
import MonthlyTaxInfo from '../modules/reports/MonthlyTaxInfo.js'
import KamDashboard from '../modules/zohoDashboards/kamDashboard.js'
import { SubCategories } from '../modules/category/subCategory.js'
import AdminToBeInvoiced from '../modules/adminTobeInvoiced.js'
import CountDownList from '../modules/countdown/CountdownList.js'
import CountdownForm from '../modules/countdown/CountdownForm.js'
import FontList from '../modules/font/FontList.js'
import FontForm from '../modules/font/FontForm.js'
import TaxCampaignHighlightList from '../modules/taxCampaignHighlight/TaxCampaignHighlightList.js'
import TaxCampaignHighlightForm from '../modules/taxCampaignHighlight/TaxCampaignHighlightForm.js'
import Top8List from '../modules/top8/Top8List.js'
import Top8Form from '../modules/top8/Top8Form.js'
import ParcelBrandOverview from 'Modules/freight/parcelBrandOverview.js'
import MerchInvoice from '../modules/invoice/merchInvoice.js'
import MerchInvoiceDetails from '../modules/invoice/merchInvoiceDetails.js'
import UserPromptList from '../modules/userPrompt/userPromptList.js'
import UserPromptCreate from '../modules/userPrompt/userPromptCreate.js'
import UserPromptEdit from '../modules/userPrompt/userPromptEdit.js'

const _routes = [
  /* home */
  {
    path: '/',
    exact: true,
    name: 'home',
    component: DefaultIndex,
    hasMenu: true,
    icon: <FontAwesomeIcon icon='home' className='nav-icon' />,
    hideMenuText: true
  },
  /* Test area */
  // {
  //   path: '/test',
  //   exact: true,
  //   name: 'Test',
  //   component: SantanderKamOverview,
  //   hasMenu: true,
  //   icon: <FontAwesomeIcon icon='vial' className='nav-icon' />,
  //   requirements: {
  //     userRole: 'SYSTEM',
  //     userPrivilege: 'MANAGE'
  //   },
  //   children: []
  // },
  /* cvr2 */
  {
    path: '/administrator',
    name: 'Administrator',
    hasSubMenu: true,
    hasMenu: true,
    icon: <FontAwesomeIcon icon='user-shield' className='nav-icon' />,
    requirements: {
      userRole: 'SYSTEM',
      userPrivilege: 'MANAGE'
    },
    children: [
      {
        url: '/access',
        exact: true,
        name: 'Access',
        component: ValidationCodeList,
        hasMenu: true,
        icon: <FontAwesomeIcon icon='list-alt' className='nav-icon' />,
        requirements: {
          BrandID: 9,
          userRole: 'SYSTEM',
          userPrivilege: 'MANAGE'
        }
      },
      {
        url: '/admin-users',
        exact: true,
        name: 'Admin Brugere',
        component: AdminUsersList,
        hasMenu: true,
        icon: <FontAwesomeIcon icon='users' className='nav-icon' />,
        requirements: {
          userRole: 'SYSTEM',
          userPrivilege: 'MANAGE'
        },
        children: [
          {
            name: 'Oversigt',
            url: '/admin-users',
            exact: true,
            icon: <FontAwesomeIcon icon='table' className='nav-icon' />
          },
          {
            name: 'Opret bruger',
            url: '/admin-users/new',
            icon: <FontAwesomeIcon icon='user-plus' className='nav-icon' />
          }
        ]
      },
      {
        url: '/address',
        exact: true,
        name: 'Adresser',
        component: AddressesList,
        hasMenu: true,
        icon: <FontAwesomeIcon icon='address-card' className='nav-icon' />,
        requirements: {
          userRole: 'SYSTEM',
          userPrivilege: 'MANAGE'
        },
        children: [
          {
            name: 'Oversigt',
            url: '/address',
            exact: true,
            icon: <FontAwesomeIcon icon='table' className='nav-icon' />
          },
          {
            name: 'Opret Adresser',
            url: '/address/new',
            icon: <FontAwesomeIcon icon='user-plus' className='nav-icon' />
          }
        ]
      },
      {
        url: '/announcements',
        exact: true,
        name: 'Announcement',
        component: AnnouncementOverview,
        hasMenu: true,
        icon: <FontAwesomeIcon icon='bullhorn' className='nav-icon' />,
        requirements: {
          userRole: 'SYSTEM',
          userPrivilege: 'MANAGE'
        },
        children: [
          {
            name: 'Oversigt',
            url: '/announcements',
            exact: true,
            icon: <FontAwesomeIcon icon='table' className='nav-icon' />
          },
          {
            name: 'Opret Announcement',
            url: '/announcements/new',
            icon: <FontAwesomeIcon icon='plus-square' className='nav-icon' />
          }
        ]
      },
      {
        url: '/user-prompts',
        exact: true,
        name: 'User Prompts',
        component: UserPromptList,
        hasMenu: true,
        icon: <FontAwesomeIcon icon='bullhorn' className='nav-icon' />,
        requirements: {
          userRole: 'SYSTEM',
          userPrivilege: 'MANAGE'
        },
        children: [
          {
            name: 'Oversigt',
            url: '/user-prompts',
            exact: true,
            icon: <FontAwesomeIcon icon='table' className='nav-icon' />
          },
          {
            name: 'Opret User Prompt',
            url: '/user-prompts/new',
            icon: <FontAwesomeIcon icon='plus-square' className='nav-icon' />
          }
        ]
      },
      {
        url: '/batches',
        exact: true,
        name: 'Batches',
        component: AdminBatchList,
        hasMenu: true,
        icon: <FontAwesomeIcon icon='list-alt' className='nav-icon' />,
        requirements: {
          BrandID: 9,
          userRole: 'SYSTEM',
          userPrivilege: 'MANAGE'
        }
      },
      {
        name: 'Brand',
        url: '/brand',
        component: Brand,
        hasMenu: true,
        icon: <FontAwesomeIcon icon='building' className='nav-icon' />,
        children: [
          {
            name: 'Oversigt',
            url: '/brand',
            props: { overview: true },
            exact: true,
            icon: <FontAwesomeIcon icon='table' className='nav-icon' />
          },
          {
            name: 'Opret brand',
            url: '/brand/new',
            exact: true,
            icon: <FontAwesomeIcon icon='plus' className='nav-icon' />
          },
          {
            name: 'Obfuscate brand',
            url: '/brand/obfuscate',
            exact: true,
            icon: <FontAwesomeIcon icon='trash' className='nav-icon' />
          }
        ],
        requirements: {
          BrandID: 9,
          userRole: 'System',
          userPrivilege: 'MANAGE'
        }
      },
      {
        url: '/shop',
        exact: true,
        name: 'Butik',
        component: Shop,
        hasMenu: true,
        icon: <FontAwesomeIcon icon='shopping-cart' className='nav-icon' />,
        requirements: {
          userRole: 'AGENT',
          userPrivilege: 'MANAGE'
        },
        children: [
          {
            name: 'Oversigt',
            url: '/shop',
            exact: true,
            icon: <FontAwesomeIcon icon='table' className='nav-icon' />
          },
          {
            name: 'Opret butik',
            url: '/shop/new',
            icon: <FontAwesomeIcon icon='cart-plus' className='nav-icon' />
          }
        ]
      },
      {
        name: 'Company',
        url: '/company',
        component: CompanyList,
        icon: <FontAwesomeIcon icon='building' className='nav-icon' />,
        hasMenu: true,
        children: [
          {
            name: 'Oversigt',
            url: '/company',
            exact: true,
            icon: <FontAwesomeIcon icon='table' className='nav-icon' />
          },
          {
            name: 'Opret Company',
            url: '/company/new',
            icon: <FontAwesomeIcon icon='plus' className='nav-icon' />
          }
        ],
        requirements: {
          BrandID: 9,
          userRole: 'System',
          userPrivilege: 'MANAGE'
        }
      },
      {
        name: 'Client',
        url: '/client',
        component: ClientList,
        icon: <FontAwesomeIcon icon='building' className='nav-icon' />,
        hasMenu: true,
        children: [
          {
            name: 'Oversigt',
            url: '/client',
            exact: true,
            icon: <FontAwesomeIcon icon='table' className='nav-icon' />
          },
          {
            name: 'Opret Client',
            url: '/client/new',
            icon: <FontAwesomeIcon icon='plus' className='nav-icon' />
          }
        ],
        requirements: {
          BrandID: 9,
          userRole: 'System',
          userPrivilege: 'MANAGE'
        }
      },
      {
        url: '/cron',
        exact: true,
        name: 'Cron overview',
        component: CronOverview,
        hasMenu: true,
        icon: <FontAwesomeIcon icon='cogs' className='nav-icon' />,
        requirements: {
          BrandID: [9],
          userRole: 'System',
          userPrivilege: 'MANAGE'
        }
      },
      {
        url: '/customcss',
        exact: true,
        name: 'Custom CSS',
        component: CustomCss,
        icon: <FontAwesomeIcon icon={['fab', 'css3']} className='nav-icon' />,
        requirements: {
          userRole: 'SYSTEM'
        }
      },
      {
        url: '/customization',
        exact: true,
        name: 'Customization',
        component: Customization,
        hasMenu: true,
        icon: <FontAwesomeIcon icon='sliders-h' className='nav-icon' />,
        requirements: {
          userRole: 'SYSTEM',
          userPrivilege: 'MANAGE'
        }
      },
      {
        name: 'CPR',
        url: '/cpr',
        component: CPROverview,
        hasMenu: true,
        icon: <FontAwesomeIcon icon='id-badge' className='nav-icon' />,
        children: [
          {
            name: 'Oversigt',
            url: '/cpr',
            exact: true,
            icon: <FontAwesomeIcon icon='table' className='nav-icon' />
          }
        ]
      },
      {
        url: '/dataset',
        exact: true,
        name: 'Dataset',
        component: DatasetList,
        hasMenu: true,
        icon: <FontAwesomeIcon icon='th' className='nav-icon' />,
        children: [
          {
            name: 'Oversigt',
            url: '/dataset',
            exact: true,
            icon: <FontAwesomeIcon icon='table' className='nav-icon' />
          },
          {
            name: 'Opret dataset',
            url: '/dataset/new',
            icon: <FontAwesomeIcon icon='users-cog' className='nav-icon' />
          }
        ],
        requirements: {
          userRole: 'System',
          userPrivilege: 'MANAGE'
        }
      },
      {
        url: '/department',
        exact: true,
        name: 'Department',
        component: DepartmentOverview,
        hasMenu: true,
        icon: <FontAwesomeIcon icon='th' className='nav-icon' />,
        children: [
          {
            name: 'Oversigt',
            url: '/department',
            exact: true,
            icon: <FontAwesomeIcon icon='table' className='nav-icon' />
          },
          {
            name: 'Opret department',
            url: '/department/new',
            icon: <FontAwesomeIcon icon='users-cog' className='nav-icon' />
          }
        ],
        requirements: {
          userRole: 'System',
          userPrivilege: 'MANAGE'
        }
      },
      {
        url: '/hof',
        exact: true,
        name: 'Hall Of Fame',
        component: HOFList,
        hasMenu: true,
        icon: <FontAwesomeIcon icon='clipboard-list' className='nav-icon' />,
        children: [
          {
            name: 'Oversigt',
            url: '/hof',
            exact: true,
            icon: <FontAwesomeIcon icon='table' className='nav-icon' />
          },
          {
            name: 'Opret HallOfFame',
            url: '/hof/new',
            icon: <FontAwesomeIcon icon='users-cog' className='nav-icon' />
          }
        ],
        requirements: {
          userRole: 'SYSTEM',
          userPrivilege: 'MANAGE'
        }
      },
      {
        url: '/countdown',
        exact: true,
        name: 'Countdown',
        component: CountDownList,
        hasMenu: true,
        icon: <FontAwesomeIcon icon='clock' className='nav-icon' />,
        children: [
          {
            name: 'Oversigt',
            url: '/countdown',
            exact: true,
            icon: <FontAwesomeIcon icon='table' className='nav-icon' />
          },
          {
            name: 'Opret Nedtælling',
            url: '/countdown/new',
            icon: <FontAwesomeIcon icon='users-cog' className='nav-icon' />
          }
        ],
        requirements: {
          BrandID: [9],
          userRole: 'SYSTEM',
          userPrivilege: 'MANAGE'
        }
      },
      {
        url: '/tax-campaign-highlight',
        exact: true,
        name: 'Tax Camp. Highlight',
        component: TaxCampaignHighlightList,
        hasMenu: true,
        icon: <FontAwesomeIcon icon='cog' className='nav-icon' />,
        children: [
          {
            name: 'Oversigt',
            url: '/tax-campaign-highlight',
            exact: true,
            icon: <FontAwesomeIcon icon='table' className='nav-icon' />
          },
          {
            name: 'Opret Nedtælling',
            url: '/tax-campaign-highlight/new',
            icon: <FontAwesomeIcon icon='users-cog' className='nav-icon' />
          }
        ],
        requirements: {
          BrandID: [9],
          userRole: 'SYSTEM',
          userPrivilege: 'MANAGE'
        }
      },
      {
        url: '/top8',
        exact: true,
        name: 'Top 8',
        component: Top8List,
        hasMenu: true,
        icon: <FontAwesomeIcon icon='star' className='nav-icon' />,
        children: [
          {
            name: 'Oversigt',
            url: '/top8',
            exact: true,
            icon: <FontAwesomeIcon icon='table' className='nav-icon' />
          },
          {
            name: 'Opret Nedtælling',
            url: '/top8/new',
            icon: <FontAwesomeIcon icon='users-cog' className='nav-icon' />
          }
        ],
        requirements: {
          BrandID: [9],
          userRole: 'SYSTEM',
          userPrivilege: 'MANAGE'
        }
      },
      {
        url: '/font',
        exact: true,
        name: 'Font',
        component: FontList,
        hasMenu: true,
        icon: <FontAwesomeIcon icon='font' className='nav-icon' />,
        children: [
          {
            name: 'Oversigt',
            url: '/font',
            exact: true,
            icon: <FontAwesomeIcon icon='table' className='nav-icon' />
          },
          {
            name: 'Opret Skrifttype',
            url: '/font/new',
            icon: <FontAwesomeIcon icon='users-cog' className='nav-icon' />
          }
        ]
      },
      {
        url: '/communication',
        name: 'Kommunikation',
        hasMenu: true,
        icon: <FontAwesomeIcon icon='envelope-open-text' className='nav-icon' />,
        requirements: {
          BrandID: 9,
          userRole: 'System',
          userPrivilege: 'MANAGE'
        }
      },
      {
        name: 'Menu',
        url: '/menu',
        component: MenuList,
        hasMenu: true,
        icon: <FontAwesomeIcon icon='building' className='nav-icon' />,
        children: [
          {
            name: 'Oversigt',
            url: '/menu',
            exact: true,
            icon: <FontAwesomeIcon icon='table' className='nav-icon' />
          },
          {
            name: 'Opret Menu',
            url: '/menu/new',
            icon: <FontAwesomeIcon icon='plus' className='nav-icon' />
          }
        ],
        requirements: {
          BrandID: 9,
          userRole: 'System',
          userPrivilege: 'MANAGE'
        }
      },
      {
        name: 'Translations',
        url: '/translations',
        component: TranslationList,
        hasMenu: true,
        icon: <FontAwesomeIcon icon='language' className='nav-icon' />,
        children: [
          {
            name: 'Oversigt',
            url: '/translations',
            exact: true,
            icon: <FontAwesomeIcon icon='table' className='nav-icon' />
          },
          {
            name: 'Opret Translation',
            url: '/translations/new',
            icon: <FontAwesomeIcon icon='plus' className='nav-icon' />
          },
          {
            name: 'Languages',
            url: '/translations/languages',
            icon: <FontAwesomeIcon icon='language' className='nav-icon' />
          }
        ],
        requirements: {
          BrandID: [9],
          userRole: 'SYSTEM',
          userPrivilege: 'MANAGE'
        }
      },
      {
        name: 'Upgrader Club',
        url: '/upgraderclub',
        component: ClubCMS,
        hasMenu: true,
        icon: <FontAwesomeIcon icon='cog' className='nav-icon' />,
        children: [
          {
            name: 'Oversigt',
            url: '/manage-content',
            exact: true,
            icon: <FontAwesomeIcon icon='table' className='nav-icon' />
          },
          {
            name: 'Konkurrencer',
            url: '/competition',
            exact: true,
            icon: <FontAwesomeIcon icon='trophy' className='nav-icon' />
          },
          {
            name: 'Julekalender',
            url: '/christmas-calendar',
            exact: true,
            icon: <FontAwesomeIcon icon='tree' className='nav-icon' />
          },
          {
            name: 'Configure Labels',
            url: '/configure-labels',
            exact: true,
            icon: <FontAwesomeIcon icon='cogs' className='nav-icon' />
          },
          {
            name: 'Email',
            url: '/upgraderclub/email-configuration',
            icon: <FontAwesomeIcon icon='envelope' className='nav-icon' />
          }
        ],
        requirements: {
          userRole: 'SYSTEM',
          userPrivilege: 'MANAGE'
        }
      },
      {
        name: 'Highlighted product',
        url: '/highlighted-product',
        component: HighlightedProduct,
        hasMenu: true,
        icon: <FontAwesomeIcon icon='cog' className='nav-icon' />,
        children: [
          {
            name: 'Oversigt',
            url: '/highlighted-product',
            exact: true,
            icon: <FontAwesomeIcon icon='table' className='nav-icon' />
          },
          {
            name: 'Opret',
            url: '/highlighted-product/new',
            exact: true,
            icon: <FontAwesomeIcon icon='plus' className='nav-icon' />
          }
        ],
        requirements: {
          BrandID: [9],
          userRole: 'SYSTEM',
          userPrivilege: 'MANAGE'
        }
      },
      {
        name: 'Shop',
        url: '/shops',
        component: Shop,
        hasMenu: true,
        icon: <FontAwesomeIcon icon='cog' className='nav-icon' />,
        children: [
          {
            name: 'Oversigt',
            url: '/shops',
            exact: true,
            icon: <FontAwesomeIcon icon='table' className='nav-icon' />
          },
          {
            name: 'Opret',
            url: '/shops/new',
            exact: true,
            icon: <FontAwesomeIcon icon='plus' className='nav-icon' />
          }
        ],
        requirements: {
          BrandID: [9],
          userRole: 'SYSTEM',
          userPrivilege: 'MANAGE'
        }
      },
      {
        name: 'Reports',
        url: '/user-reports',
        component: UserReports,
        hasMenu: true,
        icon: <FontAwesomeIcon icon='chart-bar' className='nav-icon' />,
        children: [
          {
            name: 'User Reports',
            url: '/user-reports',
            exact: true,
            icon: <FontAwesomeIcon icon='signal' className='nav-icon' />
          }
        ],
        requirements: {
          userRole: 'SYSTEM',
          userPrivilege: 'MANAGE'
        }
      },
      {
        name: 'Vaults',
        url: '/vaults',
        component: VaultList,
        hasMenu: true,
        icon: <FontAwesomeIcon icon='wallet' className='nav-icon' />,
        children: [
          {
            name: 'Oversigt',
            url: '/vaults',
            exact: true,
            icon: <FontAwesomeIcon icon='table' className='nav-icon' />
          },
          {
            name: 'Opret Vault',
            url: '/vaults/new',
            icon: <FontAwesomeIcon icon='plus' className='nav-icon' />
          }
        ],
        requirements: {
          BrandID: [9],
          userRole: 'SYSTEM',
          userPrivilege: 'MANAGE'
        }
      },
      {
        url: '/vibee',
        exact: true,
        name: 'vibee qr',
        component: VIB,
        hasMenu: true,
        icon: <FontAwesomeIcon icon='qrcode' className='nav-icon' />,
        requirements: {
          BrandID: 9,
          userRole: 'BRAND',
          userPrivilege: 'MANAGE'
        }
      },
      {
        url: '/wallets',
        exact: true,
        name: 'Wallets',
        component: WalletList,
        hasMenu: true,
        icon: <FontAwesomeIcon icon='wallet' className='nav-icon' />,
        requirements: {
          BrandID: 9,
          userRole: 'SYSTEM',
          userPrivilege: 'MANAGE'
        }
      },
      {
        url: '/x-files',
        exact: true,
        name: 'X-Files',
        component: AdminDatasetList,
        hasMenu: true,
        icon: <FontAwesomeIcon icon='table' className='nav-icon' />,
        requirements: {
          BrandID: 9,
          userRole: 'SYSTEM',
          userPrivilege: 'MANAGE'
        }
      }
    ]
  },
  {
    path: '/dashboard',
    exact: true,
    name: 'Dashboard',
    component: KamDashboard,
    hasMenu: true,
    icon: <FontAwesomeIcon icon='home' className='nav-icon' />,
    requirements: [
      {
        BrandID: [63],
        userRole: 'CLIENT',
        userPrivilege: 'MANAGE',
        features: { dashboard: true }
      }
    ]
  },
  /* users */
  {
    path: '/users',
    exact: true,
    name: 'Brugere',
    component: User,
    hasMenu: true,
    icon: <FontAwesomeIcon icon='users' className='nav-icon' />,
    requirements: [
      {
        BrandID: { exclude: 121 },
        userRole: 'BRAND',
        userPrivilege: 'MANAGE'
      },
      {
        features: { allowUserAdmin: true }
      }
    ],
    children: [
      {
        name: 'Oversigt',
        url: '/users',
        exact: true,
        icon: <FontAwesomeIcon icon='table' className='nav-icon' />
      },
      {
        name: 'Opret bruger',
        url: '/users/new',
        icon: <FontAwesomeIcon icon='user-plus' className='nav-icon' />
      },
      {
        name: 'Scheduled obfuscation',
        url: '/users/obfuscation/scheduled',
        icon: <FontAwesomeIcon icon='trash' className='nav-icon' />,
        requirements: {
          userRole: 'SYSTEM',
          userPrivilege: 'MANAGE'
        }
      }
    ]
  },
  {
    path: '/users/new',
    exact: true,
    name: 'Opret bruger',
    props: { create: true },
    component: User,
    requirements: [
      {
        userRole: 'BRAND',
        userPrivilege: 'MANAGE'
      },
      {
        features: { allowUserAdmin: true }
      }
    ]
  },
  {
    path: '/users/:UserID',
    exact: true,
    name: 'Detaljer',
    component: User,
    requirements: [
      {
        BrandID: { exclude: 121 },
        userRole: 'BRAND',
        userPrivilege: 'MANAGE'
      },
      {
        features: { allowUserAdmin: true }
      }
    ]
  },
  {
    path: '/users/:UserID/wallet/new',
    name: 'Opret',
    exact: true,
    component: Wallet,
    props: { create: true },
    requirements: [
      {
        userRole: 'BRAND',
        userPrivilege: 'MANAGE'
      },
      {
        features: { allowUserAdmin: true }
      }
    ]
  },
  {
    path: '/users/:UserID/wallet',
    exact: true,
    name: 'Wallet',
    component: Wallet,
    requirements: [
      {
        BrandID: { exclude: 121 },
        userRole: 'BRAND',
        userPrivilege: 'MANAGE'
      },
      {
        features: { allowUserAdmin: true }
      }
    ]
  },
  {
    path: '/users/:UserID/wallet/:WalletID',
    name: 'Detaljer',
    exact: true,
    component: Wallet,
    requirements: [
      {
        BrandID: { exclude: 121 },
        userRole: 'BRAND',
        userPrivilege: 'MANAGE'
      },
      {
        features: { allowUserAdmin: true }
      }
    ]
  },
  {
    path: '/users/obfuscation/scheduled',
    name: 'Scheduled obfuscation',
    exact: true,
    component: UserObfuscationSchedule,
    requirements: {
      userRole: 'SYSTEM',
      userPrivilege: 'MANAGE'
    }
  },

  {
    path: '/users',
    exact: true,
    name: 'Brugere',
    component: User,
    hasMenu: true,
    icon: <FontAwesomeIcon icon='users' className='nav-icon' />,
    requirements: {
      BrandID: [121],
      userRole: 'BRAND',
      userPrivilege: 'VIEW'
    },
    children: [
      {
        name: 'Oversigt',
        url: '/users',
        exact: true,
        icon: <FontAwesomeIcon icon='table' className='nav-icon' />
      },
      {
        name: 'Opret bruger',
        url: '/users/new',
        icon: <FontAwesomeIcon icon='user-plus' className='nav-icon' />,
        requirements: {
          BrandID: [121],
          userRole: 'BRAND',
          userPrivilege: 'MANAGE'
        }
      }
    ]
  },
  {
    path: '/users/:UserID',
    exact: true,
    name: 'Detaljer',
    component: User,
    requirements: {
      BrandID: [121],
      userRole: 'BRAND',
      userPrivilege: 'VIEW'
    }
  },
  {
    path: '/users/:UserID/wallet',
    exact: true,
    name: 'Wallet',
    component: Wallet,
    requirements: {
      BrandID: [121],
      userRole: 'BRAND',
      userPrivilege: 'VIEW'
    }
  },
  {
    path: '/users/:UserID/wallet/:WalletID',
    name: 'Detaljer',
    exact: true,
    component: Wallet,
    requirements: {
      BrandID: [121],
      userRole: 'BRAND',
      userPrivilege: 'VIEW'
    }
  },
  {
    path: '/walletactivation',
    exact: true,
    name: 'Wallet Activation',
    component: WalletActivationList,
    hasMenu: true,
    icon: <FontAwesomeIcon icon='wallet' className='nav-icon' />,
    requirements: [
      {
        features: { enableWalletActivation: true }
      },
      {
        features: { allowUserAdmin: true }
      }
    ],
    children: [
      {
        name: 'Oversigt',
        url: '/walletactivation',
        exact: true,
        icon: <FontAwesomeIcon icon='table' className='nav-icon' />
      },
      {
        name: 'Change Points',
        url: '/walletactivation-edit',
        exact: true,
        icon: <FontAwesomeIcon icon='plus-square' className='nav-icon' />
      },
      {
        name: 'Point Expire',
        url: '/pointExpire',
        exact: true,
        icon: <FontAwesomeIcon icon='envelope-open-text' className='nav-icon' />,
        children: [
          {
            name: 'Oversigt',
            url: '/pointExpire',
            exact: true,
            icon: <FontAwesomeIcon icon='table' className='nav-icon' />
          },
          {
            name: 'EMAIL',
            url: '/pointExpire/communication',
            exact: true,
            icon: <FontAwesomeIcon icon='envelope' className='nav-icon' />
          }
        ]
      }
    ]
  },
  {
    path: '/walletactivation-edit',
    exact: true,
    name: 'Change Points',
    component: WalletActivationList,
    props: { edit: true },
    requirements: [
      {
        features: { enableWalletActivation: true }
      },
      {
        features: { allowUserAdmin: true }
      }
    ]
  },
  /*Bruger KPI*/
  {
    path: '/usergoals',
    exact: true,
    name: 'Bruger KPI',
    component: UserGoalsOverview,
    hasMenu: true,
    icon: <FontAwesomeIcon icon='bullseye' className='nav-icon' />,
    requirements: {
      userRole: 'BRAND',
      userPrivilege: 'MANAGE',
      features: { activateBrugerKPI: true }
    },
    children: [
      {
        name: 'Oversigt',
        url: '/usergoals',
        exact: true,
        icon: <FontAwesomeIcon icon='table' className='nav-icon' />
      },
      {
        name: 'Opret User Goals',
        url: '/usergoals/new',
        icon: <FontAwesomeIcon icon='plus-square' className='nav-icon' />
      }
    ]
  },
  {
    path: '/usergoals/new',
    exact: true,
    name: 'Opret User Goals',
    component: UserGoalsForm,
    props: { create: true },
    requirements: {
      userRole: 'BRAND',
      userPrivilege: 'MANAGE'
    }
  },
  {
    path: '/usergoals/:UserID',
    exact: true,
    name: 'Edit User Goals',
    component: UserGoalsForm,
    requirements: {
      userRole: 'BRAND',
      userPrivilege: 'MANAGE'
    }
  },

  /* Blogs */
  {
    path: '/blog',
    exact: true,
    name: 'Blogs	',
    component: Blog,
    hasMenu: true,
    icon: <FontAwesomeIcon icon='newspaper' className='nav-icon' />,
    requirements: {
      userRole: 'BRAND',
      userPrivilege: 'MANAGE'
    },
    children: [
      {
        name: 'Oversigt',
        url: '/blog',
        exact: true,
        icon: <FontAwesomeIcon icon='table' className='nav-icon' />
      },
      {
        name: 'Opret blog',
        url: '/blog/new',
        icon: <FontAwesomeIcon icon='plus-square' className='nav-icon' />
      }
    ]
  },
  {
    path: '/blog/new',
    exact: true,
    name: 'Opret blog',
    component: Blog,
    props: { create: true },
    requirements: {
      userRole: 'BRAND',
      userPrivilege: 'MANAGE'
    }
  },
  {
    path: '/blog/:BlogID',
    exact: true,
    name: 'Detaljer',
    component: Blog,
    requirements: {
      userRole: 'BRAND',
      userPrivilege: 'MANAGE'
    }
  },
  {
    path: '/blog/:BlogID/posts',
    exact: true,
    name: 'Poster',
    component: Post,
    requirements: {
      userRole: 'BRAND',
      userPrivilege: 'MANAGE'
    }
  },
  {
    path: '/blog/:BlogID/posts/new',
    exact: true,
    name: 'Opret',
    component: Post,
    props: { create: true },
    requirements: {
      userRole: 'BRAND',
      userPrivilege: 'MANAGE'
    }
  },
  {
    path: '/blog/:BlogID/posts/:PostID',
    name: 'Opret',
    component: Post,
    requirements: {
      userRole: 'BRAND',
      userPrivilege: 'MANAGE'
    }
  },

  /* Notifications */
  {
    path: '/envelope',
    exact: true,
    name: 'Besked',
    component: Envelope,
    hasMenu: true,
    icon: <FontAwesomeIcon icon='bell' className='nav-icon' />,
    requirements: {
      userRole: 'BRAND',
      userPrivilege: 'MANAGE'
    },
    children: [
      {
        name: 'Oversigt',
        url: '/envelope',
        exact: true,
        icon: <FontAwesomeIcon icon='table' className='nav-icon' />
      },
      {
        name: 'Opret besked',
        url: '/envelope/new',
        icon: <FontAwesomeIcon icon='users-cog' className='nav-icon' />
      },
      {
        name: 'Point besked',
        url: '/envelope/points',
        icon: <FontAwesomeIcon icon='envelope-open-text' className='nav-icon' />
      },
      {
        name: 'Sendte beskeder',
        url: '/communication/points',
        icon: <FontAwesomeIcon icon='envelope-open-text' className='nav-icon' />
      }
    ]
  },
  {
    path: '/envelope/new',
    exact: true,
    name: 'Opret besked',
    component: Envelope,
    props: { create: true },
    requirements: {
      userRole: 'BRAND',
      userPrivilege: 'MANAGE'
    }
  },
  {
    path: '/envelope/points',
    exact: true,
    name: 'Point besked',
    component: PointsEnvelope,
    requirements: {
      userRole: 'CLIENT',
      userPrivilege: 'MANAGE'
    }
  },
  {
    path: '/envelope/:EnvelopeID/elements',
    exact: true,
    name: 'detaljer',
    component: Envelope,
    props: { elements: true },
    requirements: {
      userRole: 'BRAND',
      userPrivilege: 'MANAGE'
    }
  },
  /* company wallet */
  {
    path: '/companywallet',
    exact: true,
    name: 'Company Wallet',
    component: CompanyWallet,
    hasMenu: true,
    icon: <FontAwesomeIcon icon='coins' className='nav-icon' />,
    requirements: {
      BrandID: 11,
      userPrivilege: 'MANAGE'
    }
  },
  /* christmas (not active) */
  {
    path: '/christmas',
    exact: true,
    name: 'christmas',
    component: Christmas,
    hasMenu: true,
    icon: <FontAwesomeIcon icon='snowflake' className='nav-icon' />,
    requirements: {
      christmasShop: true
    }
  },
  {
    path: '/communication',
    exact: true,
    name: 'Kommunikation',
    component: CommunicationList,
    icon: <FontAwesomeIcon icon='envelope-open-text' className='nav-icon' />,
    requirements: {
      BrandID: 9,
      userRole: 'SYSTEM',
      userPrivilege: 'MANAGE'
    }
  },
  {
    path: '/communication/:CommunicationID/elements',
    exact: true,
    name: 'Detaljer',
    component: CommunicationDetails,
    requirements: {
      BrandID: 9,
      userRole: 'SYSTEM',
      userPrivilege: 'MANAGE'
    }
  },
  {
    path: '/communication/points',
    exact: true,
    props: { type: 'point' },
    name: 'Point Messages',
    component: CommunicationList,
    icon: <FontAwesomeIcon icon='envelope-open-text' className='nav-icon' />,
    requirements: {
      userRole: 'BRAND',
      userPrivilege: 'MANAGE'
    }
  },
  {
    path: '/communication/points/:CommunicationID/message',
    exact: true,
    name: 'Detaljer',
    props: { type: 'point' },
    component: CommunicationDetails,
    requirements: {
      userRole: 'BRAND',
      userPrivilege: 'MANAGE'
    }
  },
  /* AdminInvoice */
  {
    path: '/admininvoice',
    exact: true,
    name: 'Fakturering',
    component: AdminInvoice,
    hasMenu: true,
    icon: <FontAwesomeIcon icon='file-invoice' className='nav-icon' />,
    requirements: {
      BrandID: 9,
      userRole: 'BRAND',
      userPrivilege: 'MANAGE'
    },
    children: [
      {
        name: 'Oversigt',
        url: '/admininvoice',
        exact: true,
        icon: <FontAwesomeIcon icon='file-invoice' className='nav-icon' />
      },
      {
        name: 'To Be Invoiced',
        url: '/admininvoice/tobeinvoiced',
        exact: true,
        icon: <FontAwesomeIcon icon='file-invoice' className='nav-icon' />
      },
      {
        name: 'Merch Oversigt',
        url: '/merchandise/admininvoice',
        exact: true,
        icon: <FontAwesomeIcon icon='file-invoice' className='nav-icon' />
      }
    ]
  },
  {
    path: '/admininvoice/tobeinvoiced',
    exact: true,
    name: 'To Be Invoiced',
    component: AdminToBeInvoiced,
    requirements: {
      BrandID: 9,
      userRole: 'BRAND',
      userPrivilege: 'MANAGE'
    }
  },
  {
    path: '/admininvoice/:BrandID',
    exact: true,
    name: 'Detaljer',
    component: AdminInvoice,
    requirements: {
      BrandID: 9,
      userRole: 'BRAND',
      userPrivilege: 'MANAGE'
    }
  },
  {
    path: '/admininvoice/:BrandID/new',
    name: 'Opret',
    exact: true,
    component: AdminInvoice,
    props: { create: true },
    requirements: {
      BrandID: 9,
      userRole: 'BRAND',
      userPrivilege: 'MANAGE'
    }
  },
  {
    path: '/admininvoice/:BrandID/:InvoiceID',
    exact: true,
    name: 'Faktura',
    component: AdminInvoice,
    requirements: {
      BrandID: 9,
      userRole: 'BRAND',
      userPrivilege: 'MANAGE'
    }
  },
  {
    path: '/merchandise/admininvoice',
    exact: true,
    name: 'Merchandise Invoices',
    component: MerchInvoice,
    requirements: {
      BrandID: 9,
      userRole: 'BRAND',
      userPrivilege: 'MANAGE'
    }
  },
  {
    path: '/merchandise/admininvoice/:BrandID',
    exact: true,
    name: 'Detaljer',
    component: AdminInvoice,
    requirements: {
      BrandID: 9,
      userRole: 'BRAND',
      userPrivilege: 'MANAGE'
    }
  },
  {
    path: '/merchandise/admininvoice/:BrandID/new',
    name: 'Opret',
    exact: true,
    component: AdminInvoice,
    props: { create: true },
    requirements: {
      BrandID: 9,
      userRole: 'BRAND',
      userPrivilege: 'MANAGE'
    }
  },
  {
    path: '/merchandise/admininvoice/:BrandID/:InvoiceID',
    exact: true,
    name: 'Faktura',
    component: MerchInvoiceDetails,
    props: { isAdmin: true },
    requirements: {
      BrandID: 9,
      userRole: 'BRAND',
      userPrivilege: 'MANAGE'
    }
  },
  /* Invoice */
  {
    path: '/invoice',
    exact: true,
    name: 'Faktura',
    component: Invoice,
    hasMenu: true,
    icon: <FontAwesomeIcon icon='receipt' className='nav-icon' />,
    requirements: {
      BrandID: { exclude: 9 },
      userRole: 'BRAND',
      userPrivilege: 'MANAGE'
    }
  },
  {
    path: '/invoice/:InvoiceID',
    name: 'Detaljer',
    component: Invoice
  },
  {
    path: '/fragt',
    exact: true,
    name: 'Fragt',
    component: FreightBrandOverview,
    hasMenu: true,
    icon: <FontAwesomeIcon icon='file-invoice' className='nav-icon' />,
    requirements: {
      userRole: 'CLIENT',
      userPrivilege: 'MANAGE'
    }
  },
  {
    path: '/fragt/:BrandID',
    exact: true,
    name: 'Detaljer',
    component: FreightOverview,
    requirements: {
      userRole: 'CLIENT',
      userPrivilege: 'MANAGE'
    }
  },
  {
    path: '/fragt/:BrandID/new',
    name: 'Opret',
    exact: true,
    component: AdminInvoice,
    props: { create: true, freight: true },
    requirements: {
      userRole: 'BRAND',
      userPrivilege: 'MANAGE'
    }
  },
  {
    path: '/fragt/:BrandID/:InvoiceID',
    exact: true,
    name: 'Linier',
    component: FreightElements,
    requirements: {
      userRole: 'CLIENT',
      userPrivilege: 'MANAGE'
    }
  },
  {
    path: '/parcels',
    exact: true,
    name: 'Parcel',
    component: ParcelBrandOverview,
    hasMenu: true,
    icon: <FontAwesomeIcon icon='file-invoice' className='nav-icon' />,
    requirements: {
      userRole: 'CLIENT',
      userPrivilege: 'MANAGE'
    }
  },
  {
    path: '/parcels/:BrandID',
    exact: true,
    name: 'Detaljer',
    component: FreightOverview,
    props: { isParcels: true },
    requirements: {
      userRole: 'CLIENT',
      userPrivilege: 'MANAGE'
    }
  },
  /* Smart Groups */
  {
    path: '/group',
    exact: true,
    name: 'Grupper',
    component: Group,
    hasMenu: true,
    icon: <FontAwesomeIcon icon='users' className='nav-icon' />,
    requirements: [
      {
        userRole: 'BRAND',
        userPrivilege: 'MANAGE'
      },
      {
        features: { allowUserAdmin: true }
      }
    ],
    children: [
      {
        name: 'Oversigt',
        url: '/group',
        exact: true,
        icon: <FontAwesomeIcon icon='table' className='nav-icon' />
      },
      {
        name: 'Opret gruppe',
        url: '/group/new',
        icon: <FontAwesomeIcon icon='users-cog' className='nav-icon' />
      }
    ]
  },
  {
    path: '/group/new',
    exact: true,
    name: 'Opret gruppe',
    component: Group,
    props: { create: true },
    requirements: [
      {
        userRole: 'BRAND',
        userPrivilege: 'MANAGE'
      },
      {
        features: { allowUserAdmin: true }
      }
    ]
  },
  {
    path: '/group/:GroupID',
    name: 'Detaljer',
    exact: true,
    component: Group,
    requirements: [
      {
        userRole: 'BRAND',
        userPrivilege: 'MANAGE'
      },
      {
        features: { allowUserAdmin: true }
      }
    ]
  },
  {
    path: '/group/:GroupID/elements',
    exact: true,
    name: 'detaljer',
    component: Group,
    props: { elements: true },
    requirements: [
      {
        userRole: 'BRAND',
        userPrivilege: 'MANAGE'
      },
      {
        features: { allowUserAdmin: true }
      }
    ]
  },
  {
    path: '/customerproducts',
    exact: true,
    name: 'Klient Produkter',
    component: CustomerProductOverview,
    hasMenu: true,
    icon: <FontAwesomeIcon icon='shopping-bag' className='nav-icon' />,
    requirements: {
      BrandID: [11, 47, 127],
      userRole: 'BRAND',
      userPrivilege: 'MANAGE'
    },
    children: [
      {
        name: 'Oversigt',
        url: '/customerproducts',
        exact: true,
        icon: <FontAwesomeIcon icon='table' className='nav-icon' />
      },
      {
        name: 'Opret Klient Produkter',
        url: '/customerproducts/new',
        icon: <FontAwesomeIcon icon='plus-square' className='nav-icon' />
      }
    ]
  },
  {
    path: '/customerproducts/new',
    exact: true,
    name: 'Opret',
    component: CustomerProductCreate,
    props: { create: true },
    requirements: {
      BrandID: [11, 47, 127],
      userRole: 'BRAND',
      userPrivilege: 'MANAGE'
    }
  },
  {
    path: '/customerproducts/:CustomerProductID',
    exact: true,
    name: 'Detaljer',
    component: CustomerProductEdit,
    requirements: {
      BrandID: [11, 47, 127],
      userRole: 'BRAND',
      userPrivilege: 'MANAGE'
    }
  },

  {
    path: '/customersalesrewards',
    exact: true,
    name: 'Produkter Belønning',
    component: CustomerSalesRewardOverview,
    hasMenu: true,
    icon: <FontAwesomeIcon icon='shopping-bag' className='nav-icon' />,
    requirements: {
      BrandID: [47, 127],
      userRole: 'BRAND',
      userPrivilege: 'MANAGE'
    },
    children: [
      {
        name: 'Oversigt',
        url: '/customersalesrewards',
        exact: true,
        icon: <FontAwesomeIcon icon='table' className='nav-icon' />
      },
      {
        name: 'Opret Produkter Belønning',
        url: '/customersalesrewards/new',
        icon: <FontAwesomeIcon icon='plus-square' className='nav-icon' />
      }
    ]
  },
  {
    path: '/customersalesrewards/new',
    exact: true,
    name: 'Opret',
    component: CustomerSalesRewardCreate,
    props: { create: true },
    requirements: {
      BrandID: [47, 127],
      userRole: 'BRAND',
      userPrivilege: 'MANAGE'
    }
  },
  {
    path: '/customersalesrewards/:CustomerSalesRewardID',
    exact: true,
    name: 'Detaljer',
    component: CustomerSalesRewardEdit,
    requirements: {
      BrandID: [47, 127],
      userRole: 'BRAND',
      userPrivilege: 'MANAGE'
    }
  },

  {
    path: '/customersalesclaims',
    exact: true,
    name: 'Indberetning',
    component: CustomerSalesClaimOverview,
    hasMenu: true,
    icon: <FontAwesomeIcon icon='shopping-bag' className='nav-icon' />,
    requirements: {
      BrandID: [47, 127],
      userRole: 'BRAND',
      userPrivilege: 'MANAGE'
    },
    children: [
      {
        name: 'Oversigt',
        url: '/customersalesclaims',
        exact: true,
        icon: <FontAwesomeIcon icon='table' className='nav-icon' />
      },
      {
        name: 'Ny',
        exact: true,
        url: '/customersalesclaims?status=new',
        icon: <FontAwesomeIcon icon='plus-square' className='nav-icon' />
      },
      {
        name: 'Godkendt',
        exact: true,
        url: '/customersalesclaims?status=approved',
        icon: <FontAwesomeIcon icon='check-square' className='nav-icon' />
      },
      {
        name: 'Afvist',
        exact: true,
        url: '/customersalesclaims?status=rejected',
        icon: <FontAwesomeIcon icon='window-close' className='nav-icon' />
      }
    ]
  },

  /* SANTANDER */

  /* KAM Overview */
  {
    path: '/kams',
    exact: true,
    name: 'KAM Oversigt',
    component: KAMOverview,
    hasMenu: true,
    icon: <FontAwesomeIcon icon='user-tie' className='nav-icon' />,
    requirements: {
      BrandID: [11, 1, 2, 3, 4, 5, 50],
      userRole: 'SYSTEM',
      userPrivilege: 'MANAGE'
    }
  },
  {
    path: '/kams/:supervisorUID?/details',
    exact: true,
    name: 'Oversigt',
    component: KamCompanyDashboard,
    requirements: {
      BrandID: [1, 2, 3, 4, 5, 50],
      userRole: 'SYSTEM',
      userPrivilege: 'MANAGE'
    }
  },
  {
    path: '/kams/:kamUID/cvr',
    exact: true,
    name: 'Company Oversigt',
    component: KAMCompanyOverview,
    icon: <FontAwesomeIcon icon='table' className='nav-icon' />,
    requirements: {
      BrandID: [11],
      userRole: 'SYSTEM',
      userPrivilege: 'MANAGE'
    }
  },
  {
    path: '/kams/:kamUID/cvr/:CVR/:DealerID',
    exact: true,
    name: 'Detaljer',
    component: KAMCompanyDetails,
    requirements: {
      BrandID: [11],
      userRole: 'SYSTEM',
      userPrivilege: 'MANAGE'
    }
  },
  {
    path: '/kam',
    exact: true,
    name: 'KAM',
    component: SantanderKamOverview,
    hasMenu: true,
    icon: <FontAwesomeIcon icon='user-circle' className='nav-icon' />,
    requirements: {
      BrandID: [11]
    },
    children: [
      {
        name: 'Oversigt',
        url: '/kam',
        exact: true,
        icon: <FontAwesomeIcon icon='table' className='nav-icon' />
      },
      {
        name: 'Wallet Oversigt',
        url: '/kam/campaigns-overview',
        exact: true,
        icon: <FontAwesomeIcon icon='table' className='nav-icon' />
      },
      {
        name: 'Kampagner',
        url: '/kam/campaigns',
        exact: true,
        icon: <FontAwesomeIcon icon='users-cog' className='nav-icon' />
      },
      {
        name: 'Opret Kampagne',
        url: '/kam/campaigns/new',
        exact: true,
        icon: <FontAwesomeIcon icon='users-cog' className='nav-icon' />
      }
    ]
  },
  {
    path: '/kam/cvr/:CVR/:DealerID',
    exact: true,
    name: 'Detaljer',
    component: SantanderKamCvrDetails,
    requirements: {
      BrandID: [11]
    }
  },
  {
    path: '/kam/campaigns-overview',
    exact: true,
    name: 'Wallet Oversigt',
    component: MacroList,
    requirements: {
      BrandID: [11]
    }
  },
  {
    path: '/kam/campaigns',
    exact: true,
    name: 'Kampagner',
    component: SantanderKamCampaignView,
    requirements: {
      BrandID: [11]
    }
  },
  {
    path: '/kam/campaigns/new',
    exact: true,
    name: 'Opret Kampagne',
    component: SantanderCreateKamCampaign,
    requirements: {
      BrandID: [11]
    }
  },
  {
    path: '/kam/campaigns/:CampaignID/preview',
    name: 'Preview',
    exact: true,
    component: CampaignPreview,
    requirements: {
      BrandID: [11]
    }
  },
  {
    path: '/kam/campaigns/:CampaignID',
    name: 'Detaljer',
    exact: true,
    component: SantanderKamEditCampaign,
    requirements: {
      BrandID: [11]
    }
  },
  {
    path: '/import',
    exact: true,
    name: 'Import',
    hasMenu: true,
    component: Imports,
    icon: <FontAwesomeIcon icon='file-import' className='nav-icon' />,
    requirements: {
      userRole: 'CLIENT',
      userPrivilege: 'MANAGE',
      features: { enableClientImport: true }
    },
    children: [
      {
        name: 'Brugere',
        url: '/import/users',
        icon: <FontAwesomeIcon icon='plus-square' className='nav-icon' />
      },
      {
        name: 'Point',
        url: '/import/points',
        icon: <FontAwesomeIcon icon='plus-square' className='nav-icon' />
      },
      {
        name: 'Ordre',
        url: '/import/orders',
        icon: <FontAwesomeIcon icon='plus-square' className='nav-icon' />,
        requirements: {
          BrandID: [121, 148, 159, 164]
        }
      }
    ]
  },
  {
    path: '/import/users',
    exact: true,
    name: 'Brugere',
    props: { DatasetID: 1 },
    component: ImportsOverview,
    requirements: {
      userRole: 'CLIENT',
      userPrivilege: 'MANAGE'
    }
  },
  {
    path: '/import/users/new',
    exact: true,
    name: 'New',
    component: ImportUsers,
    requirements: {
      userRole: 'CLIENT',
      userPrivilege: 'MANAGE'
    }
  },
  {
    path: '/import/users/:BatchID/elements',
    exact: true,
    name: 'Detaljer',
    props: { DatasetID: 1 },
    component: ImportsBatchPreview,
    requirements: {
      userRole: 'CLIENT',
      userPrivilege: 'MANAGE'
    }
  },
  {
    path: '/import/points',
    exact: true,
    name: 'Points',
    props: { DatasetID: 2 },
    component: ImportsOverview,
    requirements: {
      userRole: 'CLIENT',
      userPrivilege: 'MANAGE'
    }
  },
  {
    path: '/import/points/new',
    exact: true,
    name: 'New',
    component: ImportPoints,
    requirements: {
      userRole: 'CLIENT',
      userPrivilege: 'MANAGE'
    }
  },
  {
    path: '/import/points/:BatchID/elements',
    exact: true,
    name: 'Detaljer',
    props: { DatasetID: 2 },
    component: ImportsBatchPreview,
    requirements: {
      userRole: 'CLIENT',
      userPrivilege: 'MANAGE'
    }
  },
  {
    path: '/import/orders/new',
    exact: true,
    name: 'New',
    component: ImportOrders,
    requirements: {
      userRole: 'CLIENT',
      userPrivilege: 'MANAGE'
    }
  },
  {
    path: '/import/orders',
    exact: true,
    name: 'Ordre',
    props: { DatasetID: 91 },
    component: ImportsOverview,
    requirements: {
      userRole: 'CLIENT',
      userPrivilege: 'MANAGE'
    }
  },
  {
    path: '/import/orders/:BatchID/elements',
    exact: true,
    name: 'Detaljer',
    props: { DatasetID: 91 },
    component: ImportsBatchPreview,
    requirements: {
      userRole: 'CLIENT',
      userPrivilege: 'MANAGE'
    }
  },

  /*Campaign Notification*/

  {
    path: '/campaign',
    exact: true,
    name: 'Kampagne beskeder',
    component: CampaignOverview,
    hasMenu: true,

    icon: <FontAwesomeIcon icon='bell' className='nav-icon' />,
    requirements: {
      userRole: 'BRAND',
      userPrivilege: 'MANAGE',
      features: { activateCampaignMessages: true }
    },
    children: [
      {
        name: 'Overblik',
        url: '/campaign',
        exact: true,
        icon: <FontAwesomeIcon icon='table' className='nav-icon' />
      },
      {
        name: 'Opret Kampagne',
        url: '/campaign/new',
        icon: <FontAwesomeIcon icon='plus-square' className='nav-icon' />
      }
    ]
  },
  {
    path: '/campaign/new',
    name: 'New',
    exact: true,
    component: CampaignForm,
    requirements: {
      userRole: 'BRAND',
      userPrivilege: 'MANAGE',
      features: { activateCampaignMessages: true }
    }
  },
  {
    path: '/campaign/template',
    name: 'Template',
    exact: true,
    component: CampaignOverview,
    requirements: {
      userRole: 'CLIENT',
      userPrivilege: 'MANAGE'
    }
  },
  {
    path: '/campaign/template/new',
    name: 'New',
    exact: true,
    component: TemplateForm,
    requirements: {
      userRole: 'CLIENT',
      userPrivilege: 'MANAGE'
    }
  },
  {
    path: '/campaign/template/:NotificationCampaignTemplateID',
    name: 'Details',
    component: TemplateForm,
    requirements: {
      userRole: 'CLIENT',
      userPrivilege: 'MANAGE'
    }
  },
  {
    path: '/campaign/:NotificationCampaignID',
    name: 'Details',
    exact: true,
    component: CampaignForm,
    requirements: {
      userRole: 'BRAND',
      userPrivilege: 'MANAGE',
      features: { activateCampaignMessages: true }
    }
  },
  {
    path: '/campaign/:NotificationCampaignID/report',
    exact: true,
    name: 'Report',
    component: CampaignReport,
    requirements: {
      userRole: 'CLIENT',
      userPrivilege: 'MANAGE'
    }
  },
  {
    path: '/campaign/:NotificationCampaignID/users',
    exact: true,
    name: 'Brugere',
    component: CampaignUsers,
    requirements: {
      userRole: 'BRAND',
      userPrivilege: 'MANAGE'
    }
  },

  /*Konkurrencer*/

  {
    path: '/cvr2',
    exact: true,
    name: 'Konkurrencer',
    component: CVR2,
    hasMenu: true,
    icon: <FontAwesomeIcon icon='trophy' className='nav-icon' />,
    requirements: {
      userRole: 'AGENT',
      userPrivilege: 'MANAGE',
      features: { halloffame: ['cvr'] }
    },
    children: [
      {
        name: 'Oversigt',
        url: '/cvr2',
        icon: <FontAwesomeIcon icon='table' className='nav-icon' />
      },
      {
        name: 'Opret konkurrence',
        url: '/cvr2/create',
        icon: <FontAwesomeIcon icon='plus-square' className='nav-icon' />
      }
    ]
  },
  {
    path: '/cvr2/create',
    name: 'Opret konkurrence',
    component: CVR2,
    props: { create: true },
    requirements: {
      userPrivilege: 'MANAGE',
      features: { halloffame: ['cvr'] }
    }
  },
  {
    path: '/cvr2/:CompetitionID',
    name: 'Detaljer',
    component: CVR2,
    requirements: {
      userPrivilege: 'MANAGE',
      features: { halloffame: ['cvr'] }
    }
  },
  {
    path: '/stock',
    exact: true,
    name: 'Lager',
    hasMenu: true,
    icon: <FontAwesomeIcon icon='boxes' className='nav-icon' />,
    component: ProductsOverview,
    requirements: {
      BrandID: { exclude: 121 },
      userRole: 'CLIENT',
      userPrivilege: 'MANAGE',
      features: { variationStockOverview: true }
    }
  },
  {
    path: '/stock/:ProductID',
    exact: true,
    name: 'Details',
    component: ProductHistoryOverview,
    requirements: {
      BrandID: { exclude: 121 },
      userRole: 'CLIENT',
      userPrivilege: 'MANAGE',
      features: { variationStockOverview: true }
    }
  },
  {
    path: '/stock',
    exact: true,
    name: 'Lager',
    hasMenu: true,
    icon: <FontAwesomeIcon icon='boxes' className='nav-icon' />,
    component: ProductsOverview,
    requirements: {
      BrandID: [121],
      userRole: 'BRAND',
      userPrivilege: 'VIEW'
    }
  },
  {
    path: '/stock/:ProductID',
    exact: true,
    name: 'Details',
    component: ProductHistoryOverview,
    requirements: {
      BrandID: [121],
      userRole: 'BRAND',
      userPrivilege: 'VIEW'
    }
  },
  /*Macros*/
  {
    path: '/macros',
    exact: true,
    name: 'Makro',
    component: MacroList,
    hasMenu: true,
    icon: <FontAwesomeIcon icon='wrench' className='nav-icon' />,
    requirements: {
      BrandID: [11, 167],
      userRole: 'BRAND',
      userPrivilege: 'MANAGE'
    },
    children: [
      {
        name: 'Oversigt',
        url: '/macros',
        exact: true,
        icon: <FontAwesomeIcon icon='table' className='nav-icon' />
      },
      {
        name: 'Kampagner',
        url: '/macros/campaigns',
        exact: true,
        icon: <FontAwesomeIcon icon='table' className='nav-icon' />
      },
      {
        name: 'Opret Makro',
        url: '/macros/new',
        exact: true,
        icon: <FontAwesomeIcon icon='users-cog' className='nav-icon' />
      },
      {
        name: 'Opret Kampagne ',
        url: '/macros/campaigns/new',
        exact: true,
        icon: <FontAwesomeIcon icon='users-cog' className='nav-icon' />
      }
    ]
  },
  {
    path: '/macros/new',
    exact: true,
    name: 'Opret',
    component: MacroDetails,
    requirements: {
      BrandID: [11, 167],
      userRole: 'BRAND',
      userPrivilege: 'MANAGE'
    }
  },
  {
    path: '/macros/campaigns',
    exact: true,
    name: 'kampagner',
    component: CampaignList,
    requirements: {
      BrandID: [11, 167],
      userRole: 'BRAND',
      userPrivilege: 'MANAGE'
    }
  },
  {
    path: '/macros/campaigns/new',
    exact: true,
    name: 'Opret',
    component: CampaignCreate,
    requirements: {
      BrandID: [11, 167],
      userRole: 'BRAND',
      userPrivilege: 'MANAGE'
    }
  },
  {
    path: '/macros/campaigns/:CampaignID',
    name: 'Detaljer',
    exact: true,
    component: CampaignEdit,
    requirements: {
      BrandID: [11, 167],
      userRole: 'BRAND',
      userPrivilege: 'MANAGE'
    }
  },
  {
    path: '/macros/campaigns/:CampaignID/preview',
    name: 'Preview',
    exact: true,
    component: CampaignPreview,
    requirements: {
      BrandID: [11, 167],
      userRole: 'BRAND',
      userPrivilege: 'MANAGE'
    }
  },
  {
    path: '/msb',
    exact: true,
    name: 'MSB',
    component: Order,
    hasMenu: true,
    hasSubMenu: true,
    icon: <FontAwesomeIcon icon='user-circle' className='nav-icon' />,
    requirements: {
      BrandID: [9]
    },
    children: [
      {
        url: '/catalogs',
        exact: true,
        name: 'Catalogs',
        component: CatalogList,
        hasMenu: true,
        icon: <FontAwesomeIcon icon='shopping-basket' className='nav-icon' />,
        requirements: {
          BrandID: [9],
          userRole: 'AGENT'
        },
        children: [
          {
            name: 'Oversigt',
            url: '/catalogs',
            exact: true,
            icon: <FontAwesomeIcon icon='table' className='nav-icon' />
          },
          {
            name: 'Opret katalog',
            url: '/catalogs/new',
            icon: <FontAwesomeIcon icon='plus-square' className='nav-icon' />
          }
        ]
      },
      {
        name: 'Category',
        exact: true,
        url: '/category',
        component: CategoryList,
        hasMenu: true,
        icon: <FontAwesomeIcon icon='list' className='nav-icon' />,
        requirements: {
          BrandID: [9],
          userRole: 'SYSTEM',
          userPrivilege: 'MANAGE'
        },
        children: [
          {
            name: 'Oversigt',
            url: '/category',
            exact: true,
            icon: <FontAwesomeIcon icon='table' className='nav-icon' />
          },
          {
            name: 'Opret Category',
            url: '/category/new',
            icon: <FontAwesomeIcon icon='plus' className='nav-icon' />
          },
          {
            name: 'Sub-categories',
            url: '/category/subcategory',
            icon: <FontAwesomeIcon icon='table' className='nav-icon' />
          }
        ]
      },
      {
        url: '/suppliers',
        exact: true,
        name: 'Leverandører',
        component: SupplierOverview,
        hasMenu: true,
        icon: <FontAwesomeIcon icon='users' className='nav-icon' />,
        requirements: {
          BrandID: [9],
          userRole: 'SYSTEM',
          userPrivilege: 'MANAGE'
        },
        children: [
          {
            name: 'Oversigt',
            url: '/suppliers',
            exact: true,
            icon: <FontAwesomeIcon icon='table' className='nav-icon' />
          },
          {
            name: 'Opret Leverandører',
            url: '/suppliers/new',
            icon: <FontAwesomeIcon icon='plus-square' className='nav-icon' />
          }
        ]
      },
      {
        url: '/orders',
        exact: true,
        name: 'Ordrer',
        component: Order,
        hasMenu: true,
        icon: <FontAwesomeIcon icon='truck' className='nav-icon' />,
        requirements: {
          BrandID: [9],
          userRole: 'AGENT'
        },
        children: [
          {
            name: 'Oversigt',
            url: '/orders',
            exact: true,
            icon: <FontAwesomeIcon icon='truck' className='nav-icon' />
          },
          {
            name: 'Custom Order',
            exact: true,
            url: '/orders/custom-order-form',
            icon: <FontAwesomeIcon icon='plus' className='nav-icon' />
          },
          {
            name: 'Orders to be obfuscated',
            exact: true,
            url: '/orders/obfuscationSchedule',
            icon: <FontAwesomeIcon icon='trash' className='nav-icon' />
          }
        ]
      },
      {
        url: '/season-orders',
        exact: true,
        name: 'Season Order',
        component: Order,
        hasMenu: true,
        icon: <FontAwesomeIcon icon='truck' className='nav-icon' />,
        requirements: {
          BrandID: [9],
          userRole: 'SYSTEM',
          userPrivilege: 'MANAGE'
        }
      },
      {
        url: '/products',
        exact: true,
        name: 'Products',
        component: ProductList,
        hasMenu: true,
        icon: <FontAwesomeIcon icon='shopping-bag' className='nav-icon' />,
        requirements: {
          BrandID: [8, 9],
          userRole: 'AGENT'
        },
        children: [
          {
            name: 'Oversigt',
            url: '/products',
            exact: true,
            icon: <FontAwesomeIcon icon='table' className='nav-icon' />
          },
          {
            name: 'Opret produkt',
            url: '/products/new',
            icon: <FontAwesomeIcon icon='users-cog' className='nav-icon' />
          },
          {
            name: 'Sampakning',
            url: '/packages',
            exact: true,
            icon: <FontAwesomeIcon icon='archive' className='nav-icon' />,
            children: [
              {
                name: 'Oversigt',
                url: '/packages',
                exact: true,
                icon: <FontAwesomeIcon icon='table' className='nav-icon' />
              },
              {
                name: 'Opret ny',
                url: '/packages/new',
                icon: <FontAwesomeIcon icon='users-cog' className='nav-icon' />
              }
            ]
          }
        ]
      },
      {
        url: '/colors',
        exact: true,
        name: 'Colors',
        component: ColorsOverview,
        hasMenu: true,
        icon: <FontAwesomeIcon icon='palette' className='nav-icon' />,
        requirements: {
          BrandID: [9],
          userRole: 'SYSTEM',
          userPrivilege: 'MANAGE'
        },
        children: [
          {
            name: 'Oversigt',
            url: '/colors',
            exact: true,
            icon: <FontAwesomeIcon icon='table' className='nav-icon' />
          },
          {
            name: 'Opret Color',
            url: '/colors/new',
            icon: <FontAwesomeIcon icon='plus-square' className='nav-icon' />
          }
        ]
      },
      {
        url: '/tax-report',
        exact: true,
        name: 'Skatteopgørelse',
        component: Order,
        hasMenu: true,
        icon: <FontAwesomeIcon icon='file-invoice' className='nav-icon' />,
        requirements: {
          BrandID: [9],
          userRole: 'SYSTEM',
          userPrivilege: 'MANAGE'
        }
      },
      {
        url: '/tax-overview',
        exact: true,
        name: 'Tax Overview',
        component: Order,
        hasMenu: true,
        icon: <FontAwesomeIcon icon='file-invoice' className='nav-icon' />,
        requirements: {
          BrandID: [9],
          userRole: 'SYSTEM',
          userPrivilege: 'MANAGE'
        }
      },
      {
        url: '/tax-earnings',
        exact: true,
        name: 'Tax on Earnings',
        component: Order,
        hasMenu: true,
        icon: <FontAwesomeIcon icon='file-invoice' className='nav-icon' />,
        requirements: {
          BrandID: [9],
          userRole: 'SYSTEM',
          userPrivilege: 'MANAGE'
        }
      },
      {
        url: '/monthly-tax',
        exact: true,
        name: 'Monthly tax info',
        component: MonthlyTaxInfo,
        hasMenu: true,
        icon: <FontAwesomeIcon icon='file-invoice' className='nav-icon' />,
        requirements: {
          BrandID: [9],
          userRole: 'SYSTEM',
          userPrivilege: 'MANAGE'
        }
      }
    ]
  },
  /* Insights */
  {
    path: '/stat',
    exact: true,
    name: 'Statistik',
    component: StatList,
    hasMenu: true,
    icon: <FontAwesomeIcon icon='chart-bar' className='nav-icon' />,
    requirements: {
      BrandID: [9],
      userRole: 'SYSTEM',
      userPrivilege: 'MANAGE'
    },
    children: [
      {
        name: 'MSB Oversigt',
        url: '/stat',
        exact: true,
        icon: <FontAwesomeIcon icon='table' className='nav-icon' />
      },
      {
        name: 'Oversigt',
        url: '/stats',
        exact: true,
        icon: <FontAwesomeIcon icon='table' className='nav-icon' />
      },
      {
        name: 'Opret stat',
        url: '/stats/new',
        icon: <FontAwesomeIcon icon='plus-square' className='nav-icon' />
      }
    ]
  },
  {
    path: '/stat/:StatID/elements',
    name: 'Elements',
    component: StatDetails,
    requirements: [
      {
        userRole: 'CLIENT',
        userPrivilege: 'VIEW'
      },
      {
        features: { allowUserAdmin: true }
      }
    ]
  },
  {
    path: '/stat',
    exact: true,
    name: 'Statistik',
    component: StatList,
    hasMenu: true,
    icon: <FontAwesomeIcon icon='chart-bar' className='nav-icon' />,
    requirements: [
      {
        BrandID: { exclude: [9, 121] },
        userRole: 'CLIENT',
        userPrivilege: 'VIEW'
      },
      {
        features: { allowUserAdmin: true }
      }
    ]
  },
  {
    path: '/stat',
    exact: true,
    name: 'Statistik',
    component: StatList,
    hasMenu: true,
    icon: <FontAwesomeIcon icon='chart-bar' className='nav-icon' />,
    requirements: {
      BrandID: [121],
      userRole: 'BRAND',
      userPrivilege: 'VIEW'
    }
  },
  {
    path: '/stats',
    name: 'Statistik',
    component: StatList,
    exact: true,
    icon: <FontAwesomeIcon icon='chart-bar' className='nav-icon' />,
    props: { msb: true },
    requirements: {
      BrandID: [9],
      userRole: 'SYSTEM',
      userPrivilege: 'MANAGE'
    }
  },
  {
    path: '/stats/new',
    name: 'Opret',
    exact: true,
    component: StatCreate,
    props: { msb: true },
    requirements: {
      BrandID: [9],
      userRole: 'SYSTEM',
      userPrivilege: 'MANAGE'
    }
  },
  {
    path: '/stats/:StatID/elements',
    name: 'Elements',
    component: StatDetails,
    props: { msb: true },
    requirements: {
      BrandID: [9],
      userRole: 'SYSTEM',
      userPrivilege: 'MANAGE'
    }
  },
  {
    path: '/stats/:StatID',
    exact: true,
    name: 'Detaljer',
    component: StatEdit,
    props: { msb: true },
    requirements: {
      BrandID: [9],
      userRole: 'SYSTEM',
      userPrivilege: 'MANAGE'
    }
  },
  /* css */
  {
    path: '/customcss',
    exact: true,
    name: 'Custom CSS',
    component: CustomCss,
    icon: <FontAwesomeIcon icon={['fab', 'css3']} className='nav-icon' />,
    requirements: {
      userRole: 'SYSTEM'
    }
  },
  /* admin users */
  {
    path: '/admin-users',
    exact: true,
    name: 'Admin Brugere',
    component: AdminUsersList,
    requirements: {
      userRole: 'SYSTEM',
      userPrivilege: 'MANAGE'
    }
  },
  {
    path: '/admin-users/new',
    exact: true,
    name: 'Opret Bruger',
    props: { create: true, adminUser: true },
    component: User,
    requirements: {
      userRole: 'SYSTEM',
      userPrivilege: 'MANAGE'
    }
  },
  {
    path: '/admin-users/:UserID',
    exact: true,
    name: 'Detaljer',
    props: { adminUser: true },
    component: User,
    requirements: {
      userRole: 'SYSTEM',
      userPrivilege: 'MANAGE'
    }
  },
  {
    path: '/address',
    exact: true,
    name: 'Adresser',
    component: AddressesList,
    requirements: {
      userRole: 'SYSTEM',
      userPrivilege: 'MANAGE'
    }
  },
  {
    path: '/address/new',
    exact: true,
    name: 'Opret Adresser',
    component: AddressCreate,
    requirements: {
      userRole: 'SYSTEM',
      userPrivilege: 'MANAGE'
    }
  },
  {
    path: '/address/:AddressID',
    exact: true,
    name: 'Detaljer',
    component: AddressEdit,
    requirements: {
      userRole: 'SYSTEM',
      userPrivilege: 'MANAGE'
    }
  },
  /* Shops */
  {
    path: '/shop',
    exact: true,
    name: 'Butik',
    component: Shop,
    requirements: {
      userRole: 'AGENT',
      userPrivilege: 'MANAGE'
    }
  },
  {
    path: '/shop/new',
    exact: true,
    name: 'Opret butik',
    component: Shop,
    props: { create: true },
    requirements: {
      userRole: 'AGENT',
      userPrivilege: 'MANAGE'
    }
  },
  {
    path: '/shop/:ShopID',
    name: 'Detaljer',
    component: Shop,
    requirements: {
      userRole: 'AGENT',
      userPrivilege: 'MANAGE'
    }
  },
  {
    path: '/customization',
    exact: true,
    name: 'Customization',
    component: Customization,
    requirements: {
      userRole: 'SYSTEM',
      userPrivilege: 'MANAGE'
    }
  },
  {
    path: '/cpr',
    exact: true,
    name: 'CPR Overview',
    component: CPROverview
  },
  {
    path: '/pointExpire',
    exact: true,
    name: 'Point Expire Overview',
    component: PointExpireOverview
  },
  {
    path: '/pointExpire/:UserID/communication',
    exact: true,
    name: 'Emails',
    component: PointExpireCommunication
  },
  {
    path: '/pointExpire/communication',
    exact: true,
    name: 'Emails',
    component: PointExpireCommunication
  },
  /* vibee */
  {
    path: '/vibee',
    exact: true,
    name: 'vibee qr',
    component: VIB,
    requirements: {
      BrandID: 9,
      userRole: 'BRAND',
      userPrivilege: 'MANAGE'
    }
  },
  /* wallets */
  {
    path: '/wallets',
    exact: true,
    name: 'Wallets',
    component: WalletList,
    requirements: {
      BrandID: 9,
      userRole: 'SYSTEM',
      userPrivilege: 'MANAGE'
    }
  },
  {
    path: '/x-files',
    exact: true,
    name: 'X-Files',
    component: AdminDatasetList,
    requirements: {
      BrandID: 9,
      userRole: 'SYSTEM',
      userPrivilege: 'MANAGE'
    }
  },
  {
    path: '/x-files/:DatasetID/elements',
    exact: true,
    name: 'Elements',
    component: AdminDatasetElements,
    requirements: {
      BrandID: 9,
      userRole: 'SYSTEM',
      userPrivilege: 'MANAGE'
    }
  },
  /* batches */
  {
    path: '/batches',
    exact: true,
    name: 'Batches',
    component: AdminBatchList,
    requirements: {
      BrandID: 9,
      userRole: 'SYSTEM',
      userPrivilege: 'MANAGE'
    }
  },
  {
    path: '/access',
    exact: true,
    name: 'Access',
    component: ValidationCodeList,
    requirements: {
      BrandID: 9,
      userRole: 'SYSTEM',
      userPrivilege: 'MANAGE'
    }
  },
  /* datasets*/
  {
    path: '/dataset',
    exact: true,
    name: 'Dataset',
    component: DatasetList,
    requirements: {
      userRole: 'System',
      userPrivilege: 'MANAGE'
    }
  },
  {
    path: '/dataset/new',
    exact: true,
    name: 'Opret dataset',
    component: DatasetCreate,
    requirements: {
      userRole: 'System',
      userPrivilege: 'MANAGE'
    }
  },
  {
    path: '/dataset/:DatasetID',
    name: 'Details',
    exact: true,
    component: DatasetEdit,
    requirements: {
      userRole: 'System',
      userPrivilege: 'MANAGE'
    }
  },
  {
    path: '/dataset/:DatasetID/import',
    name: 'DataImport',
    exact: true,
    component: DataImport,
    requirements: {
      userRole: 'System',
      userPrivilege: 'MANAGE'
    }
  },
  {
    path: '/dataset/:DatasetID/copy/:BatchID',
    name: 'DataImport',
    exact: true,
    component: DataImport,
    requirements: {
      userRole: 'System',
      userPrivilege: 'MANAGE'
    }
  },
  {
    path: '/dataset/:DatasetID/batch/edit/:BatchID',
    name: 'Batch Edit',
    exact: true,
    component: DataImport,
    requirements: {
      userRole: 'System',
      userPrivilege: 'MANAGE'
    }
  },
  {
    path: '/dataset/:DatasetID/batch',
    name: 'Batches',
    exact: true,
    component: BatchList,
    requirements: {
      userRole: 'System',
      userPrivilege: 'MANAGE'
    }
  },
  {
    path: '/dataset/:DatasetID/batch/:BatchID/preview',
    name: 'Batch Preview',
    exact: true,
    component: BatchPreview,
    requirements: {
      userRole: 'System',
      userPrivilege: 'MANAGE'
    }
  },
  {
    path: '/department',
    exact: true,
    name: 'Department',
    component: DepartmentOverview,
    requirements: {
      userRole: 'System',
      userPrivilege: 'MANAGE'
    }
  },
  {
    path: '/department/new',
    exact: true,
    name: 'Opret department',
    component: DepartmentForm,
    requirements: {
      userRole: 'System',
      userPrivilege: 'MANAGE'
    }
  },
  {
    path: '/department/:DepartmentID',
    exact: true,
    name: 'Edit department',
    component: DepartmentForm,
    requirements: {
      userRole: 'System',
      userPrivilege: 'MANAGE'
    }
  },
  /* Brands*/
  {
    path: '/brand',
    exact: true,
    name: 'Brand',
    props: { overview: true },
    component: Brand,
    requirements: {
      BrandID: 9,
      userRole: 'SYSTEM',
      userPrivilege: 'MANAGE'
    }
  },
  {
    path: '/brand/new',
    exact: true,
    name: 'Opret brand',
    props: { create: true },
    component: Brand,
    requirements: {
      BrandID: 9,
      userRole: 'SYSTEM',
      userPrivilege: 'MANAGE'
    }
  },
  {
    path: '/brand/obfuscate',
    exact: true,
    name: 'obfuscate',
    component: ObfuscateBrand,
    requirements: {
      BrandID: 9,
      userRole: 'SYSTEM',
      userPrivilege: 'MANAGE'
    }
  },
  {
    path: '/brand/:BrandID',
    exact: true,
    name: 'redigere',
    component: Brand,
    props: { brandedit: true },
    requirements: {
      BrandID: 9,
      userRole: 'SYSTEM',
      userPrivilege: 'MANAGE'
    }
  },
  /* Company */
  {
    path: '/company',
    exact: true,
    name: 'Company',
    component: CompanyList,
    requirements: {
      BrandID: [9],
      userRole: 'SYSTEM',
      userPrivilege: 'MANAGE'
    }
  },
  {
    path: '/company/new',
    exact: true,
    name: 'Opret Company',
    component: CompanyCreate,
    requirements: {
      BrandID: [9],
      userRole: 'SYSTEM',
      userPrivilege: 'MANAGE'
    }
  },
  {
    path: '/company/:CompanyID',
    exact: true,
    name: 'Edit Company',
    component: CompanyEdit,
    requirements: {
      BrandID: [9],
      userRole: 'SYSTEM',
      userPrivilege: 'MANAGE'
    }
  },
  /* Client */
  {
    path: '/client',
    exact: true,
    name: 'Client',
    component: ClientList,
    requirements: {
      BrandID: [9],
      userRole: 'SYSTEM',
      userPrivilege: 'MANAGE'
    }
  },
  {
    path: '/client/new',
    exact: true,
    name: 'Opret Client',
    component: ClientCreate,
    requirements: {
      BrandID: [9],
      userRole: 'SYSTEM',
      userPrivilege: 'MANAGE'
    }
  },
  {
    path: '/client/:ClientID',
    exact: true,
    name: 'Edit Client',
    component: ClientEdit,
    requirements: {
      BrandID: [9],
      userRole: 'SYSTEM',
      userPrivilege: 'MANAGE'
    }
  },
  /* Menu */
  {
    path: '/menu',
    exact: true,
    name: 'Menu',
    component: MenuList,
    requirements: {
      BrandID: [9],
      userRole: 'SYSTEM',
      userPrivilege: 'MANAGE'
    }
  },
  {
    path: '/menu/new',
    exact: true,
    name: 'Opret Menu',
    component: MenuCreate,
    requirements: {
      BrandID: [9],
      userRole: 'SYSTEM',
      userPrivilege: 'MANAGE'
    }
  },
  {
    path: '/menu/:MenuID',
    exact: true,
    name: 'Edit Menu',
    component: MenuEdit,
    requirements: {
      BrandID: [9],
      userRole: 'SYSTEM',
      userPrivilege: 'MANAGE'
    }
  },
  /* HOF */
  {
    path: '/hof',
    exact: true,
    name: 'Hall Of Fame',
    component: HOFList,
    requirements: {
      userRole: 'SYSTEM',
      userPrivilege: 'MANAGE'
    }
  },
  {
    path: '/countdown',
    exact: true,
    name: 'Countdowns',
    component: CountDownList,
    requirements: {
      BrandID: [9],
      userRole: 'SYSTEM',
      userPrivilege: 'MANAGE'
    }
  },
  {
    path: '/countdown/new',
    exact: true,
    name: 'Countdown',
    component: CountdownForm,
    requirements: {
      BrandID: [9],
      userRole: 'SYSTEM',
      userPrivilege: 'MANAGE'
    }
  },
  {
    path: '/countdown/:CountdownID',
    exact: true,
    name: 'Countdown',
    component: CountdownForm,
    requirements: {
      BrandID: [9],
      userRole: 'SYSTEM',
      userPrivilege: 'MANAGE'
    }
  },
  {
    path: '/tax-campaign-highlight',
    exact: true,
    name: 'TaxCampaignHighlights',
    component: TaxCampaignHighlightList,
    requirements: {
      BrandID: [9],
      userRole: 'SYSTEM',
      userPrivilege: 'MANAGE'
    }
  },
  {
    path: '/tax-campaign-highlight/new',
    exact: true,
    name: 'TaxCampaignHighlight',
    component: TaxCampaignHighlightForm,
    requirements: {
      BrandID: [9],
      userRole: 'SYSTEM',
      userPrivilege: 'MANAGE'
    }
  },
  {
    path: '/tax-campaign-highlight/:TaxCampaignHighlightID',
    exact: true,
    name: 'TaxCampaignHighlight',
    component: TaxCampaignHighlightForm,
    requirements: {
      BrandID: [9],
      userRole: 'SYSTEM',
      userPrivilege: 'MANAGE'
    }
  },
  {
    path: '/top8',
    exact: true,
    name: 'Top8s',
    component: Top8List,
    requirements: {
      BrandID: [9],
      userRole: 'SYSTEM',
      userPrivilege: 'MANAGE'
    }
  },
  {
    path: '/top8/new',
    exact: true,
    name: 'Top8',
    component: Top8Form,
    requirements: {
      BrandID: [9],
      userRole: 'SYSTEM',
      userPrivilege: 'MANAGE'
    }
  },
  {
    path: '/top8/:Top8ID',
    exact: true,
    name: 'Top 8',
    component: Top8Form,
    requirements: {
      BrandID: [9],
      userRole: 'SYSTEM',
      userPrivilege: 'MANAGE'
    }
  },
  {
    path: '/font',
    exact: true,
    name: 'Fonts',
    component: FontList
  },
  {
    path: '/font/new',
    exact: true,
    name: 'Font',
    component: FontForm
  },
  {
    path: '/font/:FontID',
    exact: true,
    name: 'Font',
    component: FontForm
  },
  {
    path: '/hof/new',
    exact: true,
    name: 'Opret HOF',
    component: HOFCreate,
    requirements: {
      userRole: 'SYSTEM',
      userPrivilege: 'MANAGE'
    }
  },
  {
    path: '/hof/:HOFID',
    exact: true,
    name: 'Edit HOF',
    component: HOFEdit,
    requirements: {
      userRole: 'SYSTEM',
      userPrivilege: 'MANAGE'
    }
  },
  {
    path: '/hof/:HOFID/elements',
    exact: true,
    name: 'detaljer',
    component: HOFElements,
    requirements: {
      userRole: 'SYSTEM',
      userPrivilege: 'MANAGE'
    }
  },
  // Email Configurations
  {
    path: '/upgraderclub/email-configuration',
    exact: true,
    name: 'Email Configuration',
    component: EmailConfiguration,
    requirements: {
      userRole: 'BRAND',
      userPrivilege: 'MANAGE'
    }
  },
  /*Translations*/
  {
    path: '/translations',
    exact: true,
    name: 'Translations',
    component: TranslationList,
    requirements: {
      BrandID: [9],
      userRole: 'SYSTEM',
      userPrivilege: 'MANAGE'
    }
  },
  {
    path: '/translations/new',
    exact: true,
    name: 'Opret Tranlsation',
    component: TranslationCreate,
    requirements: {
      BrandID: [9],
      userRole: 'SYSTEM',
      userPrivilege: 'MANAGE'
    }
  },
  {
    path: '/translations/languages',
    exact: true,
    name: 'Languages',
    component: LanguagesList,
    requirements: {
      BrandID: [9],
      userRole: 'SYSTEM',
      userPrivilege: 'MANAGE'
    }
  },
  {
    path: '/translations/languages/new',
    exact: true,
    name: 'Opret Language',
    component: LanguageCreate,
    requirements: {
      BrandID: [9],
      userRole: 'SYSTEM',
      userPrivilege: 'MANAGE'
    }
  },
  {
    path: '/translations/languages/:LanguageID',
    exact: true,
    name: 'Edit Language',
    component: LanguageCreate,
    requirements: {
      BrandID: [9],
      userRole: 'SYSTEM',
      userPrivilege: 'MANAGE'
    }
  },
  {
    path: '/translations/:PhraseID',
    exact: true,
    name: 'Edit Translations',
    component: TranslationEdit,
    requirements: {
      BrandID: [9],
      userRole: 'SYSTEM',
      userPrivilege: 'MANAGE'
    }
  },
  {
    path: '/manage-content',
    exact: true,
    name: 'Club Content',
    component: ClubCMS,
    requirements: {
      userRole: 'SYSTEM',
      userPrivilege: 'MANAGE'
    }
  },
  {
    path: '/user-reports',
    exact: true,
    name: 'User Reports',
    component: UserReports,
    requirements: {
      userRole: 'SYSTEM',
      userPrivilege: 'MANAGE'
    }
  },
  /* VAULTS */
  {
    path: '/vaults',
    exact: true,
    name: 'Vaults',
    component: VaultList,
    requirements: {
      BrandID: [9],
      userRole: 'SYSTEM',
      userPrivilege: 'MANAGE'
    }
  },
  {
    path: '/vaults/new',
    exact: true,
    name: 'Opret Vault',
    component: VaultCreate,
    requirements: {
      BrandID: [9],
      userRole: 'SYSTEM',
      userPrivilege: 'MANAGE'
    }
  },
  {
    path: '/vaults/:VaultID',
    exact: true,
    name: 'Edit Vault',
    component: VaultEdit,
    requirements: {
      BrandID: [9],
      userRole: 'SYSTEM',
      userPrivilege: 'MANAGE'
    }
  },
  /* Orders */
  {
    path: '/orders',
    exact: true,
    name: 'Ordrer',
    component: Order,
    icon: <FontAwesomeIcon icon='truck' className='nav-icon' />,
    requirements: {
      BrandID: [9],
      userRole: 'AGENT'
    }
  },
  {
    path: '/orders/custom-order-form',
    exact: true,
    name: 'Custom Order',
    component: CustomOrder
  },
  {
    path: '/orders/obfuscationSchedule',
    exact: true,
    name: 'Orders to be obfuscated',
    component: OrderObfuscationSchedule
  },
  {
    path: '/orders/print',
    exact: true,
    name: 'Print Order',
    component: OrderPrint
  },
  {
    path: '/orders/:OrderID',
    exact: true,
    name: 'Detaljer',
    component: Order
  },
  {
    path: '/suppliers',
    exact: true,
    name: 'Leverandører',
    component: SupplierOverview,
    requirements: {
      BrandID: [9],
      userRole: 'SYSTEM',
      userPrivilege: 'MANAGE'
    }
  },
  {
    path: '/suppliers/new',
    exact: true,
    name: 'Opret',
    component: SupplierCreate,
    requirements: {
      BrandID: [9],
      userRole: 'SYSTEM',
      userPrivilege: 'MANAGE'
    }
  },
  {
    path: '/suppliers/:SupplierID',
    exact: true,
    name: 'Detaljer',
    component: SupplierEdit,
    requirements: {
      BrandID: [9],
      userRole: 'SYSTEM',
      userPrivilege: 'MANAGE'
    }
  },
  {
    path: '/colors',
    exact: true,
    name: 'Colors',
    component: ColorsOverview,
    requirements: {
      BrandID: [9],
      userRole: 'SYSTEM',
      userPrivilege: 'MANAGE'
    }
  },
  {
    path: '/colors/new',
    exact: true,
    name: 'Opret',
    component: ColorsForm,
    requirements: {
      BrandID: [9],
      userRole: 'SYSTEM',
      userPrivilege: 'MANAGE'
    }
  },
  {
    path: '/colors/:ColorID',
    exact: true,
    name: 'Detaljer',
    component: ColorsForm,
    requirements: {
      BrandID: [9],
      userRole: 'SYSTEM',
      userPrivilege: 'MANAGE'
    }
  },
  {
    path: '/announcements',
    exact: true,
    name: 'Announcement',
    component: AnnouncementOverview,
    requirements: {
      userRole: 'SYSTEM',
      userPrivilege: 'MANAGE'
    }
  },
  {
    path: '/announcements/new',
    exact: true,
    name: 'Opret',
    component: AnnouncementCreate,
    requirements: {
      userRole: 'SYSTEM',
      userPrivilege: 'MANAGE'
    }
  },
  {
    path: '/announcements/:AnnouncementID',
    exact: true,
    name: 'Detaljer',
    component: AnnouncementEdit,
    requirements: {
      userRole: 'SYSTEM',
      userPrivilege: 'MANAGE'
    }
  },
  {
    path: '/user-prompts',
    exact: true,
    name: 'User Prompts',
    component: UserPromptList,
    requirements: {
      userRole: 'SYSTEM',
      userPrivilege: 'MANAGE'
    }
  },
  {
    path: '/user-prompts/new',
    exact: true,
    name: 'Opret User Prompt',
    component: UserPromptCreate,
    requirements: {
      userRole: 'SYSTEM',
      userPrivilege: 'MANAGE'
    }
  },
  {
    path: '/user-prompts/:UserPromptID',
    exact: true,
    name: 'Detaljer',
    component: UserPromptEdit,
    requirements: {
      userRole: 'SYSTEM',
      userPrivilege: 'MANAGE'
    }
  },
  {
    path: '/season-orders',
    exact: true,
    name: 'Season Ordrer',
    component: Order,
    props: { seasonOrder: true },
    icon: <FontAwesomeIcon icon='truck' className='nav-icon' />,
    requirements: {
      BrandID: [9],
      userRole: 'SYSTEM',
      userPrivilege: 'MANAGE'
    }
  },
  {
    path: '/season-orders/print',
    exact: true,
    name: 'Print Order',
    props: { seasonOrder: true },
    component: OrderPrint
  },
  {
    path: '/season-orders/:OrderID',
    exact: true,
    name: 'Detaljer',
    props: { seasonOrder: true },
    component: Order
  },
  {
    path: '/tax-report',
    exact: true,
    name: 'Skatteopgørelse',
    component: TaxReport,
    icon: <FontAwesomeIcon icon='file-invoice' className='nav-icon' />,
    requirements: {
      BrandID: [9],
      userRole: 'SYSTEM',
      userPrivilege: 'MANAGE'
    }
  },
  {
    path: '/tax-overview',
    exact: true,
    name: 'Tax Overview',
    component: TaxOverview,
    icon: <FontAwesomeIcon icon='file-invoice' className='nav-icon' />,
    requirements: {
      BrandID: [9],
      userRole: 'SYSTEM',
      userPrivilege: 'MANAGE'
    }
  },
  {
    path: '/tax-earnings',
    exact: true,
    name: 'Tax on Earnings',
    component: EarningsTaxReport,
    icon: <FontAwesomeIcon icon='file-invoice' className='nav-icon' />,
    requirements: {
      BrandID: [9],
      userRole: 'SYSTEM',
      userPrivilege: 'MANAGE'
    }
  },
  {
    path: '/monthly-tax',
    exact: true,
    name: 'Monthly tax info',
    component: MonthlyTaxInfo,
    icon: <FontAwesomeIcon icon='file-invoice' className='nav-icon' />,
    requirements: {
      BrandID: [9],
      userRole: 'SYSTEM',
      userPrivilege: 'MANAGE'
    }
  },
  /* Products */
  {
    path: '/products',
    exact: true,
    name: 'Products',
    component: ProductList,
    requirements: {
      BrandID: [8, 9],
      userRole: 'AGENT'
    }
  },
  {
    path: '/products/new',
    exact: true,
    name: 'Opret produkt',
    component: ProductCreate,
    requirements: {
      BrandID: [8, 9],
      userRole: 'AGENT'
    }
  },
  {
    path: '/products/:ProductID',
    name: 'Detaljer',
    exact: true,
    component: ProductEdit,
    requirements: {
      BrandID: [8, 9],
      userRole: 'AGENT'
    }
  },
  {
    path: '/packages',
    exact: true,
    name: 'Package',
    component: PackageOverview,
    requirements: {
      BrandID: [8, 9],
      userRole: 'AGENT'
    }
  },
  {
    path: '/packages/new',
    exact: true,
    name: 'Opret pakke',
    component: PackageForm,
    requirements: {
      BrandID: [8, 9],
      userRole: 'AGENT'
    }
  },
  {
    path: '/packages/:ProductPackageID',
    name: 'Detaljer',
    exact: true,
    component: PackageForm,
    requirements: {
      BrandID: [8, 9],
      userRole: 'AGENT'
    }
  },
  {
    path: '/cron',
    exact: true,
    name: 'Cron overview',
    component: CronOverview,
    icon: <FontAwesomeIcon icon='cogs' className='nav-icon' />,
    requirements: {
      BrandID: [9],
      userRole: 'SYSTEM',
      userPrivilege: 'MANAGE'
    }
  },
  /* Catalogs */
  {
    path: '/catalogs',
    exact: true,
    name: 'Catalogs',
    component: CatalogList,
    requirements: {
      BrandID: [9],
      userRole: 'AGENT'
    }
  },
  {
    path: '/catalogs/new',
    exact: true,
    name: 'Opret katalog',
    component: CatalogCreate,
    requirements: {
      BrandID: [9],
      userRole: 'AGENT'
    }
  },
  {
    path: '/catalogs/:CatalogID',
    exact: true,
    name: 'Detaljer',
    component: CatalogEdit,
    requirements: {
      BrandID: [9],
      userRole: 'AGENT'
    }
  },

  /*Category*/
  {
    path: '/category',
    exact: true,
    name: 'Category',
    component: CategoryList,
    requirements: {
      BrandID: [9],
      userRole: 'SYSTEM',
      userPrivilege: 'MANAGE'
    }
  },
  {
    path: '/category/new',
    exact: true,
    name: 'Opret Category',
    component: CategoryCreate,
    requirements: {
      BrandID: [9],
      userRole: 'SYSTEM',
      userPrivilege: 'MANAGE'
    }
  },
  {
    path: '/category/subcategory',
    exact: true,
    name: 'Sub Category',
    component: SubCategories,
    requirements: {
      BrandID: [9],
      userRole: 'SYSTEM',
      userPrivilege: 'MANAGE'
    }
  },
  {
    path: '/category/:CategoryID',
    exact: true,
    name: 'Edit Category',
    component: CategoryEdit,
    requirements: {
      BrandID: [9],
      userRole: 'SYSTEM',
      userPrivilege: 'MANAGE'
    }
  },
  {
    path: '/dashboards/:DashboardID/:DashboardToken/:Criteria',
    exact: true,
    name: 'Dashboards',
    component: props => <DashboardFrame {...props} height='1900' />,
    hasMenu: true,

    icon: <FontAwesomeIcon icon='chart-line' className='nav-icon' />,
    requirements: {
      BrandID: [167]
    },
    children: [
      {
        name: 'Oversigt',
        url: '/dashboards/69771000005980700/6880047962d8c1af5dc90302ee81a233/ZOHO_CRITERIA=%22bi_club_brands%22.%22BrandID%22%20%3D%20167',
        exact: true,
        icon: <FontAwesomeIcon icon='chart-bar' className='nav-icon' />,
        requirements: {
          BrandID: 167
        }
      }
    ]
  },
  {
    path: '/settings',
    exact: true,
    name: 'Settings',
    component: Settings,
    hasMenu: true,
    icon: <FontAwesomeIcon icon='cog' className='nav-icon' />,
    children: [
      {
        name: 'Tokens',
        url: '/settings',
        exact: true,
        icon: <FontAwesomeIcon icon='wrench' className='nav-icon' />
      },
      {
        name: 'maintenance',
        url: '/settings/maintenance',
        exact: true,
        icon: <FontAwesomeIcon icon='screwdriver' className='nav-icon' />,
        requirements: {
          BrandID: 9,
          userRole: 'SYSTEM',
          userPrivilege: 'MANAGE'
        }
      }
    ],
    requirements: {
      userRole: 'CLIENT',
      userPrivilege: 'MANAGE'
    }
  },
  {
    path: '/settings/maintenance',
    name: 'maintenance',
    exact: true,
    component: Maintenance,
    props: { msb: true },
    requirements: {
      BrandID: [9],
      userRole: 'SYSTEM',
      userPrivilege: 'MANAGE'
    }
  },
  {
    path: '/management/:DashboardID/:DashboardToken',
    exact: true,
    url: '/management',
    name: 'Management',
    component: DashboardFrame,
    hasMenu: true,
    icon: <FontAwesomeIcon icon='users' className='nav-icon' />,
    children: [
      {
        name: 'Overblik',
        url: '/management/69771000001172544/112c86509b4988064589b91b8aed74d4',
        exact: true,
        icon: <FontAwesomeIcon icon='chart-bar' className='nav-icon' />
      },
      {
        name: 'Brand oversigt',
        url: '/management/69771000005790251/ea5358dfbb1ebd2ba40a5bffb2d51bfd',
        exact: true,
        icon: <FontAwesomeIcon icon='chart-bar' className='nav-icon' />
      },
      {
        name: 'Produkter',
        url: '/management/69771000001380349/9a6913c46f072c6d7aba51337ca54dfb',
        component: Settings,
        exact: true,
        icon: <FontAwesomeIcon icon='chart-bar' className='nav-icon' />
      },
      {
        name: 'Klaviyo oversigt',
        url: '/management/69771000005811316/7d53a0253f7af4be3f5ccebc151a6b4d',
        exact: true,
        icon: <FontAwesomeIcon icon='chart-bar' className='nav-icon' />
      },
      {
        name: 'WLB oversigt',
        url: '/management/69771000006306977/dfd9130357451d4941c65f6578b0f286',
        exact: true,
        icon: <FontAwesomeIcon icon='chart-bar' className='nav-icon' />
      },
      {
        name: 'Kundeservice',
        url: '/management/69771000005505741/45b58536292f8622ccf0557f92adb128',
        exact: true,
        icon: <FontAwesomeIcon icon='chart-bar' className='nav-icon' />
      }
    ],
    requirements: {
      BrandID: [9],
      userRole: 'SYSTEM',
      userPrivilege: 'MANAGE'
    }
  },
  /* log out */
  {
    path: '/logout',
    exact: true,
    name: 'Log Ud',
    component: Logout,
    hasMenu: true,
    icon: <FontAwesomeIcon icon='sign-out-alt' className='nav-icon' />
  },
  {
    path: '/dashboard/:SID',
    exact: true,
    name: 'KAM Details',
    component: KamDetails,
    hasMenu: false
  },
  {
    path: '/all-earned-point/startDate=:startDate&endDate=:endDate',
    exact: true,
    name: 'Optjente Point',
    component: AllEarnedPoints,
    hasMenu: false,
    requirements: {
      userRole: 'CLIENT',
      userPrivilege: 'MANAGE'
    }
  },
  {
    path: '/all-used-point/startDate=:startDate&endDate=:endDate',
    exact: true,
    name: 'Brugte Point',
    component: AllUsedPoints,
    hasMenu: false,
    requirements: {
      userRole: 'CLIENT',
      userPrivilege: 'MANAGE'
    }
  },
  {
    path: '/all-unused-point/endDate=:endDate',
    exact: true,
    name: 'Ubrugte Point',
    component: AllUnusedPoints,
    hasMenu: false,
    requirements: {
      userRole: 'CLIENT',
      userPrivilege: 'MANAGE'
    }
  },
  {
    path: '/all-unused-activation-point/endDate=:endDate',
    exact: true,
    name: 'Ubrugte Aktiverede Point',
    component: AllUnusedPoints,
    hasMenu: false,
    requirements: {
      userRole: 'CLIENT',
      userPrivilege: 'MANAGE',
      features: { enableWalletActivation: true }
    }
  },
  {
    path: '/all-active-inactive-users/status=:status&startDate=:startDate&endDate=:endDate',
    exact: true,
    name: 'Declined Aktive Inaktive Brugere',
    component: ActiveInactiveUsers,
    hasMenu: false,
    requirements: {
      userRole: 'CLIENT',
      userPrivilege: 'MANAGE'
    }
  },
  {
    path: '/all-active-inactive-users/status=:status&endDate=:endDate',
    exact: true,
    name: 'Aktive Inaktive Brugere',
    component: ActiveInactiveUsers,
    hasMenu: false,
    requirements: {
      userRole: 'CLIENT',
      userPrivilege: 'MANAGE'
    }
  },
  {
    path: '/all-active-inactive-users-points/endDate=:endDate',
    exact: true,
    name: 'Aktive Inaktive Brugere Point',
    component: ActiveInactiveUsersPoints,
    hasMenu: false,
    requirements: {
      userRole: 'CLIENT',
      userPrivilege: 'MANAGE'
    }
  },
  {
    path: '/all-orders-within-dates/startDate=:startDate&endDate=:endDate',
    exact: true,
    name: 'Ordrer',
    component: OrdersListWithinDates,
    hasMenu: false,
    requirements: {
      userRole: 'CLIENT',
      userPrivilege: 'MANAGE'
    }
  },
  {
    path: '/all-dealers-within-dates/startDate=:startDate&endDate=:endDate',
    exact: true,
    name: 'Dealers',
    component: DealersListWithinDates,
    hasMenu: false,
    requirements: {
      userRole: 'CLIENT',
      userPrivilege: 'MANAGE'
    }
  },
  {
    path: '/competition',
    exact: true,
    name: 'Konkurrencer',
    component: CompetitionOverview
  },
  {
    path: '/competition/:CompetitionID/competitionUsers',
    name: 'Brugere deltagere',
    exact: true,
    component: CompetitionUsers
  },
  {
    path: '/competition/new',
    name: 'Opret Konkurrencer',
    exact: true,
    component: CompetitionForm
  },
  {
    path: '/competition/:CompetitionID',
    name: 'Edit Konkurrencer',
    exact: true,
    component: CompetitionForm
  },
  {
    path: '/christmas-calendar',
    exact: true,
    name: 'Julekalender',
    component: ChristmasCalendarOverview
  },
  {
    path: '/christmas-calendar/new',
    name: 'Opret Julekalender',
    exact: true,
    component: ChristmasCalendarForm
  },
  {
    path: '/christmas-calendar/:CompetitionID',
    name: 'Edit Julekalender',
    exact: true,
    component: ChristmasCalendarForm
  },
  {
    path: '/christmas-calendar/:CompetitionID/view',
    name: 'View Julekalender',
    exact: true,
    component: ChristmasCalendarForm
  },
  {
    path: '/christmas-calendar/:CompetitionID/day/new',
    name: 'Opret Julekalender day',
    exact: true,
    component: ChristmasCalendarDaysForm
  },
  {
    path: '/christmas-calendar/:CompetitionID/day/:ChristmasDayID',
    name: 'Edit Julekalender day',
    exact: true,
    component: ChristmasCalendarDaysForm
  },
  {
    path: '/configure-labels',
    exact: true,
    name: 'Configure Labels',
    component: ConfigureLabels
  },
  {
    path: '/highlighted-product',
    name: 'Highlighted product',
    exact: true,
    component: HighlightedProductOverview
  },
  {
    path: '/highlighted-product/new',
    name: 'Opret Highlighted product',
    exact: true,
    component: HighlightedProduct
  },
  {
    path: '/highlighted-product/:HighlightedProductID',
    name: 'Edit Highlighted product',
    exact: true,
    component: HighlightedProduct
  },
  {
    path: '/shops',
    name: 'Shops',
    exact: true,
    component: ShopOverview
  },
  {
    path: '/shops/new',
    name: 'Opret Shops',
    exact: true,
    component: Shops
  },
  {
    path: '/shops/:ShopBannerID',
    name: 'Edit Shops',
    exact: true,
    component: Shops
  }
]

const _noRoute = [
  {
    path: '/',
    name: 'home',
    component: NotAdmin,
    hasMenu: true,
    icon: <FontAwesomeIcon icon='home' className='nav-icon' />,
    hideMenuText: true
  },
  {
    path: '/logout',
    exact: true,
    name: 'Log Ud',
    component: Logout,
    hasMenu: true,
    icon: <FontAwesomeIcon icon='sign-out-alt' className='nav-icon' />
  }
]

const generateRoutes = (settings = null) => {
  if (!settings) {
    return _noRoute
  }
  let routes = _routes
    .map(e => {
      return verifyRequirements(e, settings)
    })
    .filter(e => {
      return e
    })
  if (routes.length == 2) {
    return _noRoute
  }
  return routes
}
const generateMenu = (settings = null) => {
  let items = [].concat(generateRoutes(settings))
  items = items.filter(e => {
    return e.hasMenu
  })
  items.forEach(e => {
    if (e.hideMenuText) {
      e.name = ' '
      delete e.hideMenuText
    }
    if (e.path) {
      e.url = e.path
      delete e.path
    }
    if (e.component) {
      delete e.component
    }
    delete e.hasMenu
    if (e.deleteFromRoutes) {
      e.deleteFromRoutes
    }
  })
  return { items }
}

export { generateMenu, generateRoutes }
