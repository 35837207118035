import React, { useState } from 'react'
import { ProductActions } from 'Stores/productStore'
import { Row, Col, Input } from 'reactstrap'
import 'react-image-crop/dist/ReactCrop.css'

export default function ProductImageUpload() {
  const [isUploading, setIsUploading] = useState(false)

  const onSelectFile = e => {
    if (e.target.files && e.target.files.length > 0) {
      setIsUploading(true)
      for (const file of e.target.files) {
        const newImage = {
          BrandID: '',
          UserID: '',
          imageName: '',
          imageUID: '',
          imageOriginSource: '',
          imageOptimizedSource: '',
          imageOptimizedSourceMedium: '',
          imageOptimizedSourceSmall: '',
          imageCroppedSource: '',
          imageCroppedSourceMedium: '',
          imageCroppedSourceSmall: '',
          imageSizes: '',
          imageWidth: '',
          imageHeight: '',
          mime: '',
          timeInsert: new Date(),
          poolOrder: 0,
          imageBlob: file
        }
        ProductActions.addAttachment(newImage)
      }
      setIsUploading(false)
    }
  }

  return (
    <Row>
      <Col xs='2'>
        <label>Attachments</label>
      </Col>
      <Col xs='2'>
        <Input
          type='file'
          accept='image/*'
          onClick={e => (e.target.value = '') /*To enable uploading same image twice in a row*/}
          onChange={onSelectFile}
          disabled={isUploading}
          multiple
        />
      </Col>
    </Row>
  )
}
