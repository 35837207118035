import { useState, useEffect } from 'react'
import Proxies from 'Components/proxies'
const { GET, POST } = Proxies
/* eslint-disable eqeqeq */
import React from 'react'
import DynamicTable from 'Components/dynamicTable'
import CollapsibleCard from 'Components/collapsibleCard'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import moment from 'moment'
import 'moment/locale/da'
import { Button, Col, Form, Input, Row, ButtonGroup, UncontrolledTooltip as Tooltip } from 'reactstrap'
import FormField from 'Components/formField'
import DatePicker from 'react-datepicker'
import { currencyFormatter } from 'Util/currencyFormatter'

const MerchInvoiceDetails = props => {
  const [invoiceDetails, setInvoiceDetails] = useState({})
  const [invoiceElements, setInvoiceElements] = useState([])
  const [departments, setDepartments] = useState([])
  const [loading, setLoading] = useState(false)
  const [elementsLoading, setElementsLoading] = useState(false)
  const [filterDepartment, setFilterDepartment] = useState(null)

  const createStatusList = ['OPEN']
  const editStatusList = ['OPEN', 'CLOSED']

  const showColumn = [11, 167].includes(props.BrandID) || !!props.isAdmin
  const elementHeaders = {
    OrderlineID: '#',
    ConfirmationID: 'Confirmation ID',
    OrderID: 'Ordre ID',
    UserID: 'Bruger ID',
    userName: 'Bruger',
    userUID: 'Unikt ID',
    userCompany: 'Virksomhed',
    orderlineTitle: 'Vare',
    orderAmountPoints: 'Point',
    ...(showColumn && { orderPointRatio: 'Ratio' }),
    ...(showColumn && { orderlineProductPrice: 'Basis' }),
    ...(showColumn && { orderCurrency: 'Valuta' }),
    ...(!props.hideDkkColumnForInvoice && { orderAmountDKK: 'DKK' }),
    timeInsert: 'Oprettet',
    timeShipped: 'Afsendt',
    ...(props.enableVault && { vaultSlug: 'Vault Slug' })
  }

  const fetchInvoiceDetails = async () => {
    setLoading(true)
    const response = await GET(!props.isAdmin ? 'invoices/fetch' : 'invoices/adminFetch', { InvoiceID: props.match.params.InvoiceID })
    setLoading(false)
    if (response) {
      setInvoiceDetails(response)
    }
  }

  const fetchInvoiceElements = async department => {
    setElementsLoading(true)
    const response = await GET(!props.isAdmin ? 'invoices/elements' : 'invoices/adminElements', {
      InvoiceID: props.match.params.InvoiceID,
      department
    })
    setElementsLoading(false)
    if (response.length) {
      setInvoiceElements(response)
    }
  }

  const fetchByDepartment = async () => {
    const response = await GET(!props.isAdmin ? 'invoices/merchandiseDetails' : 'invoices/adminMerchandiseDetails', {
      InvoiceID: props.match.params.InvoiceID
    })
    if (response.length) {
      setDepartments(response)
    }
  }

  const saveInvoice = async () => {
    setLoading(true)
    await POST('invoices/replace', invoiceDetails)
    setLoading(false)
  }

  const updateDateField = (m, field) => {
    let value = ''
    if (field === 'timeEnd') {
      value = m.endOf('day').toString()
    } else if (field === 'timeStart') {
      value = m.startOf('day').toString()
    }
    setInvoiceDetails({ ...invoiceDetails, [field]: value })
  }

  const filterElements = department => {
    setFilterDepartment(department)
    fetchInvoiceElements(department)
  }

  const clearFilter = () => {
    setFilterDepartment(null)
    fetchInvoiceElements()
  }

  useEffect(() => {
    fetchInvoiceDetails()
    fetchInvoiceElements()
    fetchByDepartment()
  }, [])

  return loading ? (
    <FontAwesomeIcon icon='spinner' spin />
  ) : (
    <React.Fragment>
      <CollapsibleCard startOpen={false} header={<strong>Detaljer for faktura</strong>}>
        <Form
          onSubmit={e => {
            e.preventDefault()
          }}
        >
          <FormField
            data={invoiceDetails}
            field={'invoiceTitle'}
            name='Titel'
            onChange={e => setInvoiceDetails({ ...invoiceDetails, invoiceTitle: e.target.value })}
            required
          />
          <FormField field={'invoiceStatus'} name='Status'>
            <Input
              type='select'
              name='invoiceStatus'
              value={invoiceDetails.invoiceStatus}
              onChange={e => setInvoiceDetails({ ...invoiceDetails, invoiceStatus: e.target.value })}
            >
              {props.create &&
                createStatusList.map(e => {
                  return (
                    <option value={e} key={e}>
                      {e}
                    </option>
                  )
                })}
              {!props.create &&
                editStatusList.map(e => {
                  return (
                    <option value={e} key={e}>
                      {e}
                    </option>
                  )
                })}
            </Input>
          </FormField>
          <FormField field={'timeStart'} name='Start dato'>
            <DatePicker
              selected={moment(invoiceDetails.timeStart)}
              onChange={e => {
                updateDateField(e, 'timeStart')
              }}
            />
          </FormField>
          <FormField field={'timeEnd'} name='Slut dato'>
            <DatePicker
              selected={moment(invoiceDetails.timeEnd)}
              onChange={e => {
                updateDateField(e, 'timeEnd')
              }}
            />
          </FormField>
          <Button onClick={saveInvoice} disabled={loading}>
            {loading ? <FontAwesomeIcon icon='spinner' spin /> : 'Gem'}
          </Button>
          <Button color='danger' onClick={fetchInvoiceDetails} className='ml-2'>
            Nulstil
          </Button>
        </Form>
      </CollapsibleCard>
      {!!departments.length && (
        <CollapsibleCard header={<strong>Departments</strong>} startOpen>
          <Row style={{ marginBottom: 8 }}>
            <Col md='2'>
              <strong>Department</strong>
            </Col>
            <Col md='2'>
              <strong>Total Amount</strong>
            </Col>
            <Col md='2'></Col>
          </Row>
          {departments.map(item => {
            const isFilterEnabled = item.department === filterDepartment
            return (
              <Row key={item.department} style={{ marginBottom: 8 }}>
                <Col md='2'>{item.department || 'None'}</Col>
                <Col md='2'>{item.total ? currencyFormatter.format(item.total) : '0,00 kr.'}</Col>
                <Col md='2'>
                  <ButtonGroup>
                    <Tooltip target={'view' + item.department}>{isFilterEnabled ? 'Clear' : 'Vis'}</Tooltip>
                    <Button
                      color={isFilterEnabled ? 'danger' : 'success'}
                      id={'view' + item.department}
                      onClick={() => (isFilterEnabled ? clearFilter() : filterElements(item.department))}
                    >
                      <FontAwesomeIcon icon={isFilterEnabled ? 'ban' : 'eye'} />
                    </Button>
                  </ButtonGroup>
                </Col>
              </Row>
            )
          })}
        </CollapsibleCard>
      )}
      <CollapsibleCard header={<strong>Linier</strong>} startOpen>
        {elementsLoading ? (
          <FontAwesomeIcon icon='spinner' spin />
        ) : (
          <DynamicTable
            responsive
            size='sm'
            data={invoiceElements}
            headers={elementHeaders}
            options={{
              fieldFunctions: {
                orderType: e => {
                  return e.orderCustomOrder ? 'Custom' : 'Normal'
                },
                timeInsert: e => {
                  return e.timeInsert ? moment(e.timeInsert).format('DD-MM-YYYY HH:mm') : ''
                },
                timeShipped: e => {
                  return e.timeShipped ? moment(e.timeShipped).format('DD-MM-YYYY HH:mm') : ''
                }
              },
              fieldStringFunctions: {
                timeInsert: e => {
                  return moment(e.timeInsert).format('x')
                },
                timeShipped: e => {
                  return moment(e.timeShipped).format('x')
                }
              }
            }}
          />
        )}
      </CollapsibleCard>
    </React.Fragment>
  )
}

export default MerchInvoiceDetails
