import React from 'react'
import { Component } from 'reflux'
import { UserPromptActions, UserPromptStore, STATUS } from 'Stores/userPromptStore'
import { Button, Form, Input, Row, Col, Label, Table, CustomInput } from 'reactstrap'
import _ from 'lodash'
import CollapsibleCard from 'Components/collapsibleCard'
import { Redirect } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import moment from 'moment'
import { Fragment } from 'react'
import DynamicTable from '../../components/dynamicTable'
import Editor from 'rich-markdown-editor'
import { priorities } from '../../stores/userPromptStore'

const promptTypes = ['CUSTOM', 'PHONE', 'TAX_PERCENTAGE', 'AUTOMATIC_ACTIVATION']

export default class UserPromptForm extends Component {
  constructor(props) {
    super(props)
    this.stores = [UserPromptStore]
    this.storeKeys = ['status', 'userPromptDetails', 'groups']
  }

  componentDidMount = () => {
    UserPromptActions.getGroups()
  }

  resetUserPrompt = () => {
    if (_.get(this.props, 'match.params.UserPromptID')) {
      UserPromptActions.getUserPromptDetails(this.props.match.params.UserPromptID)
    } else {
      UserPromptActions.createEmptyUserPrompt()
    }
  }

  onBodyTextChange = v => {
    const formattedText = v().replace(/\n\\/g, '\n')
    UserPromptActions.updateBodyText(formattedText)
  }

  render() {
    if (this.state.status === STATUS.REDIRECT) {
      return <Redirect to='/user-prompts' />
    }
    if (this.state.status === STATUS.IS_FETCHING) {
      return <FontAwesomeIcon icon='spinner' spin />
    }
    return (
      <>
        <CollapsibleCard startOpen header={<strong>{_.get(this.props, 'match.params.UserPromptID') ? 'Detaljer' : 'Opret'}</strong>}>
          <Form>
            <Row className='mb-2'>
              <Col xs='2'>
                <Label for='title'>Titel</Label>
              </Col>
              <Col xs='10'>
                <Input
                  type='text'
                  value={this.state.userPromptDetails.title}
                  name='title'
                  id='title'
                  onChange={UserPromptActions.updateUserPromptField}
                  invalid={!this.state.userPromptDetails.title.length}
                />
              </Col>
            </Row>
            <Row className='mb-2'>
              <Col xs='2'>
                <Label for='bodyText'>Brødtekst</Label>
              </Col>
              <Col xs='10'>
                <Editor
                  defaultValue={this.state.userPromptDetails.bodyText}
                  style={{ border: '1px solid #e4e7ea', borderRadius: '0.25rem', padding: 10, paddingLeft: 30, color: '#5c6873' }}
                  onChange={this.onBodyTextChange}
                  placeholder={''}
                  disableExtensions={[
                    'checkbox_list',
                    'strikethrough',
                    'checkbox_item',
                    'table',
                    'blockquote',
                    'container_notice',
                    'code_fence',
                    'highlight',
                    'code_block',
                    'code_inline'
                  ]}
                />
              </Col>
            </Row>
            <Row className='mb-2'>
              <Col xs='2'>
                <Label for='promptType'>Type</Label>
              </Col>
              <Col xs='10'>
                <Input
                  type='select'
                  name='promptType'
                  value={this.state.userPromptDetails.promptType}
                  id='promptType'
                  onChange={UserPromptActions.updateUserPromptField}
                >
                  {promptTypes.map((type, index) => {
                    return (
                      <option value={type} key={index}>
                        {type}
                      </option>
                    )
                  })}
                </Input>
              </Col>
            </Row>
            {this.state.userPromptDetails.promptType === 'CUSTOM' && (
              <>
                <Row className='mb-2'>
                  <Col xs='2'>
                    <Label for='buttonText'>Knap tekst</Label>
                  </Col>
                  <Col xs='10'>
                    <Input
                      type='text'
                      value={this.state.userPromptDetails.buttonText}
                      name='buttonText'
                      id='buttonText'
                      onChange={UserPromptActions.updateUserPromptField}
                    />
                  </Col>
                </Row>
                <Row className='mb-2'>
                  <Col xs='2'>
                    <Label for='buttonLink'>Knap link</Label>
                  </Col>
                  <Col xs='10'>
                    <Input
                      type='text'
                      value={this.state.userPromptDetails.buttonLink}
                      name='buttonLink'
                      id='buttonLink'
                      onChange={UserPromptActions.updateUserPromptField}
                    />
                  </Col>
                </Row>
              </>
            )}
            <Row className='mb-2'>
              <Col xs='2'>
                <Label for='poolOrder'>Prioritet</Label>
              </Col>
              <Col xs='10'>
                <Input
                  type='select'
                  name='poolOrder'
                  value={this.state.userPromptDetails.poolOrder}
                  id='poolOrder'
                  onChange={UserPromptActions.updateUserPromptField}
                >
                  {priorities.map((type, index) => {
                    return (
                      <option value={index * 10} key={index}>
                        {type}
                      </option>
                    )
                  })}
                </Input>
              </Col>
            </Row>
            <Row className='mb-3'>
              <Col xs='2'>
                <label>Vises fra/til</label>
              </Col>
              <Col xs='10'>
                <Row xs='12'>
                  <Col xs='6'>
                    <Input
                      type='date'
                      value={moment(this.state.userPromptDetails.timeStart).format('YYYY-MM-DD')}
                      name='timeStart'
                      id='timeStart'
                      onChange={UserPromptActions.updateUserPromptField}
                    />
                  </Col>
                  <Col xs='6'>
                    <Input
                      type='date'
                      value={this.state.userPromptDetails.timeEnd ? moment(this.state.userPromptDetails.timeEnd).format('YYYY-MM-DD') : ''}
                      name='timeEnd'
                      id='timeEnd'
                      onChange={UserPromptActions.updateUserPromptField}
                    />
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row className='mb-2'>
              <Col xs='2'>
                <Label for='isActive'>Aktiv</Label>
              </Col>
              <Col xs='10'>
                <CustomInput
                  type='switch'
                  checked={this.state.userPromptDetails.isActive}
                  name='isActive'
                  id='isActive'
                  onChange={UserPromptActions.updateUserPromptField}
                />
              </Col>
            </Row>
            <Row className='mb-2'>
              <Col xs='2'>
                <Label for='isHideable'>Kan skjules</Label>
              </Col>
              <Col xs='10'>
                <CustomInput
                  type='switch'
                  checked={this.state.userPromptDetails.isHideable}
                  name='isHideable'
                  id='isHideable'
                  onChange={UserPromptActions.updateUserPromptField}
                />
              </Col>
            </Row>
            <Row className='mb-2'>
              <Col xs='2'>
                <Label for='userPromptAccessType'>Grupper</Label>
              </Col>
              <Col xs='10'>
                <Table className='mb-sm-0'>
                  <thead>
                    <tr>
                      <th>Gruppe ID</th>
                      <th>Brand ID</th>
                      <th>Navn</th>
                      <th>Beskrivelse</th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.state.userPromptDetails.groupIds &&
                      !!this.state.userPromptDetails.groupIds.length &&
                      this.state.userPromptDetails.groupIds.map((groupId, idx) => {
                        const group = this.state.groups.find(g => g.GroupID === groupId)
                        return (
                          <Fragment key={groupId}>
                            <tr>
                              <td>{group?.GroupID}</td>
                              <td>{group?.BrandID}</td>
                              <td>{group?.moduleName}</td>
                              <td>{group?.moduleDescription}</td>
                              <td>
                                <Button onClick={() => UserPromptActions.removeGroup(idx)} color='danger'>
                                  <FontAwesomeIcon icon='minus' />
                                </Button>
                              </td>
                            </tr>
                          </Fragment>
                        )
                      })}
                  </tbody>
                </Table>
              </Col>
            </Row>
            <Button
              onClick={UserPromptActions.saveUserPrompt}
              disabled={this.state.status === STATUS.IS_SAVING || !this.state.userPromptDetails.title.length}
            >
              {this.state.status === STATUS.IS_SAVING ? <FontAwesomeIcon icon='spinner' spin /> : 'Gem'}
            </Button>
            <Button color='danger' onClick={this.resetUserPrompt} className='ml-2'>
              Nulstil
            </Button>
          </Form>
        </CollapsibleCard>
        <CollapsibleCard startOpen header={<strong>Grupper</strong>}>
          <DynamicTable
            responsive
            size='sm'
            options={{
              startSort: {
                dir: 'DESC',
                column: 'GroupID'
              },
              addField: e => {
                return (
                  <td>
                    {/* <Tooltip>Tilføj</Tooltip> */}
                    <Button
                      onClick={() => {
                        UserPromptActions.addGroup(e.GroupID)
                      }}
                    >
                      <FontAwesomeIcon icon='plus' />
                    </Button>
                  </td>
                )
              }
            }}
            headers={{
              GroupID: '#',
              ...(this.state.isAdminAccess && { BrandID: 'Brand' }),
              BrandID: 'Brand',
              moduleName: 'Titel',
              moduleDescription: 'Beskrivelse'
            }}
            data={
              this.state.userPromptDetails.groupIds
                ? this.state.groups.filter(g => !this.state.userPromptDetails.groupIds.includes(g.GroupID))
                : this.state.groups
            }
          />
        </CollapsibleCard>
      </>
    )
  }
}
