/* eslint-disable eqeqeq */
/**
 * PaginatedTable
 *
 * Showing paginated data in a simple table, using DataTables CSS
 */
import React from 'react'
import { Button, Form, FormGroup, Input, Label, UncontrolledTooltip as Tooltip, Table, Row, Col } from 'reactstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import nl2br from 'Components/nl2br'
import ucwords from 'locutus/php/strings/ucwords'
import _ from 'lodash'
import { toast } from 'react-toastify'
import styled from 'styled-components'
import DownloadUtil from '../util/downloadUtil'

const StyledSpan = styled.span`
  &:hover {
    color: green;
  }
`
export default class PaginatedTable extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      isAllChecked: false,
      isChecked: false
    }
  }

  fetchData() {
    this.props.options.getdata()
  }

  downloadData() {
    let { data, headers, options } = this.props
    DownloadUtil.downloadData(data, headers, options)
  }
  downloadDataExcel() {
    let { data, headers, options } = this.props
    DownloadUtil.downloadDataExcel(data, headers, options)
  }
  label(label) {
    if (label.match(/^[A-Z_]{3,}$/)) {
      label = label.replace('_', ' ').toLowerCase()
    } else {
      label = label
        .replace(/[_\-.]+/g, ' ')
        .replace(/([A-Z])/g, ' $1')
        .replace(/([A-Z]) /g, '$1')
        .replace(/[\s]+/g, ' ')
    }
    return ucwords(label.trim())
  }
  sortColumn(ColumnID = null) {
    if (ColumnID !== null) {
      const sortColumn = ColumnID
      const sortDir = ColumnID == this.props.options.sortData.column && this.props.options.sortData.dir == 'ASC' ? 'DESC' : 'ASC'
      this.props.options.setSortData(sortColumn, sortDir)
      this.fetchData()
    }
  }
  setPaginationAmount(e) {
    let nextState = {
      paginationAmount: e.target.value,
      paginationPoint: 0
    }
    if (this.props.options.paginationPoint !== 0) {
      let currPaginationCount = (this.props.options.paginationPoint + 1) * this.props.options.paginationAmount
      let nextPaginationPoint = Math.floor(currPaginationCount / nextState.paginationAmount)
      nextState.paginationPoint = nextPaginationPoint
    }
    this.props.options.setPaginationAmount(nextState.paginationAmount)
    this.props.options.setPaginationPoint(nextState.paginationPoint)

    if (_.get(this.props.options, 'isCheckedAll')) {
      this.props.options.setAllChecked(false)
    }

    this.fetchData()
  }
  setPaginationPoint(e) {
    let paginationPoint = e.target.value - 1
    if (paginationPoint != this.props.options.paginationPoint) {
      this.props.options.setPaginationPoint(paginationPoint)
      this.fetchData()

      if (_.get(this.props.options, 'isCheckedAll')) {
        this.props.options.setAllChecked(false)
      }
    }
  }
  componentDidMount() {
    let nextState = {}
    if (_.get(this.props.options, 'isAllChecked')) {
      nextState.isAllChecked = this.props.options.isAllChecked
    }
    this.setState(nextState)
  }
  componentWillReceiveProps(props) {
    if (_.get(this.props.options, 'isAllChecked')) {
      this.setState({ isAllChecked: props.options.isAllChecked })
    }
  }
  prevPage() {
    this.props.options.setPaginationPoint(this.props.options.paginationPoint - 1)
    if (_.get(this.props.options, 'isCheckedAll')) {
      this.props.options.setAllChecked(false)
    }
    this.fetchData()
  }
  nextPage() {
    this.props.options.setPaginationPoint(this.props.options.paginationPoint + 1)
    if (_.get(this.props.options, 'isCheckedAll')) {
      this.props.options.setAllChecked(false)
    }
    this.fetchData()
  }
  copyTextToClipboard(value) {
    if (!navigator.clipboard) {
      // Clipboard API not available
      return
    }
    if (window.getSelection().toString() && this.props.options.copyHightlighText) {
      return
    }
    try {
      navigator.clipboard.writeText(value)
      toast.success('Kopieret: ' + value, {
        autoClose: 3000
      })
    } catch (err) {
      // console.error('Failed to copy!', err)
    }
  }
  rowHandler = (event, type, data) => {
    if (event.target.localName === 'span') {
      this.copyTextToClipboard(event.target.innerText)
    } else if (this.props && this.props.options && this.props.options.rowClickable && this.props.options.redirectTo) {
      type !== 'actions' && event.target.type !== 'checkbox' && this.props.options.redirectTo(data)
    }
  }
  onFilterTextChange = e => {
    this.props.options.setFilterText(e.target.value)
  }
  handleSearchKeyPress(e) {
    if (e.key === 'Enter') {
      this.props.options.setPaginationPoint(0)
      this.fetchData()
    }
  }
  toggleHandler(field, data) {
    if (data && data.length) {
      data.forEach(item => {
        item.checked = field.target.checked
      })
      this.setState({ isAllChecked: field.target.checked })
      this.props.options.setAllChecked({ isAllChecked: field.target.value })
    }
  }
  isSelected(data) {
    let isAllChecked = data && data.length ? data.reduce((sum, next) => sum && next['checked'], true) : false
    let isIndeterminate = false
    if (isAllChecked === false) {
      let value = data && data.length && data.filter(item => item.checked)
      isIndeterminate = value && value.length > 0 && true
    }
    return { isAllChecked, isIndeterminate }
  }
  render() {
    /* træk data, options og headers ud af this.props og efterlad resten i props */
    let { data, options, headers, className = '', hideSearch = false, ...props } = this.props
    className = className = ' dataTable table-striped table-bordered table-hover'
    if (!Array.isArray(data)) {
      return null
    }
    /* hvis der ikke er sendt headers med, så lad headers være feltnavnene */
    if (!headers) {
      headers = Object.keys(data[0])
    }
    /* find rækkefølge af felter - hvis headers ikke er sendt med, så lad rækkefølgen være den som data er leveret i */
    let fieldOrder = []
    if (!Array.isArray(headers)) {
      fieldOrder = Object.keys(headers)
    } else {
      fieldOrder = headers
    }
    /* sørg for at options er et objekt */
    if (!options) {
      options = {}
    }
    let isAllChecked = false
    let isIndeterminate = false
    if (this.props && this.props.options && this.props.options.showAdminAccessDropdown) {
      const response = this.isSelected(data)
      isAllChecked = response.isAllChecked
      isIndeterminate = response.isIndeterminate
    }
    /* klargør pagination */
    let paginationArr = []
    for (let i = 1; i <= Math.ceil(this.props.options.totalamount / this.props.options.paginationAmount); i++) {
      paginationArr.push(<option key={i}>{i}</option>)
    }
    return (
      <React.Fragment>
        {this.props && this.props.options && this.props.options.showAdminAccessDropdown && (
          <Row style={{ padding: '19px', marginBottom: '25px' }}>
            <Col xs={3}>
              <input
                className='form-check-input'
                type='checkbox'
                id={'checkbox'}
                name={'checkbox'}
                checked={isAllChecked}
                ref={input => {
                  if (input) {
                    input.indeterminate = isIndeterminate
                  }
                }}
                onChange={e => {
                  this.toggleHandler(e, data)
                }}
              />
              <Label check htmlFor={'checkbox'}>
                Select All
              </Label>
            </Col>
            <Col></Col>
            {this.props.options && this.props.options.showContent && <this.props.options.showContent />}
          </Row>
        )}
        <Row>
          <Col>
            <Form
              inline
              onSubmit={e => {
                e.preventDefault()
              }}
            >
              <FormGroup>
                <Label for='paginationAmount'>Vis</Label>
                <Input
                  className='mr-2 ml-2'
                  type='select'
                  value={this.props.options.paginationAmount}
                  id='paginationAmount'
                  onChange={this.setPaginationAmount.bind(this)}
                >
                  <option>10</option>
                  <option>25</option>
                  <option>50</option>
                  <option>100</option>
                  <option>150</option>
                  <option>250</option>
                  <option>500</option>
                  <option>1000</option>
                </Input>
                <Label for='paginationAmount'> rækker</Label>
              </FormGroup>
            </Form>
          </Col>
          <Col>
            <center>
              {this.props.options.loading ? (
                <FontAwesomeIcon icon='spinner' spin />
              ) : (
                <small>
                  Viser {Math.min(this.props.options.paginationPoint * this.props.options.paginationAmount + 1, this.props.options.totalamount)} -{' '}
                  {(this.props.options.paginationPoint + 1) * this.props.options.paginationAmount > this.props.options.totalamount
                    ? this.props.options.totalamount
                    : (this.props.options.paginationPoint + 1) * this.props.options.paginationAmount}{' '}
                  ud af {this.props.options.totalamount} rækker
                </small>
              )}
            </center>
          </Col>
          <Col>
            {!hideSearch && (
              <React.Fragment>
                <Tooltip target='downloadXlsx'>Download XLSX af det viste</Tooltip>
                <Button onClick={this.downloadDataExcel.bind(this)} id='downloadXlsx' className='float-right downloadBtn ml-1'>
                  <FontAwesomeIcon icon='file-excel' />
                </Button>
                <Tooltip target='download'>Download CSV af det viste</Tooltip>
                <Button onClick={this.downloadData.bind(this)} id='download' className='float-right downloadBtn ml-1'>
                  <FontAwesomeIcon icon='file-download' />
                </Button>
                <Form
                  inline
                  onSubmit={e => {
                    e.preventDefault()
                  }}
                  className='float-right'
                >
                  <Label for='filterText'>Søg: </Label>
                  <Input
                    className='ml-2'
                    id='filterText'
                    value={this.props.options.filterText}
                    onChange={this.onFilterTextChange}
                    onKeyDown={this.handleSearchKeyPress.bind(this)}
                  />
                </Form>
              </React.Fragment>
            )}
          </Col>
        </Row>
        <Table responsive size='sm' {...props} className={className}>
          <React.Fragment>
            {!options.hideHeader && (
              <thead>
                <tr>
                  {fieldOrder.map((e, i) => {
                    let className = 'sorting'
                    let title = this.label(e)
                    let css = { whiteSpace: 'normal' }
                    if (this.props.customcss) {
                      css = this.props.customcss
                    }
                    if (!Array.isArray(headers)) {
                      if (this.props.headerscss) {
                        if (e in this.props.headerscss) {
                          css = this.props.customcss ? Object.assign(this.props.headerscss[e], this.props.customcss) : this.props.headerscss[e]
                        }
                      }
                      if (!headers[e]) {
                        return null
                      }
                      title = headers[e]
                    }
                    if (e == this.props.options.sortData.column) {
                      if (this.props.options.sortData.dir == 'ASC') {
                        className = className + '_asc'
                      } else {
                        className = className + '_desc'
                      }
                    }
                    let onClick = () => this.sortColumn(e)
                    if ((options.unsortable && options.unsortable.indexOf(e) > -1) || e == 'functions') {
                      onClick = () => {
                        return false
                      }
                      className = ''
                    }
                    return (
                      <th key={i} style={css} onClick={onClick} className={className}>
                        {title}
                      </th>
                    )
                  })}
                </tr>
              </thead>
            )}
            <tbody>
              {data.map((e, i) => {
                let rowAction = () => {
                  return false
                }
                if (options.rowAction && typeof options.rowAction == 'function') {
                  rowAction = options.rowAction
                }
                return (
                  <tr
                    key={i}
                    onClick={() => {
                      rowAction()
                    }}
                    style={this.props && this.props.options && this.props.options.rowClickable && { cursor: 'pointer' }}
                  >
                    {fieldOrder.map((a, index) => {
                      let css = { whiteSpace: 'normal' }
                      if (this.props.customcss) {
                        css = this.props.customcss
                      }
                      if (!Array.isArray(headers)) {
                        if (this.props.headerscss) {
                          if (a in this.props.headerscss) {
                            css = this.props.customcss ? Object.assign(this.props.headerscss[a], this.props.customcss) : this.props.headerscss[a]
                          }
                        }
                        if (!headers[a]) {
                          return null
                        }
                      }
                      if (a === 'error') {
                        return null
                      }
                      let rVal = e[a]
                      if (options.fieldFunctions && options.fieldFunctions[a] && typeof options.fieldFunctions[a] == 'function') {
                        rVal = options.fieldFunctions[a](e)
                      } else if (rVal instanceof Object) {
                        rVal = <pre>{JSON.stringify(rVal, null, '\t')}</pre>
                      } else {
                        rVal = nl2br(rVal)
                      }
                      return (
                        <td
                          style={e.error && e.error.includes(a) ? { background: 'red', border: '1px solid rgba(0, 0, 0, 0.3)', ...css } : css}
                          key={index}
                          onClick={event => {
                            this.rowHandler(event, a, e)
                          }}
                          onMouseUp={() => {
                            if (this.props && this.props.options && this.props.options.copyHightlighText && window.getSelection().toString()) {
                              navigator.clipboard.writeText(window.getSelection().toString())
                              toast.success('Kopieret: ' + window.getSelection().toString(), {
                                autoClose: 3000
                              })
                            }
                          }}
                        >
                          <StyledSpan>{rVal}</StyledSpan>
                        </td>
                      )
                    })}
                    {options.addField && typeof options.addField == 'function' && options.addField(e)}
                  </tr>
                )
              })}
            </tbody>
          </React.Fragment>
        </Table>
        {this.props.options.totalamount > this.props.options.paginationAmount && (
          <Form
            inline
            onSubmit={e => {
              e.preventDefault()
            }}
          >
            <FormGroup>
              <Button className='nextPageBtn' disabled={this.props.options.paginationPoint == 0} onClick={this.prevPage.bind(this)}>
                Forrige side
              </Button>
              <Label className='ml-2 mr-2' for='paginationPoint'>
                Side{' '}
              </Label>
              <span style={{ minWidth: 70, justifyContent: 'space-around', display: 'flex' }}>
                {this.props.options.loading ? (
                  <FontAwesomeIcon icon='spinner' spin style={{ justifySelf: 'center' }} />
                ) : (
                  <Input
                    type='select'
                    id='paginationPoint'
                    value={this.props.options.paginationPoint + 1}
                    onChange={this.setPaginationPoint.bind(this)}
                  >
                    {paginationArr}
                  </Input>
                )}
              </span>
              <Label className='ml-2 mr-2' for='paginationPoint'>
                af {Math.ceil(this.props.options.totalamount / this.props.options.paginationAmount)} sider
              </Label>
              <Button
                className='nextPageBtn'
                disabled={this.props.options.paginationPoint >= Math.floor(this.props.options.totalamount / this.props.options.paginationAmount)}
                onClick={this.nextPage.bind(this)}
              >
                Næste side
              </Button>
            </FormGroup>
          </Form>
        )}
      </React.Fragment>
    )
  }
}
