import { createActions, Store } from 'reflux'
import Proxies from 'Components/proxies'
const { GET, POST, wrapFilter, PUT } = Proxies
import { customSlugify } from 'Util/slug'
import keyMirror from 'keymirror'
import moment from 'moment'
import _ from 'lodash'
import { toast } from 'react-toastify'

export const STATUS = new keyMirror({
  FETCHING_CLIENTS: null,
  FETCHING_CLIENTS_FAILED: null,
  FETCHING_CLIENTS_SUCCESS: null,
  FETCHING_BRAND: null,
  FETCHING_BRAND_FAILED: null,
  FETCHING_BRAND_SUCCESS: null,
  BRAND_FAILED: null,
  BRAND_SUCCESS: null,
  IS_REDIRECT: null,
  IS_SAVING: null,
  ACTIVATED_BRAND_FAILED: null,
  DEACTIVATED_BRAND_FAILED: null,
  DELETE_BRAND: null,
  ACTIVATED_BRAND_SUCCESS: null,
  DEACTIVATED_BRAND_SUCCESS: null,
  FETCH_CUSTOMISATION_FAILED: null,
  FETCHING_LANGUAGE: null,
  FETCHING_LANGUAGE_ERROR: null,
  FETCHING_LANGUAGE_SUCCESS: null,
  FETCHING_FONTs: null,
  FETCHING_FONTs_ERROR: null,
  FETCHING_FONTs_SUCCESS: null
})

const selectCustomisation = {
  customisation: 'isDownForMaintenance',
  tncCustomisation: 'TermsConditionsURL',
  cookieCustomisation: 'cookiePageClub',
  walletActivationCustomisation: 'walletActivation',
  sendPointsCustomisation: 'sendPoints',
  profileCustomisation: 'disableProfileFeatures',
  openingHoursCustomisation: 'openingHours'
}

export const BrandActions = createActions([
  'clearToast',
  'getBrands',
  'getBrandsList',
  'getClients',
  'updateField',
  'saveBrand',
  'deleteBrand',
  'resetForm',
  'fieldBlur',
  'getBrandDetails',
  'copyBrand',
  'getBrandCustomisations',
  'updateCustomisation',
  'toggleActiveBrand',
  'toggleInActiveBrand',
  'uploadFaviconImage',
  'resetFormValidations',
  'getBrandtncCustomisation',
  'setObfuscationBrandID',
  'getBrandObfuscationDetails',
  'setUserObfuscationDate',
  'setOrderObfuscationDate',
  'scheduleUserObfuscation',
  'scheduleOrderObfuscation',
  'revertUserObfuscation',
  'revertOrderObfuscation',
  'updateTncCustomisation',
  'updateCookieCustomisation',
  'getBrandCookieCustomisation',
  'updateTranslationField',
  'addFreightIntervalRow',
  'updateFreightIntervalField',
  'getLanguages',
  'getFonts',
  'getWalletActivationCustomisation',
  'updateWalletActivationCustomisation',
  'updateEnabledLanguages',
  'getSendPointsCustomisation',
  'updateSendPointsCustomisation',
  'getProfileCustomisation',
  'updateProfileCustomisation',
  'getOpeningHoursCustomisation',
  'updateOpeningHoursCustomisation'
])

const DEFAULT_ORDERSHIP_MESSAGE = `Du skal ikke vente længe nu. Din ordre {{OrderID}} er nu afsendt.
(Hvis dette er en del ordre, vil det resterende blive afsendt hurtigst muligt).
Med venlig hilsen, {{brandNickname}}`

export class BrandStore extends Store {
  constructor(props) {
    super(props)
    this.listenables = BrandActions
    this.state = {
      status: null,
      clientlist: [],
      brandsList: [],
      obfuscationBrandID: null,
      userObfuscationDate: null,
      userObfuscationDateValue: null,
      orderObfuscationDate: null,
      orderObfuscationDateValue: null,
      languages: [],
      fonts: [],
      freightIntervals: [],
      brandDetails: {
        brandName: '',
        brandNameShort: '',
        brandNickname: '',
        brandSlug: '',
        brandColor: 'FFFFFF',
        brandLogo: '',
        brandIconImageURL: '',
        brandFeatureImageURL: '',
        brandPointRatio: 1,
        freeFreightOrderLimit: 0,
        brandShopSelectionLimit: 0,
        poolOrder: 0,
        brandAccessType: 'PRIVATE',
        brandAccess: 'ACTIVE',
        brandAccessKey: '',
        brandAccessTermsURL: '',
        isChristmas: 0,
        brandDemo: 0,
        brandMuted: 0,
        brandHeaderFontSize: 0,
        brandHeaderFont: 0,
        brandBodyFontSize: 0,
        brandBodyFont: 0,
        glsCustomerNumber: '25106',
        userInactivityTimeoutMonths: 0,
        timeStart: moment().format('YYYY-MM-DD'),
        TimeEnd: '',
        orderShipmentType: 'A',
        orderParcelType: 'A',
        orderGLSService: 'E',
        customDomain: '',
        orderShipMessage: DEFAULT_ORDERSHIP_MESSAGE,
        brandTheme: '',
        LanguageID: 1,
        excludeInvoice: 0,
        excludeFreight: 0,
        contactEmail: '',
        contactPhone: '',
        enabledLanguages: [],
        brandEnableTaxInfo: null,
        useCustomOrderShipMessage: null,
        isMerchandise: 0
      },
      deleteBrandID: null,
      customisation: {
        ...(_.get(this.props, 'match.params.BrandID') && { CustomisedID: this.props.match.params.BrandID }),
        customisationData: '',
        customisedType: 'Brand',
        description: 'Brand Down For Maintenance',
        isDownForMaintenance: false,
        title: 'downForMaintenance'
      },
      tncCustomisation: {
        ...(_.get(this.props, 'match.params.BrandID') && { CustomisedID: this.props.match.params.BrandID }),
        customisationData: '',
        customisedType: 'Brand',
        description: 'URL for terms and conditions',
        TermsConditionsURL: null,
        title: 'termsAndConditions'
      },
      cookieCustomisation: {
        ...(_.get(this.props, 'match.params.BrandID') && { CustomisedID: this.props.match.params.BrandID }),
        customisationData: '',
        customisedType: 'Brand',
        description: 'Cookie page content for Upgraderclub',
        cookiePageClub: { header: '', content: '' },
        title: 'cookiePageClub'
      },
      sendPointsCustomisation: {
        ...(_.get(this.props, 'match.params.BrandID') && { CustomisedID: this.props.match.params.BrandID }),
        customisationData: '',
        customisedType: 'Brand',
        description: 'Send points and loan feature',
        sendPoints: { enableSendPoints: false, enableLoanPoints: false },
        title: 'sendAndLoanPoints'
      },
      profileCustomisation: {
        ...(_.get(this.props, 'match.params.BrandID') && { CustomisedID: this.props.match.params.BrandID }),
        customisationData: '',
        customisedType: 'Brand',
        description: 'Disable profile features',
        disableProfileStatistic: false,
        disableProfileWishlist: false,
        disableProfilePointsOverview: false,
        title: 'disableProfileFeatures'
      },
      showRemovedBrands: 'active',
      showActiveBrands: true,
      showInActiveBrands: false,
      isUploading: false
    }
  }

  updateEnabledLanguages = item => {
    let brandDetails = this.state.brandDetails
    let languages = this.state.languages
    if (item.checked) {
      brandDetails.enabledLanguages.push(item.value)
    } else {
      brandDetails.enabledLanguages = brandDetails.enabledLanguages.filter(i => i !== item.value)
    }
    brandDetails.enabledLanguages = [...new Set(brandDetails.enabledLanguages)]
    languages = languages.map(lang => {
      lang.checked = item.value === lang.LanguageID ? item.checked : lang.checked
      return lang
    })
    this.setState({ brandDetails, languages })
  }

  addFreightIntervalRow = () => {
    this.setState({
      freightIntervals: [
        ...this.state.freightIntervals,
        {
          title: '',
          orderWeightFrom: 0,
          orderWeightTo: 0,
          freightPoints: 0
        }
      ]
    })
  }

  updateFreightIntervalField = intervals => {
    this.setState({ freightIntervals: intervals })
  }

  updateField = e => {
    let fieldName = e.name === 'translations' ? e.name : e.target.name
    let fieldValue = e.name === 'translations' ? e.value : e.target.value
    let fieldType = e.name === 'translations' ? e.value : e.target.type
    let brandDetails = this.state.brandDetails

    if (fieldName === 'ClientID') {
      brandDetails[fieldName] = Number(fieldValue)
    }

    if (fieldType === 'number') {
      brandDetails[fieldName] = Number(fieldValue)
    }

    if (fieldName === 'brandName') {
      brandDetails[fieldName] = fieldValue
      brandDetails['brandSlug'] = customSlugify(fieldValue)
    }

    if (fieldName === 'LanguageID') {
      brandDetails[fieldName] = Number(fieldValue)
    }

    if (fieldName === 'brandBodyFont' || fieldName === 'brandHeaderFont') {
      brandDetails[fieldName] = Number(fieldValue)
    }

    if (fieldName === 'brandNameShort') {
      brandDetails[fieldName] = fieldValue
    }
    if (fieldName === 'brandTheme') {
      brandDetails[fieldName] = fieldValue
    }
    if (fieldName === 'brandNickname') {
      brandDetails[fieldName] = fieldValue
    }
    if (fieldName === 'brandSlug') {
      brandDetails[fieldName] = fieldValue
    }

    if (fieldName === 'brandLogo') {
      brandDetails[fieldName] = fieldValue
    }

    if (fieldName === 'brandIconImageURL') {
      brandDetails[fieldName] = fieldValue
    }

    if (fieldName === 'brandFeatureImageURL') {
      brandDetails[fieldName] = fieldValue
    }
    if (fieldName === 'brandColor') {
      brandDetails[fieldName] = fieldValue
    }
    if (fieldName === 'colorPicker') {
      brandDetails[fieldName] = fieldValue
      brandDetails['brandColor'] = fieldValue.replace('#', '').toUpperCase()
    }
    if (fieldName === 'brandPointRatio' || fieldName === 'freeFreightOrderLimit') {
      brandDetails[fieldName] = fieldValue
    }
    if (fieldName === 'brandShopSelectionLimit') {
      brandDetails[fieldName] = Number(fieldValue)
    }

    if (fieldName === 'poolOrder') {
      brandDetails[fieldName] = Number(fieldValue)
    }

    if (fieldName === 'brandAccess') {
      brandDetails[fieldName] = fieldValue
    }
    if (fieldName === 'brandAccessKey') {
      brandDetails[fieldName] = fieldValue
    }
    if (fieldName === 'brandAccessTermsURL') {
      brandDetails[fieldName] = fieldValue
    }
    if (fieldName === 'brandAccessType') {
      brandDetails[fieldName] = fieldValue
    }
    if (fieldName === 'timeStart') {
      fieldValue = moment(fieldValue).format('YYYY-MM-DD')
      brandDetails[fieldName] = fieldValue
    }

    if (fieldName === 'timeEnd') {
      brandDetails[fieldName] = fieldValue
      fieldValue = moment(fieldValue).format('YYYY-MM-DD')
    }
    if (fieldName === 'brandDemo') {
      brandDetails[fieldName] = Number(fieldValue)
    }
    if (fieldName === 'isChristmas') {
      brandDetails[fieldName] = Number(fieldValue)
    }
    if (fieldName === 'brandMuted') {
      brandDetails[fieldName] = Number(fieldValue)
    }
    if (fieldName === 'userInactivityTimeoutMonths') {
      brandDetails[fieldName] = Number(fieldValue)
    }
    if (fieldName === 'glsCustomerNumber') {
      brandDetails[fieldName] = fieldValue
    }
    if (fieldName === 'orderShipmentType' || fieldName === 'orderParcelType' || fieldName === 'orderGLSService' || fieldName === 'customDomain') {
      brandDetails[fieldName] = fieldValue
    }
    if (fieldName === 'translations') {
      brandDetails[fieldName] = fieldValue
    }
    if (fieldName === 'excludeInvoice') {
      const excludeInvoiceValue = e.target.checked ? 1 : 0
      brandDetails[fieldName] = excludeInvoiceValue
    }
    if (fieldName === 'excludeFreight') {
      const excludeFreightValue = e.target.checked ? 1 : 0
      brandDetails[fieldName] = excludeFreightValue
    }
    if (fieldName === 'contactEmail') {
      brandDetails[fieldName] = fieldValue
    }
    if (fieldName === 'contactPhone') {
      brandDetails[fieldName] = fieldValue
    }
    if (fieldName === 'brandEnableTaxInfo') {
      brandDetails[fieldName] = e.target.checked ? 1 : 0
    }
    if (fieldName === 'useCustomOrderShipMessage') {
      brandDetails[fieldName] = e.target.checked ? 1 : 0
    }
    if (fieldName === 'isMerchandise') {
      brandDetails[fieldName] = e.target.checked ? 1 : 0
    }
    this.setState({ brandDetails })
  }

  resetFormValidations = () => {
    this.setState({
      brandSlugError: false,
      brandNameError: false,
      brandNameShort: false,
      brandNameShortError: false,
      brandNicknameError: false,
      brandColorError: false,
      brandHeaderFontError: false,
      brandHeaderFontSizeError: false,
      brandBodyFontError: false,
      brandBodyFontSizeError: false,
      brandLogoError: false,
      brandIconImageURLError: false,
      brandFeatureImageURLError: false,
      brandPointRatioError: false,
      freeFreightOrderLimitError: false,
      brandAccessKeyError: false,
      brandAccessTermsURLError: false,
      brandShopSelectionLimitError: false,
      poolOrderError: false,
      timeStartError: false,
      timeEndError: false,
      contactEmailError: false,
      contactPhoneError: false
    })
  }

  fieldBlur = e => {
    e.preventDefault()
    let fieldName = e.target.name
    let fieldValue = e.target.value
    const {
      brandName,
      brandNameShort,
      brandNickname,
      brandSlug,
      brandLogo,
      brandIconImageURL,
      brandFeatureImageURL,
      brandPointRatio,
      freeFreightOrderLimit,
      brandShopSelectionLimit,
      poolOrder,
      timeStart,
      timeEnd,
      brandAccessKey,
      brandAccessTermsURL,
      brandColor,
      contactEmail
    } = this.state.brandDetails

    if (fieldName === 'brandName') {
      if (fieldValue.length > 0 && !/^[a-zøåæA -ZØÅÆ_0-9-]{1,225}$/.test(brandName)) {
        this.setState({ brandNameError: true })
      } else {
        this.setState({ brandNameError: false })
      }
    }
    if (fieldName === 'brandSlug') {
      if (fieldValue.length > 0 && !/^[a-zA -Z_0-9-]{1,11}$/.test(brandSlug)) {
        this.setState({ brandSlugError: true })
      } else {
        this.setState({ brandSlugError: false })
      }
    }

    if (fieldName === 'brandNameShort') {
      if (fieldValue.length > 0 && !/^[a-zøåæA -ZØÅÆ_0-9-]{1,11}$/.test(brandNameShort)) {
        this.setState({ brandNameShortError: true })
      } else {
        this.setState({ brandNameShortError: false })
      }
    }

    if (fieldName === 'brandNickname') {
      if (fieldValue.length > 0 && !/^[a-zøåæA -ZØÅÆ_0-9-]{1,11}$/.test(brandNickname)) {
        this.setState({ brandNicknameError: true })
      } else {
        this.setState({ brandNicknameError: false })
      }
    }
    if (fieldName === 'brandColor' && !/^[a-zA-Z_0-9-]{6,6}$/.test(brandColor)) {
      this.setState({ brandColorError: true })
    } else {
      this.setState({ brandColorError: false })
    }

    if (fieldName === 'brandLogo') {
      if (fieldValue.length > 0 && !/^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w.-]+)+[\w\-._~:/?#[\]@!$&'()*+,;=.]+$/.test(brandLogo)) {
        this.setState({ brandLogoError: true })
      } else {
        this.setState({ brandLogoError: false })
      }
    }
    if (fieldName === 'brandIconImageURL') {
      if (fieldValue.length > 0 && !/^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w.-]+)+[\w\-._~:/?#[\]@!$&'()*+,;=.]+$/.test(brandIconImageURL)) {
        this.setState({ brandIconImageURLError: true })
      } else {
        this.setState({ brandIconImageURLError: false })
      }
    }

    if (fieldName === 'brandFeatureImageURL') {
      if (fieldValue.length > 0 && !/^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w.-]+)+[\w\-._~:/?#[\]@!$&'()*+,;=.]+$/.test(brandFeatureImageURL)) {
        this.setState({ brandFeatureImageURLError: true })
      } else {
        this.setState({ brandFeatureImageURLError: false })
      }
    }
    if (fieldName === 'brandPointRatio') {
      if (fieldValue.length > 0 && !/^[0-9.]{1,}$/.test(brandPointRatio)) {
        this.setState({ brandPointRatioError: true })
      } else {
        this.setState({ brandPointRatioError: false })
      }
    }

    if (fieldName === 'freeFreightOrderLimit') {
      if (fieldValue.length > 0 && !/^[0-9.]{1,}$/.test(freeFreightOrderLimit)) {
        this.setState({ freeFreightOrderLimitError: true })
      } else {
        this.setState({ freeFreightOrderLimitError: false })
      }
    }

    if (fieldName === 'brandShopSelectionLimit') {
      if (fieldValue.length > 0 && !/^[0-9.]{1,}$/.test(brandShopSelectionLimit)) {
        this.setState({ brandShopSelectionLimitError: true })
      } else {
        this.setState({ brandShopSelectionLimitError: false })
      }
    }

    if (fieldName === 'poolOrder') {
      if (fieldValue.length > 0 && !/^[0-9.]{1,}$/.test(poolOrder)) {
        this.setState({ poolOrderError: true })
      } else {
        this.setState({ poolOrderError: false })
      }
    }

    if (fieldName === 'timeStart') {
      if (fieldValue.length > 0 && !/^[0-9-]{8,}$/.test(timeStart)) {
        this.setState({ timeStartError: true })
      } else {
        this.setState({ timeStartError: false })
      }
    }
    if (fieldName === 'timeEnd') {
      if (fieldValue.length > 0 && !/^[0-9-]{8,}$/.test(timeEnd)) {
        this.setState({ timeEndError: true })
      } else {
        this.setState({ timeEndError: false })
      }
    }

    if (fieldName === 'brandAccessKey') {
      if (fieldValue.length > 0 && !/^[0-9-]{6,6}$/.test(brandAccessKey)) {
        this.setState({ brandAccessKeyError: true })
      } else {
        this.setState({ brandAccessKeyError: false })
      }
    }

    if (fieldName === 'brandAccessTermsURL') {
      if (fieldValue.length > 0 && !/^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w.-]+)+[\w\-._~:/?#[\]@!$&'()*+,;=.]+$/.test(brandAccessTermsURL)) {
        this.setState({ brandAccessTermsURLError: true })
      } else {
        this.setState({ brandAccessTermsURLError: false })
      }
    }

    if (fieldName === 'contactEmail') {
      if (fieldValue.length > 0 && !/^[\w-+.]+@[\w-+]+\.[a-zA-Z]{2,4}$/.test(contactEmail)) {
        this.setState({ contactEmailError: true })
      } else {
        this.setState({ contactEmailError: false })
      }
    }

    // if (fieldName === 'contactPhone') {
    //   if (fieldValue.length > 0) {
    //     this.setState({ contactPhoneError: true })
    //   } else {
    //     this.setState({ contactPhoneError: false })
    //   }
    // }
  }

  resetForm = () => {
    this.setState({
      brandDetails: {
        ClientID: 'Vælg klient',
        brandName: '',
        brandNameShort: '',
        brandNickname: '',
        brandSlug: '',
        brandColor: 'FFFFFF',
        brandLogo: '',
        brandIconImageURL: '',
        brandFeatureImageURL: '',
        brandPointRatio: 1,
        freeFreightOrderLimit: 0,
        brandShopSelectionLimit: 0,
        poolOrder: 0,
        brandAccessType: 'PRIVATE',
        brandAccess: 'ACTIVE',
        brandAccessKey: '',
        brandAccessTermsURL: '',
        isChristmas: 0,
        brandDemo: 0,
        brandMuted: 0,
        glsCustomerNumber: '25106',
        brandHeaderFontSize: 0,
        brandHeaderFont: 0,
        brandBodyFontSize: 0,
        brandBodyFont: 0,
        userInactivityTimeoutMonths: 0,
        timeStart: moment().format('YYYY-MM-DD'),
        timeEnd: '',
        orderShipmentType: 'A',
        orderParcelType: 'A',
        orderGLSService: 'E',
        customDomain: '',
        orderShipMessage: DEFAULT_ORDERSHIP_MESSAGE,
        brandTheme: '',
        brandEnableTaxInfo: null,
        useCustomOrderShipMessage: null,
        isMerchandise: 0
      },
      freightIntervals: []
    })
  }

  getClients() {
    if (this.state.status === STATUS.FETCHING_CLIENTS) {
      return false
    }
    this.setState({ status: STATUS.FETCHING_CLIENTS })
    GET('clients/pool').then(responseJSON => {
      let nextState = {
        clientlist: [],
        brandDetails: this.state.brandDetails
      }
      if (responseJSON.error) {
        nextState.status = STATUS.FETCHING_CLIENT_FAILED
      } else {
        for (const iterator of responseJSON) {
          nextState.clientlist.push({ ClientID: iterator['ClientID'], clientName: iterator['clientName'] })
          if (
            this.state.brandDetails.ClientID === iterator.ClientID &&
            !!iterator.clientEnableTaxInfo &&
            this.state.brandDetails.brandEnableTaxInfo === null
          ) {
            nextState.brandDetails.brandEnableTaxInfo = iterator.clientEnableTaxInfo
          }
        }
        nextState.status = STATUS.FETCHING_CLIENT_SUCCESS
      }
      this.setState(nextState)
    })
  }

  setObfuscationBrandID(id) {
    if (!id || isNaN(id)) {
      this.setState({ obfuscationBrandID: null })
      return null
    }
    this.setState({ obfuscationBrandID: id })
  }

  setUserObfuscationDate(num) {
    if (!num) {
      this.setState({ userObfuscationDateValue: null, userObfuscationDate: null })
      return null
    }
    const userObfuscationDateValue = num === 'now' ? 'now' : Number(num)
    const userObfuscationDate = num === 'now' ? moment() : moment().add(num, 'months')
    this.setState({ userObfuscationDate, userObfuscationDateValue })
  }

  setOrderObfuscationDate(num) {
    if (!num) {
      this.setState({ orderObfuscationDateValue: null, orderObfuscationDate: null })
      return null
    }
    const orderObfuscationDateValue = num === 'now' ? 'now' : Number(num)
    const orderObfuscationDate = num === 'now' ? moment() : moment().add(num, 'months')
    this.setState({ orderObfuscationDate, orderObfuscationDateValue })
  }

  scheduleUserObfuscation() {
    if (!this.state.userObfuscationDateValue) return null
    if (confirm(`Are you sure to schedule user obfuscation on ${moment(this.state.userObfuscationDate).format('DD.MM.YYYY')} ?`)) {
      POST('DataRetentions/obfuscateBrandUsers', {
        scheduledTimeMonths: this.state.userObfuscationDateValue === 'now' ? 0 : this.state.userObfuscationDateValue,
        BrandID: Number(this.state.obfuscationBrandID)
      }).then(() => {
        this.getBrandObfuscationDetails(Number(this.state.obfuscationBrandID))
        toast.success('Users are Marked for Obfuscation', {
          autoClose: 7000,
          onClose: () => {
            this.clearToast()
          }
        })
        this.clearToast()
      })
    }
  }

  revertUserObfuscation() {
    if (!this.state.brandObfuscationDetails.userObfuscationScheduledTime) return null
    if (confirm(`Are you sure to revert scheduled user obfuscation`)) {
      this.setUserObfuscationDate(null)
      POST('DataRetentions/revertObfuscateBrandUsers', { BrandID: Number(this.state.obfuscationBrandID) }).then(() => {
        this.getBrandObfuscationDetails(Number(this.state.obfuscationBrandID))
        toast.success('Reverted obfuscation schedule', {
          autoClose: 7000,
          onClose: () => {
            this.clearToast()
          }
        })
        this.clearToast()
      })
    }
  }

  scheduleOrderObfuscation() {
    if (!this.state.orderObfuscationDateValue) return null
    if (confirm(`Are you sure to schedule order obfuscation on ${moment(this.state.orderObfuscationDate).format('DD.MM.YYYY')}?`)) {
      POST('DataRetentions/obfuscateBrandOrders', {
        scheduledTimeMonths: this.state.orderObfuscationDateValue === 'now' ? 0 : this.state.orderObfuscationDateValue,
        BrandID: Number(this.state.obfuscationBrandID)
      }).then(() => {
        this.getBrandObfuscationDetails(Number(this.state.obfuscationBrandID))
        toast.success('Orders are Marked for Obfuscation', {
          autoClose: 7000,
          onClose: () => {
            this.clearToast()
          }
        })
        this.clearToast()
      })
    }
  }

  revertOrderObfuscation() {
    if (!this.state.brandObfuscationDetails.orderObfuscationScheduledTime) return null
    if (confirm(`Are you sure to revert scheduled order obfuscation?`)) {
      this.setOrderObfuscationDate(null)
      POST('DataRetentions/revertObfuscateBrandOrders', { BrandID: Number(this.state.obfuscationBrandID) }).then(() => {
        this.getBrandObfuscationDetails(Number(this.state.obfuscationBrandID))
        toast.success('Reverted obfuscation schedule', {
          autoClose: 7000,
          onClose: () => {
            this.clearToast()
          }
        })
        this.clearToast()
      })
    }
  }

  getBrandObfuscationDetails(BrandID) {
    if (!BrandID || isNaN(BrandID)) return null
    this.setObfuscationBrandID(BrandID)
    GET('brands/brand-obfuscation-details', { BrandID }).then(responseJSON => {
      let nextState = {
        brandObfuscationDetails: null
      }
      if (!responseJSON.error) {
        nextState.brandObfuscationDetails = responseJSON
      }
      this.setState(nextState)
    })
  }

  getBrandsList() {
    GET('menus/listBrands').then(responseJSON => {
      let nextState = {
        brandsList: []
      }
      if (!responseJSON.error) {
        for (const iterator of responseJSON) {
          nextState.brandsList.push({ BrandID: iterator['BrandID'], brandName: iterator['brandName'] })
        }
      }
      this.setState(nextState)
    })
  }

  getBrands() {
    if (this.state.status === STATUS.FETCHING_BRAND) {
      return false
    }
    this.setState({ status: STATUS.FETCHING_BRAND })
    GET('brands/pool').then(responseJSON => {
      let nextState = {
        brands: []
      }
      if (responseJSON.error) {
        nextState.status = STATUS.FETCHING_BRAND_FAILED
      } else {
        nextState.brands = responseJSON
        nextState.status = STATUS.FETCHING_BRAND_SUCCESS
      }
      this.setState(nextState)
    })
  }
  getBrandDetails(BrandID, isCopy) {
    this.setState({ status: STATUS.FETCHING_BRAND })
    GET('brands/fetch', { BrandID }).then(responseJSON => {
      let nextState = {
        brandDetails: {},
        languages: []
      }
      if (responseJSON.error) {
        nextState.status = STATUS.FETCHING_BRAND_FAILED
      } else {
        if (isCopy) {
          delete responseJSON.BrandID
          responseJSON.removed = 0
        }
        responseJSON.customDomain = responseJSON.customDomain ? responseJSON.customDomain : ''
        responseJSON.orderShipMessage = responseJSON.orderShipMessage ? responseJSON.orderShipMessage : DEFAULT_ORDERSHIP_MESSAGE
        nextState.brandDetails = responseJSON
        nextState.languages = this.state.languages.map(item => {
          item.checked = responseJSON.enabledLanguages.includes(item.LanguageID)
          return item
        })
        nextState.freightIntervals = !!responseJSON.freightIntervals && responseJSON.freightIntervals.length > 0 ? responseJSON.freightIntervals : []
        nextState.status = STATUS.FETCHING_BRAND_SUCCESS
        responseJSON.timeEnd = responseJSON.timeEnd ? moment(responseJSON.timeEnd).format('YYYY-MM-DD') : null
        responseJSON.timeStart = responseJSON.timeStart ? moment(responseJSON.timeStart).format('YYYY-MM-DD') : null
      }
      this.setState(nextState)
    })
  }

  clearToast(status) {
    status ? this.setState({ status }) : this.setState({ status: null })
  }

  saveBrand() {
    if (this.state.brandName !== '') {
      let brandDetails = this.state.brandDetails
      let tncCustomisation = this.state.tncCustomisation
      let cookieCustomisation = this.state.cookieCustomisation
      let walletActivation = this.state.walletActivation
      let sendPointsCustomisation = this.state.sendPointsCustomisation
      let profileCustomisation = this.state.profileCustomisation
      let openingHoursCustomisation = this.state.openingHoursCustomisation
      this.verifyDownForMaintenace()
      if (!brandDetails.timeEnd) {
        brandDetails.timeEnd = null
      }
      if (!brandDetails.faviconImageURL) {
        delete brandDetails.faviconImageURL
      }
      if (!brandDetails.customDomain) {
        brandDetails.customDomain = ''
      }
      if (!brandDetails.contactEmail) {
        brandDetails.contactEmail = ''
      }
      if (!brandDetails.contactPhone) {
        brandDetails.contactPhone = ''
      }
      brandDetails.freightIntervals = this.state.freightIntervals
      this.setState({ status: STATUS.IS_SAVING })
      POST('brands/replace', this.state.brandDetails).then(responseJSON => {
        if (tncCustomisation && (tncCustomisation.TermsConditionsURL || tncCustomisation.CustomisationID) && !responseJSON.error) {
          this.verifytermsAndConditionsCustomisation(brandDetails)
        }
        if (cookieCustomisation && (cookieCustomisation.cookiePageClub || cookieCustomisation.CustomisationID) && !responseJSON.error) {
          this.verifyCookieCustomisation(brandDetails)
        }
        if (
          walletActivation &&
          (walletActivation.enableWalletActivation ||
            walletActivation.disableActivateWalletFeature ||
            walletActivation.disableWalletActivationGridSection ||
            walletActivation.disableAutoActivateFeature ||
            walletActivation.CustomisationID) &&
          !responseJSON.error
        ) {
          this.saveWalletActivationCustomisation(brandDetails)
        }
        if (sendPointsCustomisation && (sendPointsCustomisation.sendPoints || sendPointsCustomisation.CustomisationID) && !responseJSON.error) {
          this.verifySendPointsCustomisation(brandDetails)
        }
        if (
          profileCustomisation &&
          (profileCustomisation.disableProfileWishlist ||
            profileCustomisation.disableProfileStatistic ||
            profileCustomisation.disableProfilePointsOverview ||
            profileCustomisation.CustomisationID) &&
          !responseJSON.error
        ) {
          this.verifyProfileCustomisation(brandDetails)
        }
        if (openingHoursCustomisation && (openingHoursCustomisation.openingHoursMonThurs || openingHoursCustomisation.openingHoursFri)) {
          this.saveOpeningHoursCustomisation(brandDetails)
        }
        let nextState = {
          brandDetails: this.state.brandDetails
        }
        if (responseJSON) {
          if (responseJSON.error) {
            nextState.status = STATUS.BRAND_FAILED
          } else {
            nextState.brandDetails = responseJSON
            nextState.status = STATUS.BRAND_SUCCESS
          }
        }
        this.setState(nextState)
      })
    }
  }
  deleteBrand(BrandID = null, removed) {
    if (BrandID && removed >= 0) {
      this.setState({ status: STATUS.DELETE_BRAND, deleteBrandID: BrandID })
      POST('brands/removeBrand', { BrandID, removed }).then(responseJSON => {
        if (responseJSON.error) {
          removed === 0
            ? this.setState({ deleteBrandID: null, status: STATUS.ACTIVATED_BRAND_FAILED })
            : this.setState({ deleteBrandID: null, status: STATUS.DEACTIVATED_BRAND_FAILED })
        } else {
          let brands = this.state.brands
          brands.map(item => {
            if (item.BrandID === BrandID) {
              item.removed = removed
            }
            return item
          })
          let status = removed === 0 ? STATUS.ACTIVATED_BRAND_SUCCESS : STATUS.DEACTIVATED_BRAND_SUCCESS
          this.setState({ deleteBrandID: null, brands, status })
          this.getBrands()
        }
      })
    }
  }
  copyBrand(brand, props) {
    props.history.push(props.location.pathname + '/new?copy=' + brand.BrandID)
  }

  getBrandCustomisations(BrandID) {
    let baseData = { CustomisedID: BrandID, customisedType: 'Brand', title: 'downForMaintenance' }
    GET('customisationv2', wrapFilter(baseData)).then(responseJSON => {
      let nextState = {
        customisation: {
          CustomisedID: BrandID ? Number(BrandID) : Number(this.props.match.params.BrandID),
          customisationData: '',
          customisedType: 'Brand',
          description: 'Brand Down For Maintenance',
          isDownForMaintenance: false,
          title: 'downForMaintenance'
        }
      }
      if (responseJSON && !responseJSON.error && responseJSON.length) {
        responseJSON[0].isDownForMaintenance = JSON.parse(responseJSON[0].customisationData).features.isDownForMaintenance
        nextState.customisation = responseJSON[0]
      }
      this.setState(nextState)
    })
  }

  getBrandCookieCustomisation(BrandID) {
    let baseData = { CustomisedID: BrandID, customisedType: 'Brand', title: 'cookiePageClub' }
    GET('customisationv2', wrapFilter(baseData)).then(responseJSON => {
      let nextState = {
        cookieCustomisation: {
          CustomisedID: BrandID ? Number(BrandID) : Number(this.props.match.params.BrandID),
          customisationData: '',
          customisedType: 'Brand',
          description: 'Cookie page content for Upgraderclub',
          cookiePageClub: { header: '', content: '' },
          title: 'cookiePageClub'
        }
      }
      if (responseJSON && !responseJSON.error && responseJSON.length) {
        responseJSON[0].cookiePageClub = JSON.parse(responseJSON[0].customisationData).features.cookiePageClub
        nextState.cookieCustomisation = responseJSON[0]
      }
      this.setState(nextState)
    })
  }

  getBrandtncCustomisation(BrandID, isCopy = false) {
    let nextState = {
      loginImageCustomisation: {
        customisationData: '',
        customisedType: 'Brand',
        description: 'URL for terms and conditions',
        TermsConditionsURL: null,
        title: 'termsAndConditions'
      }
    }
    if (BrandID) {
      nextState.loginImageCustomisation.CustomisedID = BrandID ? Number(BrandID) : Number(this.props.match.params.BrandID)
      let baseData = { CustomisedID: BrandID, customisedType: 'Brand', title: 'termsAndConditions' }
      GET('customisationv2', wrapFilter(baseData)).then(responseJSON => {
        if (responseJSON && !responseJSON.error && responseJSON.length) {
          responseJSON[0].TermsConditionsURL = JSON.parse(responseJSON[0].customisationData).features.TermsConditionsURL
          if (isCopy) {
            delete responseJSON[0].CustomisationID
          }
          nextState.tncCustomisation = responseJSON[0]
        }
        this.setState(nextState)
      })
    }
    this.setState(nextState)
  }

  getSendPointsCustomisation(BrandID, isCopy = false) {
    let nextState = {
      sendPointsCustomisation: {
        customisationData: '',
        customisedType: 'Brand',
        description: 'Send points and loan feature',
        TermsConditionsURL: null,
        title: 'sendAndLoanPoints',
        sendPoints: { enableSendPoints: false, enableLoanPoints: false }
      }
    }
    if (BrandID) {
      nextState.sendPointsCustomisation.CustomisedID = BrandID ? Number(BrandID) : Number(this.props.match.params.BrandID)
      let baseData = { CustomisedID: BrandID, customisedType: 'Brand', title: 'sendAndLoanPoints' }
      GET('customisationv2', wrapFilter(baseData)).then(responseJSON => {
        if (responseJSON && !responseJSON.error && responseJSON.length) {
          responseJSON[0].sendPoints = JSON.parse(responseJSON[0].customisationData).features.sendPoints
          if (isCopy) {
            delete responseJSON[0].CustomisationID
          }
          nextState.sendPointsCustomisation = responseJSON[0]
        }
        this.setState(nextState)
      })
    }
    this.setState(nextState)
  }

  getProfileCustomisation(BrandID, isCopy = false) {
    let nextState = {
      profileCustomisation: {
        customisationData: '',
        customisedType: 'Brand',
        description: 'Disable profile features',
        disableProfileStatistic: false,
        disableProfileWishlist: false,
        disableProfilePointsOverview: false,
        title: 'disableProfileFeatures'
      }
    }
    if (BrandID) {
      nextState.profileCustomisation.CustomisedID = BrandID ? Number(BrandID) : Number(this.props.match.params.BrandID)
      let baseData = { CustomisedID: BrandID, customisedType: 'Brand', title: 'disableProfileFeatures' }
      GET('customisationv2', wrapFilter(baseData)).then(responseJSON => {
        if (responseJSON && !responseJSON.error && responseJSON.length) {
          responseJSON[0].disableProfileStatistic = JSON.parse(responseJSON[0].customisationData).features.disableProfileStatistic
          responseJSON[0].disableProfileWishlist = JSON.parse(responseJSON[0].customisationData).features.disableProfileWishlist
          responseJSON[0].disableProfilePointsOverview = JSON.parse(responseJSON[0].customisationData).features.disableProfilePointsOverview
          if (isCopy) {
            delete responseJSON[0].CustomisationID
          }
          nextState.profileCustomisation = responseJSON[0]
        }
        this.setState(nextState)
      })
    }
    this.setState(nextState)
  }

  updateCustomisation(field) {
    let customisation = this.state.customisation
    customisation[field.target.name] = field.target.checked
    this.setState({ customisation })
  }

  updateTncCustomisation(field) {
    let tncCustomisation = this.state.tncCustomisation
    tncCustomisation[field.target.name] = field.target.value
    this.setState({ tncCustomisation })
  }

  updateCookieCustomisation(field) {
    let cookieCustomisation = this.state.cookieCustomisation
    cookieCustomisation.cookiePageClub[field.target.name] = field.target.value
    this.setState({ cookieCustomisation })
  }

  updateSendPointsCustomisation(field) {
    let sendPointsCustomisation = this.state.sendPointsCustomisation
    sendPointsCustomisation.sendPoints[field.target.name] = field.target.checked
    if (!sendPointsCustomisation.sendPoints.enableSendPoints) {
      sendPointsCustomisation.sendPoints.enableLoanPoints = false
    }
    this.setState({ sendPointsCustomisation })
  }

  updateProfileCustomisation(field) {
    let profileCustomisation = this.state.profileCustomisation
    profileCustomisation[field.target.name] = field.target.checked
    this.setState({ profileCustomisation })
  }

  verifyDownForMaintenace() {
    let customisation = this.state.customisation
    if (customisation.CustomisationID) {
      let customisationData = JSON.parse(customisation.customisationData)
      if (customisationData && customisationData.features.isDownForMaintenance !== customisation.isDownForMaintenance) {
        customisation.customisationData = JSON.stringify({ features: { isDownForMaintenance: customisation.isDownForMaintenance } })
        this.saveCustomisation(customisation, 'customisation')
      }
    } else {
      if (customisation.isDownForMaintenance) {
        customisation.customisationData = JSON.stringify({ features: { isDownForMaintenance: customisation.isDownForMaintenance } })
        this.saveCustomisation(customisation, 'customisation')
      }
    }
  }

  verifyCookieCustomisation() {
    let customisation = this.state.cookieCustomisation
    if (customisation.CustomisationID) {
      // let customisationData = JSON.parse(customisation.customisationData)
      customisation.customisationData = JSON.stringify({ features: { cookiePageClub: customisation.cookiePageClub } })
      this.saveCustomisation(customisation, 'customisation')
    } else {
      if (customisation.cookiePageClub) {
        customisation.customisationData = JSON.stringify({ features: { cookiePageClub: customisation.cookiePageClub } })
        this.saveCustomisation(customisation, 'customisation')
      }
    }
  }

  verifytermsAndConditionsCustomisation(brandDetails) {
    let customisation = this.state.tncCustomisation
    if (!customisation.CustomisedID) {
      customisation.CustomisedID = brandDetails.BrandID
    }
    if (customisation.CustomisationID) {
      let customisationData = JSON.parse(customisation.customisationData)
      if (customisationData && customisationData.features.TermsConditionsURL !== customisation.TermsConditionsURL) {
        customisation.customisationData = JSON.stringify({ features: { TermsConditionsURL: customisation.TermsConditionsURL } })
        this.saveCustomisation(customisation, 'tncCustomisation')
      }
    } else {
      if (customisation.TermsConditionsURL) {
        customisation.customisationData = JSON.stringify({ features: { TermsConditionsURL: customisation.TermsConditionsURL } })
        this.saveCustomisation(customisation, 'tncCustomisation')
      }
    }
  }

  verifySendPointsCustomisation() {
    let customisation = this.state.sendPointsCustomisation
    if (customisation.CustomisationID) {
      customisation.customisationData = JSON.stringify({ features: { sendPoints: customisation.sendPoints } })
      this.saveCustomisation(customisation, 'sendPointsCustomisation')
    } else {
      if (customisation.sendPoints) {
        customisation.customisationData = JSON.stringify({ features: { sendPoints: customisation.sendPoints } })
        this.saveCustomisation(customisation, 'sendPointsCustomisation')
      }
    }
  }

  verifyProfileCustomisation() {
    let customisation = this.state.profileCustomisation
    if (customisation.CustomisationID) {
      customisation.customisationData = JSON.stringify({
        features: {
          disableProfileStatistic: customisation.disableProfileStatistic,
          disableProfileWishlist: customisation.disableProfileWishlist,
          disableProfilePointsOverview: customisation.disableProfilePointsOverview
        }
      })
      this.saveCustomisation(customisation, 'profileCustomisation')
    } else {
      if (customisation.disableProfileStatistic || customisation.disableProfileWishlist || customisation.disableProfilePointsOverview) {
        customisation.customisationData = JSON.stringify({
          features: {
            disableProfileStatistic: customisation.disableProfileStatistic,
            disableProfileWishlist: customisation.disableProfileWishlist,
            disableProfilePointsOverview: customisation.disableProfilePointsOverview
          }
        })
        this.saveCustomisation(customisation, 'profileCustomisation')
      }
    }
  }

  getLanguages() {
    this.setState({ status: STATUS.FETCHING_LANGUAGE })
    GET('schemas/languages').then(responseJSON => {
      let nextState = {
        languages: []
      }
      if (responseJSON.error) {
        nextState.status = STATUS.FETCHING_LANGUAGE_ERROR
      } else {
        nextState.languages = _.sortBy(responseJSON, 'LanguageID')
        nextState.status = STATUS.FETCHING_LANGUAGE_SUCCESS
      }
      this.setState(nextState)
    })
  }

  getFonts() {
    this.setState({ status: STATUS.FETCHING_FONTS })
    GET('Font/pool').then(responseJSON => {
      let nextState = {
        fonts: []
      }
      if (responseJSON.error) {
        nextState.status = STATUS.FETCHING_FONTS_ERROR
      } else {
        nextState.fonts = _.sortBy(
          responseJSON.filter(font => font.removed === 0),
          'FontID'
        )
        nextState.status = STATUS.FETCHING_FONTS_SUCCESS
      }
      this.setState(nextState)
    })
  }

  saveCustomisation(data, customisationName) {
    PUT('customisationv2', data).then(response => {
      if (customisationName === 'profileCustomisation') {
        let nextState = {
          profileCustomisation: this.state.profileCustomisation
        }
        if (response && !response.error) {
          const features = JSON.parse(response.customisationData).features
          nextState.profileCustomisation.disableProfileStatistic = features.disableProfileStatistic
          nextState.profileCustomisation.disableProfileWishlist = features.disableProfileWishlist
          nextState.profileCustomisation.disableProfilePointsOverview = features.disableProfilePointsOverview
        }
        this.setState(nextState)
      } else if (customisationName === 'openingHoursCustomisation') {
        let nextState = {
          openingHoursCustomisation: this.state.openingHoursCustomisation
        }
        if (response && !response.error) {
          const features = JSON.parse(response.customisationData).features
          nextState.openingHoursCustomisation.openingHoursMonThurs = features.openingHoursMonThurs
          nextState.openingHoursFri = features.openingHoursFri
        }
        this.setState(nextState)
      } else {
        let nextState = {
          [customisationName]: this.state[customisationName]
        }
        const value = selectCustomisation[customisationName]
        if (response && !response.error) {
          response[value] = JSON.parse(response.customisationData).features[value]
          nextState[customisationName] = response
        }
        this.setState(nextState)
      }
    })
  }
  toggleActiveBrand() {
    let value = !this.state.showActiveBrands ? 'active' : 'both'
    this.setState({ showActiveBrands: !this.state.showActiveBrands, showRemovedBrands: value, showInActiveBrands: false })
  }
  toggleInActiveBrand() {
    let value = this.state.showInActiveBrands ? 'both' : 'inactive'
    this.setState({ showInActiveBrands: !this.state.showInActiveBrands, showRemovedBrands: value, showActiveBrands: false })
  }
  uploadFaviconImage(data) {
    let file = data.target.files[0]
    this.setState({ file: file, isUploading: true })
    Proxies.uploadImage(file).then(responseJSON => {
      if (responseJSON) {
        let brandDetails = this.state.brandDetails
        brandDetails.faviconImageURL = responseJSON.imageOriginSource ? responseJSON.imageOriginSource : ''
        this.setState({ brandDetails, isUploading: false })
      }
    })
  }
  getWalletActivationCustomisation(BrandID, isCopy = false) {
    let nextState = {
      walletActivation: {
        customisationData: '',
        customisedType: 'Brand',
        description: 'Toggle for Wallet Activation',
        title: 'walletActivation',
        enableWalletActivation: false,
        disableWalletActivationGridSection: false,
        disableAutoActivateFeature: false,
        disableActivateWalletFeature: false
      }
    }
    if (BrandID) {
      nextState.walletActivation.CustomisedID = BrandID ? Number(BrandID) : Number(this.props.match.params.BrandID)
      let baseData = { CustomisedID: BrandID, customisedType: 'Brand', title: 'walletActivation' }
      GET('customisationv2', wrapFilter(baseData)).then(responseJSON => {
        if (responseJSON && !responseJSON.error && responseJSON.length) {
          responseJSON[0].enableWalletActivation = JSON.parse(responseJSON[0].customisationData).features.enableWalletActivation
          responseJSON[0].disableWalletActivationGridSection = JSON.parse(
            responseJSON[0].customisationData
          ).features.disableWalletActivationGridSection
          responseJSON[0].disableAutoActivateFeature = JSON.parse(responseJSON[0].customisationData).features.disableAutoActivateFeature
          responseJSON[0].disableActivateWalletFeature = JSON.parse(responseJSON[0].customisationData).features.disableActivateWalletFeature
          if (isCopy) {
            delete responseJSON[0].CustomisationID
          }
          nextState.walletActivation = responseJSON[0]
        }
        this.setState(nextState)
      })
    }
    this.setState(nextState)
  }

  updateWalletActivationCustomisation(field) {
    let walletActivation = this.state.walletActivation
    walletActivation[field.target.name] = field.target.checked
    this.setState({ walletActivation })
  }
  saveWalletActivationCustomisation() {
    let customisation = this.state.walletActivation
    if (customisation.CustomisationID) {
      // let customisationData = JSON.parse(customisation.customisationData)
      customisation.customisationData = JSON.stringify({
        features: {
          enableWalletActivation: customisation.enableWalletActivation,
          disableWalletActivationGridSection: customisation.disableWalletActivationGridSection,
          disableAutoActivateFeature: customisation.disableAutoActivateFeature,
          disableActivateWalletFeature: customisation.disableActivateWalletFeature
        }
      })
      this.saveCustomisation(customisation, 'customisation')
    } else {
      if (customisation.enableWalletActivation) {
        customisation.customisationData = JSON.stringify({
          features: {
            enableWalletActivation: customisation.enableWalletActivation,
            disableWalletActivationGridSection: customisation.disableWalletActivationGridSection,
            disableAutoActivateFeature: customisation.disableAutoActivateFeature,
            disableActivateWalletFeature: customisation.disableActivateWalletFeature
          }
        })
        this.saveCustomisation(customisation, 'customisation')
      }
    }
  }
  getOpeningHoursCustomisation(BrandID, isCopy = false) {
    let nextState = {
      openingHoursCustomisation: {
        customisationData: '',
        customisedType: 'Brand',
        description: 'Opening hours for contact us page',
        title: 'openingHours',
        openingHoursMonThurs: '8.30 - 16.00',
        openingHoursFri: '8.30 - 14.30'
      }
    }
    if (BrandID) {
      nextState.openingHoursCustomisation.CustomisedID = BrandID ? Number(BrandID) : Number(this.props.match.params.BrandID)
      let baseData = { CustomisedID: BrandID, customisedType: 'Brand', title: 'openingHours' }
      GET('customisationv2', wrapFilter(baseData)).then(responseJSON => {
        if (responseJSON && !responseJSON.error && responseJSON.length) {
          responseJSON[0].openingHoursMonThurs = JSON.parse(responseJSON[0].customisationData).features.openingHoursMonThurs
          responseJSON[0].openingHoursFri = JSON.parse(responseJSON[0].customisationData).features.openingHoursFri
          if (isCopy) {
            delete responseJSON[0].CustomisationID
          }
          nextState.openingHoursCustomisation = responseJSON[0]
        }
        this.setState(nextState)
      })
    }
    this.setState(nextState)
  }
  updateOpeningHoursCustomisation(field) {
    let openingHoursCustomisation = this.state.openingHoursCustomisation
    openingHoursCustomisation[field.target.name] = field.target.value
    this.setState({ openingHoursCustomisation })
  }
  saveOpeningHoursCustomisation() {
    let openingHoursCustomisation = this.state.openingHoursCustomisation
    if (openingHoursCustomisation.CustomisationID) {
      // let customisationData = JSON.parse(customisation.customisationData)
      openingHoursCustomisation.customisationData = JSON.stringify({
        features: {
          openingHoursMonThurs: openingHoursCustomisation.openingHoursMonThurs,
          openingHoursFri: openingHoursCustomisation.openingHoursFri
        }
      })
      this.saveCustomisation(openingHoursCustomisation, 'customisation')
    } else {
      if (openingHoursCustomisation.openingHoursMonThurs || openingHoursCustomisation.openingHoursFri) {
        openingHoursCustomisation.customisationData = JSON.stringify({
          features: {
            openingHoursMonThurs: openingHoursCustomisation.openingHoursMonThurs,
            openingHoursFri: openingHoursCustomisation.openingHoursFri
          }
        })
        this.saveCustomisation(openingHoursCustomisation, 'customisation')
      }
    }
  }
}
